import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';
import {HostListItem, HostStatus, SummaryData} from 'types/hostsType';

export interface HostsDashboardState {
	hostList: HostListItem[];
	summary: SummaryData;
}

export interface HostsDashboardEffects {
	load: () => any;
	loadResource: () => any;
	loadSummaryData: () => any;
}

const hostsDashboard = makeResource<
	HostsDashboardState,
	any,
	HostsDashboardEffects
>({
	name: 'hostsDashboard',
	state: {
		hostList: [
			{
				_id: '',
				name: '',
				lastScanned: '',
				status: HostStatus.Idle,
				memoryDumps: [{_id: '', date: '', status: ''}],
				tags: [],
				userId: '',
				key: '',
			},
		],
		summary: {
			hostRiskSummaryInfo: {
				critical: 0,
				high: 0,
				medium: 0,
				low: 0,
				clean: 0,
			},
			riskSummaryInfo: {
				malware: 0,
				vulnerability: 0,
			},
		},
	},
	effects: {
		*loadResource(): any {
			try {
				const hostList = (yield callApi('/api/v1/hosts')).data;
				yield put(hostsDashboard.actions.set({hostList}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(hostsDashboard.actions.set({hostList: []}));
				} else {
					throw err;
				}
			}
		},
		*load(): any {
			yield put(hostsDashboard.actions.startLoading());
			yield hostsDashboard.effects.loadResource();
			yield put(hostsDashboard.actions.endLoading());
		},
		*loadSummaryData(): any {
			const summary = {
				hostRiskSummaryInfo: {
					critical: 20,
					high: 39,
					medium: 160,
					low: 56,
					clean: 3,
				},
				riskSummaryInfo: {
					malware: 456,
					vulnerability: 789,
				},
			};
			yield put(hostsDashboard.actions.set({summary}));
		},
	},
});

export default hostsDashboard;
