export enum MeteringControlReasons {
	MAX_USAGE_REACHED = 'MAX_USAGE_REACHED',
	PLAN_DOES_NOT_CONTAIN_PRODUCT = 'PLAN_DOES_NOT_CONTAIN_PRODUCT',
	INVOICE_NOT_PAID = 'INVOICE_NOT_PAID',
	NO_PLAN_CHOSEN = 'NO_PLAN_CHOSEN',
	PLAN_CANCELED_BY_USER = 'PLAN_CANCELED_BY_USER',
	NO_METER_FOUND = 'NO_METER_FOUND',
	NO_TIER_FOUND = 'NO_TIER_FOUND',
	EXCEPTION_CAUGHT_ALLOWED = 'EXCEPTION_CAUGHT_ALLOWED',
	MAX_CONTAINER_SIZE_REACHED = 'MAX_CONTAINER_SIZE_REACHED',
	MAX_STORAGE_USAGE_WILL_BE_REACHED = 'MAX_STORAGE_USAGE_WILL_BE_REACHED',
}

export enum MeteringTypes {
	AWSAccount = 'aws_account',
	D_AWSAccountPrimaryRegion = 'primaryRegion',
	CloudIntegration = 'cloud_integration',
	AntiMalwareVulnerabilityScan = 'anti_malware_vulnerability_scan',
	ContainerScans = 'container_scans',
	ContainerSizeLimit = 'container_size_limit',
	Misconfiguration = 'misconfiguration',
	ReportSbomExport = 'report_sbom_export',
	SbomAnalysis = 'sbom_analysis',
	SuspiciousBehaviorDetection = 'suspicious_behavior_detection',
	ContinuousMonitor = 'continuous_monitor',
	SharedAccess = 'shared_access',
	Storage = 'storage',
	DockerImageTotal = 'docker_image_total',
	D_DockerImageTotal_ImageId = 'imageId',
	D_DockerImageTotal_ImageType = 'imageType',
	SBOMBuilder = 'sbom_builder',
	D_SBOMBuilder_SBOMId = 'sbomId',
	RepoTotal = 'repo_total',
	D_RepoTotal_RepoId = 'repoId',
	D_RepoTotal_RepoType = 'repoType',
}

export interface UsageResult {
	name: string;
	usage: number;
	quota: number;
	isRunningTotalType: boolean;
	runningTotalCurrentUsage?: number;
}

export enum SubscriptionPlanTypes {
	basic = 'basic',
	standard = 'standard',
	pro = 'pro',
}

export const METERING_CONTROL_MSG = {
	[MeteringControlReasons.MAX_USAGE_REACHED]:
		'You have reached the maximum usage for this resource.',
	[MeteringControlReasons.PLAN_DOES_NOT_CONTAIN_PRODUCT]:
		'The plan does not contain the product.',
	[MeteringControlReasons.INVOICE_NOT_PAID]:
		'There are still outstanding invoices. Please visit the billing page to pay them.',
	[MeteringControlReasons.NO_PLAN_CHOSEN]:
		'You have not chosen a plan. Please visit our account page.',
	[MeteringControlReasons.PLAN_CANCELED_BY_USER]:
		'You have canceled your subscription. Please visit our account page.',
	[MeteringControlReasons.NO_METER_FOUND]: 'No meter was found.',
	[MeteringControlReasons.NO_TIER_FOUND]: 'No tier was found.',
	[MeteringControlReasons.EXCEPTION_CAUGHT_ALLOWED]:
		'An exception was caught and allowed.',
	[MeteringControlReasons.MAX_CONTAINER_SIZE_REACHED]:
		'This container is too large for your current plan. Please consider upgrading.',
	[MeteringControlReasons.MAX_STORAGE_USAGE_WILL_BE_REACHED]:
		'You will reach the maximum storage usage.',
};
