/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {FC, ReactNode, forwardRef} from 'react';

// @mui material components
import {ButtonProps} from '@mui/material';

// Custom styles for MDButton
import MDButtonRoot from '../MDButton/MDButtonRoot';

// Material Dashboard 2 PRO React TS contexts
import {useMaterialUIController} from '../context';

// Declaring props types for MDButton
export interface MDButtonProps extends Omit<ButtonProps, 'color' | 'variant'> {
	color?:
		| 'white'
		| 'primary'
		| 'secondary'
		| 'info'
		| 'success'
		| 'warning'
		| 'error'
		| 'light'
		| 'dark'
		| 'default';
	variant?: 'text' | 'contained' | 'outlined' | 'gradient';
	size?: 'small' | 'medium' | 'large';
	circular?: boolean;
	iconOnly?: boolean;
	children?: ReactNode;
	[key: string]: any;
}

const MDButton: FC<MDButtonProps> = forwardRef(
	({color, variant, size, circular, iconOnly, children, ...rest}, ref) => {
		const [controller] = useMaterialUIController();
		const {darkMode} = controller;

		return (
			<MDButtonRoot
				{...rest}
				size={size}
				ref={ref}
				ownerState={{
					color,
					variant,
					size,
					circular,
					iconOnly,
					darkMode,
				}}
			>
				{children}
			</MDButtonRoot>
		);
	}
);

// Declaring default props for MDButton
MDButton.defaultProps = {
	color: 'white',
	variant: 'contained',
	size: 'medium',
	circular: false,
	iconOnly: false,
};

export default MDButton;
