export enum MeteringCheckReason {
	MAX_USAGE_REACHED = 'MAX_USAGE_REACHED',
	FREE_TRIAL_EXPIRED = 'FREE_TRIAL_EXPIRED',
	CONTRACT_EXPIRED = 'CONTRACT_EXPIRED',
	FEATURE_NOT_SUPPORTED = 'FEATURE_NOT_SUPPORTED',
	TEAM_ORG_NOT_FOUND = 'TEAM_ORG_NOT_FOUND',
	EXCEPTION_CAUGHT__FORBIDDEN = 'EXCEPTION_CAUGHT__FORBIDDEN',
	EXCEPTION_CAUGHT__ALLOWED = 'EXCEPTION_CAUGHT__ALLOWED',
}

export const METERING_CHECK_MSG_KEY = {
	[MeteringCheckReason.MAX_USAGE_REACHED]:
		'MeteringCheckReason.MAX_USAGE_REACHED',
	[MeteringCheckReason.FREE_TRIAL_EXPIRED]:
		'MeteringCheckReason.FREE_TRIAL_EXPIRED',
	[MeteringCheckReason.CONTRACT_EXPIRED]:
		'MeteringCheckReason.CONTRACT_EXPIRED',
	[MeteringCheckReason.FEATURE_NOT_SUPPORTED]:
		'MeteringCheckReason.FEATURE_NOT_SUPPORTED',
	[MeteringCheckReason.TEAM_ORG_NOT_FOUND]:
		'MeteringCheckReason.TEAM_ORG_NOT_FOUND',
};
