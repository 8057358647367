import {Grid, Skeleton} from '@mui/material';
import MDBox from 'shared/MDBox';

import Page from 'components/Page';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';
import {callApi, ServerRes} from 'utils/network';
import {LoginGitHubAccount} from './LoginGitHubAccount';
import ManageGithubAccount from './ManageGithubAccount';

export interface GitHubAccountItem {
	_id: string;
	url: string;
	username: string;
	avatar: string;
	createdAt: string;
	updatedAt: string;
	accountLink: string;
	accountType?: string;
}

type GitHubAccountRes = {isLinked: boolean} | GitHubAccountItem[];

export const useGithubAccount = (triggerInit = false) => {
	const triggerInitParams = triggerInit ? '?triggerInit=true' : '';
	const {data: accountRes, mutate} = useSWR<ServerRes<GitHubAccountRes>>(
		`/api/v1/github/account${triggerInitParams}`,
		callApi.get
	);

	return {accountRes, mutate};
};

const GitHubAccount = () => {
	const {accountRes, mutate} = useGithubAccount(true);

	const {t} = useTranslation();

	let elementToRender;

	if (!accountRes) {
		elementToRender = (
			<>
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</>
		);
	} else if (Array.isArray(accountRes?.data)) {
		elementToRender = (
			<ManageGithubAccount
				onUpdate={() => mutate()}
				accounts={accountRes.data}
			/>
		);
	} else {
		elementToRender = <LoginGitHubAccount />;
	}

	return (
		<MDBox>
			<Page title={t('github_account_title')} loading={false}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={9}>
						{elementToRender}
					</Grid>
				</Grid>
			</Page>
		</MDBox>
	);
};

export default GitHubAccount;
