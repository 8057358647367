import React, {createContext} from 'react';
import {callApi, callSharedApi} from 'utils/network';
import {SiteEntryPoint} from './SiteEntryPoint';

export const ApiContext = createContext<typeof callApi>(callApi);

export const ApiContextProvider: React.FC<{value: SiteEntryPoint}> = ({
	children,
	value,
}) => {
	const api = value === SiteEntryPoint.InApp ? callApi : callSharedApi;

	return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
