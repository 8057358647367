import React from 'react';

import DBTable from 'components/DBTable';
import {useTranslation} from 'react-i18next';
import MDTypography from 'shared/MDTypography';
import {ProjectFilterDef} from './SelectStreamSource';

export enum SBOMStreamType {
	AwsEcrTag = 'AwsEcrTag',
	GitHubBranch = 'GitHubBranch',
	DockerHubTag = 'DockerHubTag',
	Container = 'Container',
	SBOMBuilder = 'SBOMBuilder',
}

export interface SBOMStreamDef {
	_id: string;
	projectId: string;
	projectAssetId: string;
	streamType: SBOMStreamType;
	branchName?: string;
	tagName?: string;
	resourceId?: string;
	isWatched: boolean;
	streamDeleted?: boolean;
	scanResult?: string[];
	createdAt: Date;
	updatedAt: Date;
}

export const StreamSourceTable = ({
	streams,
	selected,
	onUpdate,
	projectAssetId,
	projectId,
}: {
	projectAssetId: string;
	projectId: string;
	streams: SBOMStreamDef[];
	selected: ProjectFilterDef[];
	onUpdate: (source: ProjectFilterDef[]) => void;
}) => {
	const {t} = useTranslation();

	const columns = React.useMemo(
		() => [
			{
				title: t('registry_stream_source_table_title'),
				field: 'branchName',
				render: (rowData) => {
					return (
						rowData.branchName ||
						rowData.tagName || (
							<MDTypography>
								Uploaded{' '}
								{t(
									`registry_stream_type_${rowData.streamType}`
								)}
							</MDTypography>
						)
					);
				},
			},
			{
				title: t('registry_stream_source_table_type'),
				field: 'type',
				render: (rowData) => {
					return (
						<MDTypography>
							{t(`registry_stream_type_${rowData.streamType}`)}
						</MDTypography>
					);
				},
			},
		],
		[]
	);

	return (
		<DBTable
			title={t('registry_stream_source_table_nested_title')}
			customTitleSize={12}
			columns={columns}
			data={streams}
			onSelectionChange={(rows, rowData) => {
				const targetProjectSelection = selected.find(
					(x) => x.project_id === projectId
				);

				const updatedProjectAssetSelection = {
					projectAssetsId: projectAssetId,
					// isAll: Boolean(!rowData && rows.length),
					isAll: Boolean(!rowData || rows.length == streams.length),
					stream: rows.map((x) => ({
						streamId: x._id,
					})),
				};

				const updatedProjectAssets =
					targetProjectSelection?.projectAssets?.filter(
						(x) => x.projectAssetsId !== projectAssetId
					) || [];

				// Only add the asset if there are selected streams
				if (updatedProjectAssetSelection.stream.length > 0) {
					updatedProjectAssets.push(updatedProjectAssetSelection);
				}

				const updatedProjectData = {
					...targetProjectSelection,
					project_id: projectId,
					isAll: false, // Set to false as we're handling individual assets
					projectAssets: updatedProjectAssets,
				} as ProjectFilterDef;

				onUpdate(
					selected
						.filter((x) => x.project_id !== projectId)
						.concat(
							updatedProjectData.projectAssets.length > 0
								? [updatedProjectData]
								: []
						)
				);
			}}
			options={{
				selection: true,
				pageSize: 5,
				search: false,
			}}
		/>
	);
};
