import {makeResource} from 'utils/saga-resource';
import {callApi} from 'utils/network';
import {put} from 'redux-saga/effects';

export interface Software {
	_id: string;
	name: string;
	description: string;
	vulnerabilities: any[];
}

export interface SoftwareState {
	[key: string]: Software;
}

export interface SoftwareEffects {
	loadSoftware: (payload: {vuldbId: string; softwareId: string}) => any;
	deleteSoftware: (payload: {vuldbId: string; softwareId: string}) => any;
}

const softwares = makeResource<SoftwareState, any, SoftwareEffects>({
	name: 'softwares',
	state: {},
	effects: {
		*loadSoftware({vuldbId, softwareId}): any {
			const software = (yield callApi(
				`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}`
			)).data;
			yield put(softwares.actions.set({[softwareId]: software}));
		},
		*deleteSoftware({vuldbId, softwareId}): any {
			yield callApi(`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}`, {
				method: 'DELETE',
			});
		},
	},
});

export default softwares;
