import {makeResource} from 'utils/saga-resource';

const counter = makeResource({
	name: 'counter',
	state: {
		count: 1,
	},
});

export default counter;
