import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertStore from 'store/alert';
import MDButton from 'shared/MDButton';

function Alert(): JSX.Element {
	const alertMsg = useSelector((state: AppState) => state.alert.msg);
	const dispatch = useDispatch();
	const handleClose = (): any =>
		dispatch(AlertStore.actions.closeAlert(null));
	return (
		<div>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={!!alertMsg}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{alertMsg}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MDButton onClick={handleClose} color="secondary">
						Close
					</MDButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default Alert;
