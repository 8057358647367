import {takeEvery, Effect} from 'redux-saga/effects';
import {get} from 'lodash';
import {ERROR} from './actions';

export function* onError(action: any): IterableIterator<Effect> {
	alert(get(action, 'payload.error.message'));
}

export default function* (): IterableIterator<Effect> {
	yield takeEvery(ERROR, onError);
}
