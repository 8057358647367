import _ from 'lodash';
import {VulSeverityPowerMapping} from 'types/dashboardDataType';

export const getTotalScore = (data) => {
	return _.sumBy(data.cve, (x: number[]) => x?.[1] || 1);
};

export const getComponentCount = (data) => {
	return _.sum(data.details.map((x) => x.components?.length || 0));
};

export const getLicenseList = (data) => {
	return _.chain(data?.details)
		.flatMap((x) =>
			x.components?.flatMap((z) => {
				return z.license;
			})
		)
		.uniq()
		.value()
		.filter(Boolean);
};

export const sortBySeverityLevel = (data = {}) => {
	return _.sum(
		Object.entries(data).map(
			([vulSeverityType, count]) =>
				Number(count) * VulSeverityPowerMapping[vulSeverityType]
		)
	);
};
