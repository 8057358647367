import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {defaultLanguage, supportedLanguages} from './config';
import enTranslation from './locales/en/translation.json';
import zhTranslation from './locales/zh/translation.json';

const resources = {
	en: {
		translation: enTranslation,
	},
	zh: {
		translation: zhTranslation,
	},
};

i18n.use(LanguageDetector)
	.use(initReactI18next) // bind react-i18next to the instance
	.init({
		fallbackLng: defaultLanguage,
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
		supportedLngs: supportedLanguages
			.filter((x) => x.code === 'en')
			.map((lang) => lang.code),
		ns: ['translation', 'sbom'],

		// react i18next special options (optional)
		// override if needed - omit if ok with defaults
		/*
		react: {
		  bindI18n: 'languageChanged',
		  bindI18nStore: '',
		  transEmptyNodeValue: '',
		  transSupportBasicHtmlNodes: true,
		  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
		  useSuspense: true,
		}
		*/
		resources,
	});

export default i18n;

export function getAllLanguageCode(fullyQualifiedCode): string[] {
	return fullyQualifiedCode.split('-')[0];
}
