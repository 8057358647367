import _ from 'lodash';
import MDBadge from 'shared/MDBadge';
import MDBox from 'shared/MDBox';
import {ContainerStaticResult} from 'types/drCloudOnPremisesType';

import {Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import MDTypography from 'shared/MDTypography';

export const getMalwareCounts = (rowData?: ContainerStaticResult): number => {
	const totalMalwares = _.uniq(
		_.flatMap(rowData?.details, (x) => x.malware?.family)
	).filter(Boolean);

	return totalMalwares?.length || 0;
};

export const renderMalwareColumn = (
	rowData?: ContainerStaticResult
): React.ReactElement | null => {
	const totalMalwares = _.uniq(
		_.flatMap(rowData?.details, (x) => x.malware?.family)
	).filter(Boolean);
	const hasMalware = !!totalMalwares?.length;
	const {t} = useTranslation();

	return (
		<>
			<MDBox
				component="span"
				sx={(theme) => ({
					padding: '4px 8px',
					display: 'inline-block',
					backgroundColor: hasMalware
						? theme.palette.error?.main
						: theme.palette.success.main,
					color: theme.palette.white.main,
					borderRadius: theme.borders.borderRadius.sm,
					fontSize: theme.typography.size.xs,
				})}
			>
				{hasMalware ? t('yes') : t('no')}
			</MDBox>
		</>
	);
};

export const renderMalwareColumnInListPage = (
	malwareCount?: number,
	largeDesign?: boolean
): React.ReactElement | null => {
	const {t} = useTranslation();

	if (!malwareCount) {
		if (largeDesign) {
			return (
				<MDBox display="flex" alignItems="center">
					<MDBox
						mr={1}
						paddingX={2}
						paddingY={1}
						sx={(theme) => ({
							backgroundColor: theme.palette.success.main,
							color: theme.palette.white.main,
							borderRadius: theme.borders.borderRadius.sm,
							minWidth: '132px',
						})}
					>
						<MDTypography
							sx={(theme) => ({
								color: theme.palette.white.main,
								fontWeight: 'normal',
							})}
							textTransform="uppercase"
						>
							{t('noMalware')}
						</MDTypography>
						<MDTypography
							variant="h4"
							sx={(theme) => ({
								color: theme.palette.white.main,
								fontWeight: 'normal',
							})}
						>
							0
						</MDTypography>
					</MDBox>
				</MDBox>
			);
		}

		return (
			<MDBadge
				color="success"
				badgeContent="None"
				variant="contained"
				textColor="light"
				textSize="xs"
				style={{marginLeft: '-4px'}}
			/>
		);
	}

	if (largeDesign) {
		return (
			<MDBox display="flex" alignItems="center">
				<MDBox
					mr={1}
					paddingX={2}
					paddingY={1}
					sx={(theme) => ({
						backgroundColor: theme.palette.severity.critical.main,
						color: theme.palette.white.main,
						borderRadius: theme.borders.borderRadius.sm,
						minWidth: '132px',
					})}
				>
					<MDTypography
						sx={(theme) => ({
							color: theme.palette.white.main,
							fontWeight: 'normal',
						})}
						textTransform="uppercase"
					>
						{t('malware')}
					</MDTypography>
					<MDTypography
						variant="h4"
						sx={(theme) => ({
							color: theme.palette.white.main,
							fontWeight: 'normal',
						})}
					>
						{malwareCount}
					</MDTypography>
				</MDBox>
			</MDBox>
		);
	}

	return (
		<MDBox display="flex" alignItems="center">
			<Tooltip title={`${malwareCount} ${t('malware')}`}>
				<MDBox
					mr={1}
					sx={(theme) => ({
						padding: '4px 8px',
						backgroundColor: theme.palette.severity.critical.main,
						color: theme.palette.white.main,
						borderRadius: theme.borders.borderRadius.sm,
						minWidth: '30px',
						textAlign: 'center',
					})}
				>
					{malwareCount}
				</MDBox>
			</Tooltip>
		</MDBox>
	);
};
