export const HOST = window.location.host;
export const PROTOCAL = window.location.protocol;

// Sidebar configuration
export const DRAWER_WIDTH = 240;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const RECAPTCHA_RENDER = process.env.REACT_APP_CAPTCHA_RENDER;
export const RECAPTCHA_SCRIPT = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_RENDER}`;

export const cfTemplateUrl = process.env.REACT_APP_AWS_CLOUDFORMATION_TEMPLATE;

export const mainAppUrl = process.env.REACT_APP_MAIN_APP_URL as string;
export const toolAppUrl = process.env.REACT_APP_TOOL_APP_URL as string;

export const isProduction = process.env.REACT_APP_ENV === 'production';

export const demoAccountEmail = 'livedemo@deepbits.com';
