import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface CodeLine {
	vma: number;
	rawBytes: string;
	instStr: string;
	opcode: string;
	operands: string;
	stringRef: string | null;
	branchRef: string | null;
	targetRef: string | null;
	crossRef: string[];
	labelName: string | null;
	function: boolean;
	code: boolean;
	section_name: string | null;
	branch: string | null;
	branch_label: string | null;
	isBranch: boolean;
	isFunction: boolean;
	isCode: boolean;
}
export interface VulnerabilityState {
	vulFunction: CodeLine[];
	patchedFunction: CodeLine[];
	vulGraph: string;
	patchedGraph: string;
}

export interface VulnerabilityEffects {
	load: (payload: {id: string}) => any;
	loadResource: (payload: {id: string}) => any;
}

const vulnerability = makeResource<
	VulnerabilityState,
	any,
	VulnerabilityEffects
>({
	name: 'vulnerability',
	state: {
		vulFunction: [],
		patchedFunction: [],
		vulGraph: '',
		patchedGraph: '',
	},
	effects: {
		*loadResource({id}): any {
			try {
				const vulFunction = yield callApi(
					`/disassemble_text/vulnerabilities/${id}`
				);
				yield put(vulnerability.actions.set({vulFunction}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({vulFunction: []}));
				} else {
					throw err;
				}
			}
			try {
				const patchedFunction = yield callApi(
					`/disassemble_text/patched_vulnerabilities/${id}`
				);
				yield put(vulnerability.actions.set({patchedFunction}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({patchedFunction: []}));
				} else {
					throw err;
				}
			}
			try {
				const vulGraph = yield callApi(
					`/graph/dot/vulnerabilities/${id}`
				);
				yield put(vulnerability.actions.set({vulGraph}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({vulGraph: ''}));
				} else {
					throw err;
				}
			}
			try {
				const patchedGraph = yield callApi(
					`/graph/dot/patched_vulnerabilities/${id}`
				);
				yield put(vulnerability.actions.set({patchedGraph}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({patchedGraph: ''}));
				} else {
					throw err;
				}
			}
		},
		*load({id}): any {
			yield put(vulnerability.actions.startLoading());
			try {
				yield vulnerability.effects.loadResource({id});
			} finally {
				yield put(vulnerability.actions.endLoading());
			}
		},
	},
});

export default vulnerability;
