export type AuthResourceType =
	| 'MalwareDB'
	| 'VulDB'
	| 'Project'
	| 'Host'
	| 'Firmware'
	| 'Container'
	| 'AwsAccount'
	| 'NewPolicy'
	| 'SBOMBuilder'
	| 'DockerHub'
	| 'GitHubAccount'
	| 'Registry';

export type AuthRoleType =
	| 'reader'
	| 'writer'
	| 'creator'
	| 'deleter'
	| 'admin';

export interface UserAssignmentPayload {
	resourceType: AuthResourceType;
	roleType: AuthRoleType | 'collaborator';
}

export const ContentMapping = {
	Project: 'teamFeatureProject',
	Registry: 'teamFeatureRegistry',
	AwsAccount: 'teamFeatureAws',
	Container: 'teamFeatureOnPremisesImage',
	NewPolicy: 'teamFeaturePolicy',
	SBOMBuilder: 'teamFeatureOnPremisesFile',
	DockerHub: 'teamFeatureDockerHub',
	GitHubAccount: 'teamFeatureGitHubAccount',
};

export type UserPermissionDetails = {
	[key in AuthResourceType]: (AuthRoleType | 'collaborator')[];
};

export type UserPermissionResponse = {
	// key is userId
	[key: string]: {permission: UserPermissionDetails};
};

export type InvitationListResponse = {
	// key is userId
	[key: string]: {
		permission: UserPermissionDetails;
		invitationId: string;
	};
};

export type MemberListResponse = {
	memberDetails: UserPermissionResponse;
	invitationDetails: InvitationListResponse;
	pendingOnBoardingInvitationDetails: InvitationListResponse;
	userDetails: UserDetails[];
};

export type UserDetails = {
	id: string;
	name: string;
	email: string;
	emailVerified: boolean;
	auth0Id: string;
	stripeId?: string;
	amberfloId?: string;
	awsRegistrationToken?: string;
	customerAwsAccountId?: string;
	awsmCustomerIdentifier?: string;
	externalOrgId?: string;
	isOwner: boolean;
};

export enum RoleTypeColorMapping {
	reader = 'info',
	writer = 'warning',
	creator = 'primary',
	deleter = 'error',
	collaborator = 'success',
}

export enum TeamManagementError {
	OWNER_NOT_ALLOWED = 'OWNER_NOT_ALLOWED',
	OWNER_ONLY = 'OWNER_ONLY',
	OWNER_SELF_ASSIGNMENT_NOT_ALLOWED = 'OWNER_SELF_ASSIGNMENT_NOT_ALLOWED',
	MEMBER_NOT_ALLOWED = 'MEMBER_NOT_ALLOWED',
	MEMBER_ONLY = 'MEMBER_ONLY',
	INDIVIDUAL_ACCOUNT_NOT_ALLOWED = 'INDIVIDUAL_ACCOUNT_NOT_ALLOWED',
	USER_NOT_FOUND = 'USER_NOT_FOUND',
	MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
	ALREADY_INVITED = 'ALREADY_INVITED',
	ALREADY_IN_TEAM = 'ALREADY_IN_TEAM',
	ALREADY_ACCEPTED = 'ALREADY_ACCEPTED',
	UPGRADE_PLAN = 'UPGRADE_PLAN',
	INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
	INVITATION_NOT_BELONG_TO_USER = 'INVITATION_NOT_BELONG_TO_USER',
	CROSS_ORG_OPERATION_NOT_ALLOWED = 'CROSS_ORG_OPERATION_NOT_ALLOWED',
	DEMO_ACCOUNT_NOT_ALLOWED = 'DEMO_ACCOUNT_NOT_ALLOWED',
	AWS_USER_NOT_ALLOWED_TO_JOIN = 'AWS_USER_NOT_ALLOWED_TO_JOIN',
	AWS_USER_NOT_ALLOWED_TO_BE_INVITED = 'AWS_USER_NOT_ALLOWED_TO_BE_INVITED',
}
