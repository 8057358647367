import {Tooltip} from '@mui/material';

import {Link} from '@mui/material';
import _ from 'lodash';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import MDBadge from 'shared/MDBadge';
import MDBox from 'shared/MDBox';
import MDTypography from 'shared/MDTypography';
import {
	DashboardVulData,
	VulSeverityColorMapping,
} from 'types/dashboardDataType';
import {
	ContainerStaticResult,
	CveScoreSeverity,
	SBOMInfo,
} from 'types/drCloudOnPremisesType';

interface ItemWithCve {
	cve?: ContainerStaticResult['cve'];
}

const isCveDataInItemWithCveFormat = (data: unknown): data is ItemWithCve =>
	typeof data === 'object' && data !== null && 'cve' in data;

export const renderCveColumn = <T extends ItemWithCve>(
	rowData: T | DashboardVulData,
	largeDesign?: boolean
): React.ReactElement | null => {
	let groupedData;

	if (isCveDataInItemWithCveFormat(rowData)) {
		groupedData = _.chain(
			rowData.cve?.map((x) => ({
				name: x?.[0],
				score: x?.[1],
				severity:
					x?.[1] !== undefined
						? convertCveScoreToSeverity(x?.[1])
						: x?.[2],
			}))
		)
			.groupBy('severity')
			.mapValues((x) => x.length)
			.value();
	} else {
		groupedData = rowData;
	}

	const sortedData = [
		{key: 'critical', value: groupedData?.critical},
		{key: 'high', value: groupedData?.high},
		{key: 'medium', value: groupedData?.medium},
		{key: 'low', value: groupedData?.low},
		{key: 'none', value: groupedData?.none},
	].filter((x) => x.value);

	const {t} = useTranslation();

	if (!sortedData.length) {
		if (largeDesign) {
			return (
				<MDBox display="flex" alignItems="center">
					<MDBox
						mr={1}
						paddingX={2}
						paddingY={1}
						sx={(theme) => ({
							backgroundColor: theme.palette.success.main,
							color: theme.palette.white.main,
							borderRadius: theme.borders.borderRadius.sm,
							minWidth: '132px',
						})}
					>
						<MDTypography
							sx={(theme) => ({
								color: theme.palette.white.main,
								fontWeight: 'normal',
							})}
							textTransform="uppercase"
						>
							{t('noCve')}
						</MDTypography>
						<MDTypography
							variant="h4"
							sx={(theme) => ({
								color: theme.palette.white.main,
								fontWeight: 'normal',
							})}
						>
							0
						</MDTypography>
					</MDBox>
				</MDBox>
			);
		}
		return (
			<MDBadge
				color="success"
				badgeContent="None"
				variant="contained"
				textColor="light"
				textSize="xs"
				style={{marginLeft: '-4px'}}
			/>
		);
	}

	return (
		<MDBox display="flex" alignItems="center" flexWrap="wrap">
			{sortedData.map((x) => {
				if (largeDesign) {
					return (
						<MDBox
							mr={1}
							mb={1}
							paddingX={2}
							paddingY={1}
							sx={(theme) => ({
								backgroundColor:
									theme.palette.severity[x.key]?.main,
								color: theme.palette.white.main,
								borderRadius: theme.borders.borderRadius.sm,
								minWidth: '132px',
							})}
						>
							<MDTypography
								sx={(theme) => ({
									color: theme.palette.white.main,
									fontWeight: 'normal',
								})}
								textTransform="uppercase"
							>
								{x.key}
							</MDTypography>
							<MDTypography
								variant="h4"
								sx={(theme) => ({
									color: theme.palette.white.main,
									fontWeight: 'normal',
								})}
							>
								{x.value}
							</MDTypography>
						</MDBox>
					);
				}

				return (
					<Tooltip key={x.key} title={`${t(x.key)} ${t('severity')}`}>
						<MDBox
							mr={1}
							sx={(theme) => ({
								padding: '4px 8px',
								backgroundColor:
									theme.palette.severity[x.key]?.main,
								color: theme.palette.white.main,
								borderRadius: theme.borders.borderRadius.sm,
								minWidth: '30px',
								textAlign: 'center',
								marginTop: '1px',
								marginBottom: '1px',
							})}
						>
							{x.value}
						</MDBox>
					</Tooltip>
				);
			})}
		</MDBox>
	);
};

export const renderCveLinks = <T extends ItemWithCve>(
	rowData: T
): React.ReactElement | null => {
	if (!rowData.cve?.length) {
		return <>None</>;
	}

	return (
		<>
			{rowData.cve?.map((cve) => {
				if (!cve) {
					return null;
				}

				return (
					<MDBadge
						sx={{margin: 0.5}}
						clickable
						key={cve[0]}
						badgeContent={cve[0]}
						component={Link}
						target="_blank"
						href={getCveLinkByName(cve[0])}
						color={getCveColorByScore(cve[1])}
						textColor="light"
						textSize="xs"
						variant="contained"
						fontWeight="normal"
					/>
				);
			})}
		</>
	);
};

export const getCveLinkByName = (name: string) =>
	`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${name}`;

export const getCveLinkElement = (name: string): ReactElement => (
	<Link href={getCveLinkByName(name)} target="_blank" rel="noreferrer">
		{name}
	</Link>
);

export const convertCveScoreToSeverity = (score: number) => {
	if (score >= CveScoreSeverity.Critical) {
		return 'critical';
	}
	if (score >= CveScoreSeverity.High) {
		return 'high';
	}
	if (score >= CveScoreSeverity.Medium) {
		return 'medium';
	}
	if (score > CveScoreSeverity.Low) {
		return 'low';
	}
	return 'none';
};

export const getCveColorByScore = (score) => {
	const severity = convertCveScoreToSeverity(score);
	return VulSeverityColorMapping[severity];
};

export const getCveSummaryFromSbomList = (results: SBOMInfo[]) => {
	return _.flatMap(results, (r) =>
		_.flatMap(r.components, (c) => c.cve)
	).filter(Boolean);
};
