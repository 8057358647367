import {Vulnerability} from 'components/Vulnerability/VulnerabilityBadgeSummary';

export enum VulSeverityColorMapping {
	critical = 'primary',
	high = 'warning',
	medium = 'info',
	low = 'success',
	none = 'secondary',
	unknown = 'secondary',
}

// For sorting by severity feature
export enum VulSeverityPowerMapping {
	critical = 10,
	high = 8,
	medium = 5,
	low = 3,
	none = 1,
	unknown = 1,
}

export interface DashboardVulData {
	critical: number;
	high: number;
	medium: number;
	low: number;
	none: number;
}

export interface DashboardMalwareData {
	malwareFamily: string;
	description: string;
	count: number;
}
export enum ScanTimeType {
	SCAN_START_AT = 'scanStartAt',
	SCAN_END_AT = 'scanEndAt',
}

export enum ScanResourceType {
	ECR = 'ECR',
	Container = 'Container',
}

export interface RecentActivityItem {
	time: Date;
	timeType: ScanTimeType;
	type: ScanResourceType;
	detail: any;
}

export interface SuspiciousBehavior {
	network: number;
	file: number;
}

export interface Misconfiguration {
	count: number;
}

export interface EcrDashboard {
	vul: Vul;
	malware: any[];
	// suspiciousBehavior: SuspiciousBehavior;
	// misconfiguration: Misconfiguration;
}

export interface Vul {
	critical: number;
	high: number;
	medium: number;
	low: number;
	none: number;
}

export interface Malware {
	malwareFamily: string;
	description: string;
	count: number;
}

export interface SuspiciousBehavior {
	network: number;
	file: number;
}

export interface Misconfiguration {
	count: number;
}

export interface ContainerDashboard {
	vul: DashboardVulData;
	malware: Malware[];
	// suspiciousBehavior: SuspiciousBehavior;
	// misconfiguration: Misconfiguration;
}

export interface DashboardData {
	ecrDashboard?: EcrDashboard;
	containerDashboard?: ContainerDashboard;
}

export interface ScanResultSummary {
	malware: number;
	saasbom: number;
	vulnerabilties: Vulnerability;
}
