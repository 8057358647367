import boxShadows from '../base/boxShadows';

// types
type Types = any;

const sidenav: Types = {
	styleOverrides: {
		root: {
			boxShadow: boxShadows.xs,
		},
	},
};

export default sidenav;
