import MaterialTable, {MaterialTableProps} from '@material-table/core';
import {createTheme, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {Theme} from '@mui/material/styles';
import {useTheme} from '@mui/styles';
import tableContainer from 'assets/theme-dark/components/table/tableContainer';
import tableCell from 'assets/theme/components/table/tableCell';
import tableHead from 'assets/theme/components/table/tableHead';
import pxToRem from 'assets/theme/functions/pxToRem';
import MDBox from 'shared/MDBox';

const DBTable = (
	props: MaterialTableProps<any> & {customTitleSize?: number}
): JSX.Element => {
	const theme = useTheme<Theme>();
	const customTheme = createTheme({
		...theme,
		components: {
			MuiToolbar: {
				styleOverrides: {
					root: {
						paddingLeft: '0 !important',
						paddingRight: '0 !important',

						'& .MuiFormControl-root': {
							padding: '2px 10px',
							backgroundColor: theme.palette.white.main,
							border: `1px solid ${theme.palette.borderColor}`,
							borderRadius: '4px',
							'& .MuiInput-root': {
								'&::before': {
									borderBottom: 'none !important',
								},
								'&::after': {
									borderBottom: 'none',
								},
							},
						},
						'& .MuiTypography-root': {
							fontSize: props.customTitleSize
								? pxToRem(props.customTitleSize)
								: pxToRem(20),
						},
					},
				},
			},
			MuiTable: {
				styleOverrides: {
					root: {
						...tableContainer.styleOverrides.root,
						backgroundColor: theme.palette.background.paper,
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						...tableHead.styleOverrides.root,
						position: 'relative',
						boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
					},
				},
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						border: 'none',
						'&:nth-of-type(odd)': {
							backgroundColor: theme.palette.lessBlue.main,
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						...tableCell.styleOverrides.root,
						borderBottom: 'none',
						'& .MuiFormControl-root': {
							// padding: '2px 10px',
							// backgroundColor: theme.palette.white.main,
							// border: `1px solid ${theme.palette.borderColor}`,
							// borderRadius: '4px',

							'& .MuiOutlinedInput-root': {
								minHeight: pxToRem(36),
								minWidth: pxToRem(100),
								backgroundColor: theme.palette.white.main,
							},
							'& .MuiOutlinedInput-input': {
								paddingTop: pxToRem(8.5),
								paddingBottom: pxToRem(8.5),
							},
						},
					},
				},
			},
			MuiTablePagination: {
				styleOverrides: {
					select: {
						border: `1px solid ${theme.palette.borderColor}`,
						borderRadius: '2px',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						'& .MuiTableRow-root': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={customTheme}>
			<CssBaseline />
			<MDBox
				component="div"
				sx={{color: (theme) => theme.palette.text.primary}}
				className="DBTable"
			>
				<MaterialTable
					style={{
						fontSize: '14px',
						overflow: 'hidden',
						boxShadow: 'none',
						backgroundColor: 'transparent',
					}}
					{...props}
				/>
			</MDBox>
		</ThemeProvider>
	);
};

export default DBTable;
