import {CircularProgress, Divider, Icon, Skeleton, Stack} from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {GitHubUrlInput} from 'pages/DrCloud/CloudProtect/CloudProviders/GitHub/Details/AddRepoHint';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FreeToolProductPageHero from 'shared/DeepbitsSite/FreeToolProductPageHero';
import useSWR from 'swr';

import NotificationStore from 'store/notification';

import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import MDTypography from 'shared/MDTypography';
import {GetGitHubRepoRes} from 'types/githubApi';
import {
	callSharedApi,
	getSharedGithubAccountId,
	useSharedGithubAccountId,
} from 'utils/network';
import {getGitHubRepoInfo, parseGitHubRepoUrl} from 'utils/network/ghApi';
import gitHubHeroImage from './gitHubHero.svg';
import PublicGitHubRepoList, {
	GithubRepoItemDetail,
} from './PublicGitHubRepoList';
import {useLoadingIndicator} from 'utils/network/useLoadingIndicator';
import {useDispatch} from 'react-redux';
import React from 'react';
import {MaterialTableProps} from '@material-table/core';

type RepoItem = Awaited<ReturnType<typeof getGitHubRepoInfo>>;

const GitHubFreeTool = () => {
	useEffect(() => {
		document.title = t('gh_toolHeading2');
	}, []);

	const {t} = useTranslation('sbom');

	const [url, setUrl] = useState('');

	const [repoInfo, setRepoInfo] = useState<RepoItem | null>(null);

	const onGitHubUrlChange = async (val) => {
		setUrl(val);
		const parsed = parseGitHubRepoUrl(val);
		if (parsed) {
			const info = await getGitHubRepoInfo(
				parsed.username,
				parsed.repoName
			);

			setRepoInfo(info);
		}
	};

	const tableRef = React.useRef<MaterialTableProps<Record<string, never>>>();

	const onUpdate = () => {
		setUrl('');
		setRepoInfo(null);
		tableRef.current?.onQueryChange && tableRef.current?.onQueryChange();
	};

	return (
		<>
			<FreeToolProductPageHero
				header={t('gh_toolHeading2')}
				description={t('gh_secondaryDesc')}
				image={gitHubHeroImage}
			/>
			<MDBox my={6} sx={{maxWidth: '1500px', mx: 'auto'}}>
				<Grid container justifyContent="center">
					<Grid item xs={12} pb={2}>
						<Card>
							<MDBox p={3}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<MDTypography
											variant="h6"
											fontWeight="medium"
										>
											{t('gh_addGitHubRepoUrl')}
										</MDTypography>
										<MDBox mt={1} mb={2}>
											<MDTypography
												display="block"
												variant="body2"
												color={'text'}
											>
												{t(
													'gh_addGitHubRepoUrlSubtitle'
												)}
											</MDTypography>
										</MDBox>
										<GitHubUrlInput
											value={url}
											onChange={onGitHubUrlChange}
										/>
									</Grid>
									<Grid item xs={12}>
										{repoInfo && (
											<GitHubRepoInfo
												onUpdate={onUpdate}
												info={repoInfo}
											/>
										)}
									</Grid>
								</Grid>
							</MDBox>
						</Card>
						<MDBox mt={2}>
							<Card sx={{p: 2}}>
								<PublicGitHubRepoList tableRef={tableRef} />
							</Card>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</>
	);
};

export default GitHubFreeTool;

export const GitHubRepoInfo: React.FC<{
	info: RepoItem;
	onUpdate: () => void;
}> = ({info, onUpdate}) => {
	const {t} = useTranslation('sbom');
	const deepbitsRepoInfo = useFreeToolGitHubRepo(info.full_name);

	const {fetcher: onAddRepo, loading: isAddingRepo} =
		useFreeToolAddGitHubRepo(onUpdate);

	const githubAccountId = useSharedGithubAccountId();

	return (
		<MDBox mt={2}>
			<MDBox display="flex" alignItems="center">
				<MDTypography variant="h5" gutterBottom>
					<Link
						href={`https://github.com/${info.full_name}`}
						target="_blank"
						rel="noopener"
					>
						{info.full_name}
					</Link>
				</MDTypography>
				<GitHubStarInfo info={info} />
			</MDBox>
			<MDTypography gutterBottom variant="body1">
				{info.description}
			</MDTypography>
			{deepbitsRepoInfo?.loading ? (
				<MDBox>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</MDBox>
			) : (
				<MDBox>
					<Divider />
					{deepbitsRepoInfo &&
					deepbitsRepoInfo?.repo &&
					deepbitsRepoInfo.projectAsset ? (
						<MDBox>
							{(() => {
								const repo = deepbitsRepoInfo.repo;

								return (
									<GithubRepoItemDetail
										rowData={repo}
										githubAccountId={githubAccountId}
										onUpdate={() => {
											deepbitsRepoInfo.mutate();
										}}
									/>
								);
							})()}
						</MDBox>
					) : (
						<MDButton
							variant="contained"
							color="dark"
							onClick={() => onAddRepo(info.full_name)}
							disabled={isAddingRepo}
						>
							{isAddingRepo ? (
								<CircularProgress size={18} color="secondary" />
							) : (
								<>{t('gh_addRepo')}</>
							)}
						</MDButton>
					)}
				</MDBox>
			)}
		</MDBox>
	);
};

export const useFreeToolGitHubRepo = (fullName?: string) => {
	const [owner, repository] = fullName?.split('/') || [];

	const {data, error, mutate} = useSWR(
		owner && repository
			? `/api/v1/github/accountId/${owner}/${repository}`
			: null,
		async (url) => {
			const sharedAccountId = await getSharedGithubAccountId();

			return (
				url &&
				callSharedApi.get<GetGitHubRepoRes>(
					url.replace('accountId', sharedAccountId)
				)
			);
		}
	);
	const loading = !data && !error;
	if (loading) {
		return {loading: true, repo: null, projectAsset: null};
	}

	if (error || !data) {
		return null;
	}

	return {
		mutate,
		...data.data,
	};
};

const useFreeToolAddGitHubRepo = (onUpdate: () => void) => {
	const dispatch = useDispatch();
	const {t} = useTranslation('sbom');

	const addRepo = async (fullName: string) => {
		await callSharedApi.post<{
			repos: string[];
		}>('/api/v1/github/repos/add', {
			fullName,
		});

		dispatch(
			NotificationStore.actions.show({
				type: 'success',
				msg: t('gh_repoAdded'),
			})
		);
		onUpdate();
	};

	return useLoadingIndicator(addRepo);
};

export const GitHubStarInfo = ({info}: {info: RepoItem}) => {
	return (
		<Stack spacing={1} direction="row" sx={{ml: 2}}>
			<MDBox display="flex" alignItems="center">
				<MDBox sx={{fontSize: '16px'}}>
					<Icon>star_border</Icon>
				</MDBox>
				<MDTypography>{info.stargazers_count}</MDTypography>
			</MDBox>
			<MDBox display="flex" alignItems="center">
				<MDBox sx={{fontSize: '16px'}}>
					<Icon>fork_right</Icon>
				</MDBox>
				<MDTypography>{info.forks_count}</MDTypography>
			</MDBox>
			<MDBox display="flex" alignItems="center">
				<MDBox sx={{fontSize: '16px'}}>
					<Icon>code</Icon>
				</MDBox>
				<MDTypography>{info.language}</MDTypography>
			</MDBox>
		</Stack>
	);
};
