import {makeResource} from 'utils/saga-resource';
import {callApi} from 'utils/network';
import {put} from 'redux-saga/effects';

export interface Vulnerability {
	_id: string;
	name: string;
	description: string;
	vul_signatures: any[];
	type: string;
	score: number;
	cvelink: string;
	reference: string;
}

export interface VulnerabilitiesState {
	[key: string]: Vulnerability;
}

export interface VulnerabilitiesEffects {
	loadVulnerability: (payload: {
		vuldbId: string;
		softwareId: string;
		vulId: string;
	}) => any;

	createVulnerability: (payload: {
		vuldbId: string;
		softwareId: string;
		data: {
			name: string;
			description: string;
			type: string;
			cveLink: string;
			reference: string;
			score: string;
		};
	}) => any;

	addVulnerabilitySignature: (payload: {
		vuldbId: string;
		vulId: string;
		softwareId: string;
		data: {
			func: any;
			file: any;
			patchedFile: any;
		};
	}) => any;

	deleteVulnerabilityFunc: (payload: {
		vuldbId: string;
		vulId: string;
		softwareId: string;
		correlationKey: string;
	}) => any;

	deleteVulnerability: (payload: {
		vuldbId: string;
		softwareId: string;
		vulId: string;
	}) => any;
}

const vulnerabilities = makeResource<
	VulnerabilitiesState,
	any,
	VulnerabilitiesEffects
>({
	name: 'softwares',
	state: {},
	effects: {
		*loadVulnerability({vuldbId, softwareId, vulId}): any {
			yield put(vulnerabilities.actions.set({isLoading: true}));
			const vulnerability = (yield callApi(
				`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}/vulnerabilities/${vulId}`
			)).data;
			yield put(vulnerabilities.actions.set({[vulId]: vulnerability}));
			yield put(vulnerabilities.actions.set({isLoading: false}));
		},
		*createVulnerability(payload): any {
			const formData = new FormData();
			formData.append('name', payload.data.name);
			formData.append('type', payload.data.type);
			formData.append('cveLink', payload.data.cveLink);
			formData.append('reference', payload.data.reference);
			formData.append('score', payload.data.score);
			formData.append('description', payload.data.description);

			yield callApi(
				`/api/v1/vuldbs/${payload.vuldbId}/softwares/${payload.softwareId}/vuls`,
				{
					method: 'POST',
					data: formData,
				}
			);
		},
		*addVulnerabilitySignature({vuldbId, softwareId, vulId, data}): any {
			const formData = new FormData();

			formData.append('func', JSON.stringify(data.func));
			formData.append('file', data.file[0]);
			data.patchedFile &&
				formData.append('patchedFile', data.patchedFile[0]);

			yield callApi(
				`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}/vulnerabilities/${vulId}/signature`,
				{
					method: 'POST',
					data: formData,
				}
			);
		},
		*deleteVulnerabilityFunc({
			vuldbId,
			softwareId,
			vulId,
			correlationKey,
		}): any {
			yield callApi(
				`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}/vulnerabilities/${vulId}/func/${correlationKey}`,
				{
					method: 'DELETE',
				}
			);
		},
		*deleteVulnerability({vuldbId, softwareId, vulId}): any {
			yield callApi(
				`/api/v1/vuldbs/${vuldbId}/softwares/${softwareId}/vulnerabilities/${vulId}`,
				{
					method: 'DELETE',
				}
			);
		},
	},
});

export default vulnerabilities;
