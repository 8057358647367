import classNames from 'classnames';
import React from 'react';
import {Icon} from '@mui/material';

interface PopupStateType {
	isOpen: boolean;
}

const PopoverMenu: React.FC<{
	title: () => React.ReactNode;
	render: (popupState: PopupStateType) => React.ReactNode;
}> = ({title, render}) => {
	const popupState = {
		isOpen: false,
	};

	return (
		<div className="group relative">
			<div
				className={classNames(
					popupState.isOpen ? 'text-bitBlue' : 'text-black',
					'text-black rounded-md inline-flex items-center font-medium hover:text-bitblue outline-none'
				)}
				style={{cursor: 'pointer'}}
			>
				{title()}
				<div
					className={classNames(
						popupState.isOpen ? 'text-bitBlue' : 'text-black',
						'ml-1 h-5 w-5 group-hover:text-bitblue'
					)}
					aria-hidden="true"
				>
					<Icon
						className={classNames(
							popupState.isOpen ? 'text-bitBlue' : 'text-black',
							'ml-2 h-5 w-5 group-hover:text-bitblue'
						)}
						aria-hidden="true"
					>
						keyboard_arrow_down
					</Icon>
				</div>
			</div>
			<div className="hidden group-hover:flex absolute top-full left-2/4 -translate-x-1/2">
				<div className="z-10 left-1/2 mt-3 px-2">
					<div className="rounded-lg shadow-lg overflow-hidden bg-slate-100">
						{render(popupState)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PopoverMenu;
