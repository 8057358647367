import {Location} from 'react-router-dom';

export const pageview = (location: Location) => {
	if (!window.dataLayer) {
		return;
	}

	const extractedTitle = location.pathname.split('/').slice(1).shift();
	window.dataLayer.push({
		event: 'pageview',
		page: {
			url: location.pathname,
			title: extractedTitle?.toUpperCase() ?? location.pathname,
		},
	});
};

interface EventData {
	name: string;
	params?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams;
}

export const trackEvent = (event: EventData) => {
	if (!window.gtag) {
		return;
	}

	gtag('event', event.name, event.params);
};
