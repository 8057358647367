import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NotificationStore from 'store/notification';
import MDSnackbar from 'shared/MDSnackbar';

function GlobalNotification(): JSX.Element {
	const [isActive, setIsActive] = useState(false);

	const notificationMsg = useSelector(
		(state: AppState) => state.notification.msg
	);
	const notificationType = useSelector(
		(state: AppState) => state.notification.type
	);
	const dispatch = useDispatch();

	const handleClose = (): any =>
		dispatch(NotificationStore.actions.close(null));

	useEffect(() => {
		if (notificationMsg) {
			setIsActive(true);
		}
	}, [notificationMsg]);
	const onClose = (): void => {
		setIsActive(false);

		setTimeout(() => {
			handleClose();
		}, 500);
	};

	let icon = 'check';
	switch (notificationType) {
		case 'info':
			icon = 'notifications';
			break;
		case 'warning':
			icon = 'star';
			break;
		case 'error':
			icon = 'warning';
			break;
	}

	return notificationType ? (
		<MDSnackbar
			color={notificationType}
			icon={icon}
			title={notificationMsg}
			open={isActive}
			onClose={onClose}
			close={onClose}
			// bgWhite
			anchorOrigin={{vertical: 'top', horizontal: 'right'}}
		/>
	) : (
		<></>
	);
}

export default GlobalNotification;
