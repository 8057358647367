import {ListItem, ListItemIcon, ListItemText, Skeleton} from '@mui/material';
import {useGithubAccount} from 'pages/Settings/Github/GitHubAccount';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {useMaterialUIController} from 'shared/context';
import MDBox from 'shared/MDBox';
import {
	collapseIconBox,
	collapseItem,
	collapseText,
} from './styles/sidenavCollapse';

const GithubSidenavMenu: React.FC<{
	icon: ReactNode;
}> = ({icon}) => {
	const {accountRes: githubAccountRes} = useGithubAccount();

	const location = useLocation();

	const {t} = useTranslation();

	if (!githubAccountRes) {
		return (
			<GitHubAccountItem icon={icon}>
				<Skeleton
					width={100}
					height={35}
					sx={{bgcolor: 'grey.800', ml: 1}}
				/>
			</GitHubAccountItem>
		);
	}

	if (!Array.isArray(githubAccountRes.data)) {
		return (
			<Link to="/settings/github">
				<GitHubAccountItem icon={icon}>
					{t('github.account_link')}
				</GitHubAccountItem>
			</Link>
		);
	}

	return (
		<>
			{githubAccountRes.data.map((account) => (
				<GitHubAccountItem
					key={account._id}
					icon={icon}
					link={`/cloud/github/${account._id}/repos`}
					active={location.pathname.includes(account._id)}
				>
					{account.username}
				</GitHubAccountItem>
			))}
		</>
	);
};

const GitHubAccountItem: React.FC<{
	active?: boolean;
	icon: ReactNode;
	link?: string;
}> = ({icon, children, link, active}) => {
	const [controller] = useMaterialUIController();
	const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} =
		controller;

	const element = (
		<MDBox
			component={link ? 'a' : 'span'}
			href={link}
			sx={(theme: any) =>
				collapseItem(theme, {
					transparentSidenav,
					whiteSidenav,
					darkMode,
					miniSidenav,
					active,
				})
			}
		>
			<ListItemIcon
				sx={(theme) =>
					collapseIconBox(theme, {
						transparentSidenav,
						whiteSidenav,
						darkMode,
						active,
					})
				}
				style={{flexShrink: 0}}
			>
				{icon}
			</ListItemIcon>

			{typeof children === 'string' ? (
				<ListItemText
					primary={children}
					sx={(theme) =>
						collapseText(theme, {
							miniSidenav,
							transparentSidenav,
							whiteSidenav,
							active,
						})
					}
					style={{overflowWrap: 'break-word'}}
				/>
			) : (
				children
			)}
		</MDBox>
	);

	return <ListItem component="li">{element}</ListItem>;
};

export default GithubSidenavMenu;
