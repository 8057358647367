import {SBOMStreamDef, SBOMStreamType} from 'pages/Registry/StreamSourceTable';
import {ProjectAssetType} from '../projectsDef';

export const filterSingleStreamScanSummaryWithBatchSummaryData = ({
	streams,
	identifier,
	scanSummaries,
}: {
	streams: SBOMStreamDef[];
	identifier: string;
	scanSummaries: any;
}) => {
	const stream = streams?.find((s) => {
		switch (s?.streamType) {
			case SBOMStreamType.AwsEcrTag:
			case SBOMStreamType.DockerHubTag:
				return s.tagName === identifier;
			case SBOMStreamType.GitHubBranch:
				return s.branchName === identifier;
			default:
				return s.resourceId === identifier;
		}
	});

	const summary = scanSummaries?.find(
		(summary) => summary.streamId === stream?._id
	)?.summary;

	return summary;
};

const ProjectAssetTypeUINames = {
	[ProjectAssetType.Container]: 'Docker Image',
	[ProjectAssetType.AwsEcr]: 'AWS ECR',
	[ProjectAssetType.DockerHub]: 'Docker Hub',
	[ProjectAssetType.GitHubRepo]: 'GitHub Repository',
	[ProjectAssetType.SBOMBuilder]: 'File',
};

export const getProjectAssetTypeUIName = (
	assetType: ProjectAssetType,
	extraInfo?: string
) =>
	`${ProjectAssetTypeUINames[assetType]}${
		extraInfo ? ` (${extraInfo})` : ''
	}`;
