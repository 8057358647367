import brandWhiteMini from 'assets/img/logo-square-white@500w.png';
import brandDarkMini from 'assets/img/logo-square@500w.png';
import brandWhite from 'assets/img/logo-white@500w.png';
import brandDark from 'assets/img/logo@500w.png';
import Alert from 'components/Alert';
import {useFreeToolRoutes} from 'components/AppFrame/useFreeToolRoutes';
import GlobalNotification from 'components/GlobalNotification/GlobalNotification';
import {RECAPTCHA_SCRIPT} from 'constant';
import React, {
	JSXElementConstructor,
	Key,
	ReactElement,
	useEffect,
	useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';
import {setMiniSidenav, useMaterialUIController} from 'shared/context';
import DeepbitsSiteHeader from 'shared/DeepbitsSite/DeepbitsSiteHeader';
import DashboardLayout from 'shared/LayoutContainers/DashboardLayout';
import Sidenav from 'shared/Sidenav';
import 'styles/deepbitsShared.scss';
import i18n from 'utils/i18n/i18n';
import {useScript} from 'utils/useScript';
import sbomEnTranslation from './utils/i18n/locales/en/sbom.json';
import SiteEntryPointProvider, {
	SiteEntryPoint,
} from 'components/SiteEntryPoint';
import {ApiContextProvider} from 'components/ApiContext';

i18n.addResources('en', 'sbom', sbomEnTranslation);

const SBOMToolAppMain = () => {
	useScript(RECAPTCHA_SCRIPT);
	const [controller, dispatchMD] = useMaterialUIController();

	const {t} = useTranslation('sbom');

	useEffect(() => {
		document.title = t('slogan');
	}, []);

	const [onMouseEnter, setOnMouseEnter] = useState(false);

	const routes = useFreeToolRoutes();

	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatchMD, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatchMD, true);
			setOnMouseEnter(false);
		}
	};

	const getRoutes = (allRoutes: any[]): any =>
		allRoutes.map(
			(route: {
				collapse: any;
				route: string;
				component: ReactElement<
					any,
					string | JSXElementConstructor<any>
				>;
				key: Key;
				noPx?: boolean;
			}) => {
				if (route.collapse) {
					return getRoutes(route.collapse);
				}

				if (route.route) {
					return (
						<Route
							path={route.route}
							element={
								<DashboardLayout
									resetedLayout
									noPx={route.noPx}
								>
									{route.component}
								</DashboardLayout>
							}
							key={route.key}
						/>
					);
				}

				return null;
			}
		);

	const {
		miniSidenav,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;

	const routesToRender = getRoutes(routes);

	return (
		<div>
			<SiteEntryPointProvider value={SiteEntryPoint.PublicTool}>
				<ApiContextProvider value={SiteEntryPoint.PublicTool}>
					<DeepbitsSiteHeader />
					<Sidenav
						color={sidenavColor}
						brand={
							(transparentSidenav && !darkMode) || whiteSidenav
								? miniSidenav
									? brandDarkMini
									: brandDark
								: miniSidenav
								? brandWhiteMini
								: brandWhite
						}
						routes={routes.filter((x) => x.name)}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
						onMouseEnterActive={onMouseEnter}
						setOnMouseEnter={setOnMouseEnter}
						isFreeTool
					/>
					<Alert />
					<GlobalNotification />

					<React.Suspense fallback={<></>}>
						<Routes>{routesToRender}</Routes>
					</React.Suspense>
				</ApiContextProvider>
			</SiteEntryPointProvider>
		</div>
	);
};

export default SBOMToolAppMain;
