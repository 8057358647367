import {makeResource} from 'utils/saga-resource';
import {all, put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface CodeLine {
	vma: number;
	rawBytes: string;
	instStr: string;
	opcode: string;
	operands: string;
	stringRef: string | null;
	branchRef: string | null;
	targetRef: string | null;
	crossRef: string[];
	labelName: string | null;
	function: boolean;
	code: boolean;
	section_name: string | null;
	branch: string | null;
	branch_label: string | null;
	isBranch: boolean;
	isFunction: boolean;
	isCode: boolean;
}
export interface VulnerabilityState {
	vulFunction: CodeLine[];
	fileFunction: CodeLine[];
	vulGraph: string;
	fileGraph: string;
}

export interface VulnerabilityEffects {
	load: (payload: {
		scanId: string;
		vulId: string;
		fileId: string;
		fileOffset: number;
	}) => any;
	// loadFile: (payload: {
	// 	vulId: string;
	// 	fileId: string;
	// 	fileOffset: number;
	// }) => any;
	// loadGraph: (payload: {
	// 	vulId: string;
	// 	fileId: string;
	// 	fileOffset: number;
	// }) => any;
	loadResource: (payload: {
		scanId: string;
		vulId: string;
		fileId: string;
		fileOffset: number;
	}) => any;
	_loadVulFunction: (payload: {vulId: string}) => any;
	_loadVulGraph: (payload: {vulId: string}) => any;
	_loadFileFunction: (payload: {
		scanId: string;
		fileId: string;
		fileOffset: number;
	}) => any;
	_loadFileGraph: (payload: {
		scanId: string;
		fileId: string;
		fileOffset: number;
	}) => any;
}

const vulnerability = makeResource<
	VulnerabilityState,
	any,
	VulnerabilityEffects
>({
	name: 'scan_vulnerability',
	state: {
		vulFunction: [],
		fileFunction: [],
		vulGraph: '',
		fileGraph: '',
	},
	effects: {
		*_loadVulFunction({vulId}): any {
			try {
				const vulFunction = yield callApi(
					`/disassemble_text/vulnerabilities/${vulId}`
				);
				yield put(vulnerability.actions.set({vulFunction}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({vulFunction: []}));
				} else {
					throw err;
				}
			}
		},
		*_loadFileFunction({scanId, fileId, fileOffset}): any {
			try {
				const file = (yield callApi(
					`/api/v1/scans/${scanId}/files/${fileId}/offset/${fileOffset}/disassemble`
				)).data;
				let lines: any[] = [];
				for (const f of file) {
					for (const node of f.disassemble.nodes) {
						lines = lines.concat(node.instructions);
					}
				}
				yield put(vulnerability.actions.set({fileFunction: lines}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({fileFunction: []}));
				} else {
					throw err;
				}
			}
		},
		*_loadVulGraph({vulId}): any {
			try {
				const vulGraph = yield callApi(
					`/graph/dot/vulnerabilities/${vulId}`
				);
				yield put(vulnerability.actions.set({vulGraph}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({vulGraph: ''}));
				} else {
					throw err;
				}
			}
		},
		*_loadFileGraph({scanId, fileId, fileOffset}): any {
			try {
				const fileGraph = yield callApi(
					`scans/${scanId}/files/${fileId}/offset/${fileOffset}/graph/dot`
				);
				yield put(vulnerability.actions.set({fileGraph}));
			} catch (err: any) {
				if (err.isAxiosError && err.response.status == 404) {
					yield put(vulnerability.actions.set({fileGraph: ''}));
				} else {
					throw err;
				}
			}

			// try {
			// 	const fileGraph = yield callApi(
			// 		`/graph/dot/files/${fileId}/${fileOffset}`
			// 	);
			// 	yield put(vulnerability.actions.set({fileGraph}));
			// } catch (err) {
			// 	if (err.isAxiosError && err.response.status == 404) {
			// 		yield put(vulnerability.actions.set({fileGraph: ''}));
			// 	} else {
			// 		throw err;
			// 	}
			// }
		},
		*loadResource({scanId, vulId, fileId, fileOffset}): any {
			yield all([
				vulnerability.effects._loadVulFunction({vulId}),
				vulnerability.effects._loadFileFunction({
					scanId,
					fileId,
					fileOffset,
				}),
				vulnerability.effects._loadVulGraph({vulId}),
				vulnerability.effects._loadFileGraph({
					scanId,
					fileId,
					fileOffset,
				}),
			]);
		},
		*load({scanId, vulId, fileId, fileOffset}): any {
			yield put(vulnerability.actions.startLoading());
			yield vulnerability.effects.loadResource({
				scanId,
				vulId,
				fileId,
				fileOffset,
			});
			yield put(vulnerability.actions.endLoading());
		},
		// *loadFile({vulId, fileId, fileOffset}): any {
		// 	yield put(vulnerability.actions.startLoading());
		// 	yield all([
		// 		vulnerability.effects._loadVulFunction({vulId}),
		// 		vulnerability.effects._loadFileFunction({fileId, fileOffset}),
		// 	]);
		// 	yield put(vulnerability.actions.endLoading());
		// },
		// *loadGraph({vulId, fileId, fileOffset}): any {
		// 	yield put(vulnerability.actions.startLoading());
		// 	yield all([
		// 		vulnerability.effects._loadVulGraph({vulId}),
		// 		vulnerability.effects._loadFileGraph({fileId, fileOffset}),
		// 	]);
		// 	yield put(vulnerability.actions.endLoading());
		// },
	},
});

export default vulnerability;
