import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MDBox from 'shared/MDBox';
import MDTypography from 'shared/MDTypography';
import HeroBg from './freeToolProductHeroBg.png';

interface Props {
	header: string;
	description: string;
	image: any;
}

const FreeToolProductPageHero = (props: Props) => {
	const {header, description, image} = props;

	const useStyles = makeStyles(() =>
		createStyles({
			topBg: {
				backgroundImage: `url(${HeroBg})`,
				backgroundSize: 'cover',
			},
			topProductImage: {
				height: 'auto',
				flexBasis: '30%',
				flexGrow: 0,
				backgroundImage: `url(${image})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
			},
		})
	);

	const classes = useStyles();

	return (
		<>
			<MDBox className={classes.topBg} sx={{mx: -3}}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					px={2}
					sx={{maxWidth: '1500px', mx: 'auto'}}
				>
					<MDBox py={6}>
						<MDTypography
							variant="h4"
							color="dark"
							fontWeight="medium"
						>
							{header}
						</MDTypography>
						<MDTypography
							variant="body2"
							color="dark"
							fontWeight="regular"
							gutterBottom
						>
							{description}
						</MDTypography>
					</MDBox>
					<MDBox className={classes.topProductImage}></MDBox>
				</MDBox>
			</MDBox>
		</>
	);
};

export default FreeToolProductPageHero;
