import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface Project {
	dateCreated: string;
	description: string;
	_id: string;
	name: string;
	traffic: number;
}
export interface ProjectsState {
	data: Project[];
}

export interface ProjectsEffects {
	loadResource: () => any;
	load: () => any;
	removeProject: (id: string) => any;
	createProject: (project: any) => any;
}

const projects = makeResource<ProjectsState, any, ProjectsEffects>({
	name: 'projects',
	state: {
		data: [],
	},
	effects: {
		*loadResource(): any {
			const data = (yield callApi('/api/v1/projects')).data;
			yield put(projects.actions.set({data}));

			// const data = yield callApi('/projects');
			// yield put(projects.actions.set({data}));
		},
		*load(): any {
			yield put(projects.actions.startLoading());
			yield projects.effects.loadResource();
			yield put(projects.actions.endLoading());
		},
		*removeProject(id: string): any {
			yield put(projects.actions.startLoading());
			yield callApi(`/api/v1/projects/${id}`, {method: 'DELETE'});
			yield projects.effects.loadResource();
			yield put(projects.actions.endLoading());

			// yield put(projects.actions.startLoading());
			// yield callApi(`/projects/${id}`, {method: 'DELETE'});
			// yield projects.effects.loadResource();
			// yield put(projects.actions.endLoading());
		},
		*createProject(project: any): any {
			const {name, description} = project;
			yield put(projects.actions.startLoading());
			yield callApi(`/api/v1/projects`, {
				method: 'POST',
				data: {name, description},
			});
			yield projects.effects.loadResource();
			yield put(projects.actions.endLoading());

			// yield put(projects.actions.startLoading());
			// const formData = new FormData();
			// formData.append('name', project.name);
			// formData.append('description', project.description);
			// yield callApi(`/projects`, {method: 'POST', data: formData});
			// yield projects.effects.loadResource();
			// yield put(projects.actions.endLoading());
		},
	},
});

export default projects;
