import {makeResource} from 'utils/saga-resource';

export interface UserState {
	email: string;
	email_verified: boolean;
	name: string;
	nickname: string;
	picture: string;
	sub: string;
	updated_at: string;
}

const user = makeResource<UserState, any, any>({
	name: 'user',
	state: {
		email: '',
		email_verified: true,
		name: '',
		nickname: '',
		picture: '',
		sub: '',
		updated_at: '',
	},
});

export default user;
