const MalwareIcon = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_313_3844)">
				<path
					d="M35.9429 31.3831H4.05714C2.92057 31.3831 2 30.4585 2 29.3171V8.41996C2 7.27852 2.92057 6.354 4.05714 6.354H35.9429C37.0794 6.354 38 7.27852 38 8.41996V29.3171C38 30.4585 37.0794 31.3831 35.9429 31.3831Z"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M10.0846 36.1348H29.9155"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M15.4846 31.7705V36.1348"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M24.5154 31.7705V36.1348"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M2.51944 26.6211H37.4806"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M18.6629 2.77473L8.74229 20.0358C8.15086 21.0688 8.89143 22.36 10.0794 22.36H29.9257C31.1137 22.36 31.8543 21.0688 31.2629 20.0358L21.3371 2.77473C20.7406 1.74176 19.2594 1.74176 18.6629 2.77473Z"
					fill="white"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M20 7.72803V14.4062"
					stroke="#242628"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M20 19.4573C20.8521 19.4573 21.5429 18.7636 21.5429 17.9079C21.5429 17.0521 20.8521 16.3584 20 16.3584C19.1479 16.3584 18.4572 17.0521 18.4572 17.9079C18.4572 18.7636 19.1479 19.4573 20 19.4573Z"
					fill="#242628"
				/>
			</g>
			<defs>
				<clipPath id="clip0_313_3844">
					<rect width="40" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default MalwareIcon;
