import {IconButton, Menu} from '@mui/material';
import NotificationIcon from 'components/Icons/NotificationIcon';
import {useState} from 'react';
import NotificationItem from 'shared/Items/NotificationItem';
import MDBadge from 'shared/MDBadge';
import MDBox from 'shared/MDBox';
import {AccountMenu} from './AccountMenu';

enum MenuType {
	Notificaiton,
	RegionSelection,
}

const PageTopBarMenus = () => {
	const [openMenu, setOpenMenu] = useState<any>(false);
	const [menuType, setMenuType] = useState<MenuType | null>(null);

	const handleOpenMenu = (menuType: MenuType) => (event: any) => {
		setMenuType(menuType);
		setOpenMenu(event.currentTarget);
	};

	const handleCloseMenu = () => setOpenMenu(false);

	const renderMenu = () => (
		<Menu
			id={
				menuType === MenuType.Notificaiton
					? 'notifications'
					: 'region-selection'
			}
			aria-labelledby={
				menuType === MenuType.Notificaiton
					? 'notifications'
					: 'region-selection'
			}
			anchorEl={openMenu}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={() => ({
				mt: 4,
			})}
		>
			{menuType === MenuType.Notificaiton && (
				<NotificationItem
					icon={<NotificationIcon />}
					title="No new notification"
				/>
			)}
		</Menu>
	);
	return (
		<MDBox display="flex" alignItems="center">
			{/* <IconButton
				size="small"
				color="inherit"
				onClick={handleOpenMenu(MenuType.Notificaiton)}
			>
				<MDBadge badgeContent={0} color="error" size="xs" circular>
					<NotificationIcon />
				</MDBadge>
			</IconButton>
			{renderMenu()} */}
			<AccountMenu />
		</MDBox>
	);
};

export default PageTopBarMenus;
