import axios, {AxiosError, AxiosInstance} from 'axios';
import {AUTH0_AUDIENCE, RECAPTCHA_RENDER} from 'constant';
import useSWRImmutable from 'swr/immutable';
import {AxiosResponse} from 'axios';
import {GitHubAccountItem} from 'pages/Settings/Github/GitHubAccount';
import {SiteEntryPoint} from 'components/SiteEntryPoint';

let authTokenGetter;
export const setAuthTokenGetter = (fn) => {
	authTokenGetter = fn;
};

export async function getAuthToken(): Promise<string | null> {
	return new Promise((res, rej) => {
		const getToken = async () => {
			if (authTokenGetter) {
				const accessToken = await authTokenGetter({
					audience: AUTH0_AUDIENCE,
					scope: 'profile all:drcloud',
				});

				if (!accessToken) {
					rej(null);
				}
				res(`Bearer ${accessToken}`);
			}

			setTimeout(() => {
				getToken();
			}, 500);
		};

		return getToken();
	});
}

const onError = (error: Error): any => {
	return Promise.reject(error);
};

function createInstance(baseUrl: string, useJwtToken = true): AxiosInstance {
	const instance = axios.create({
		baseURL: baseUrl,
	});

	const isPdfExport = baseUrl === REPORT_SERVICE_BASE_URL;

	const headers = {
		'Content-Type': 'application/json',
	};

	if (/ngrok/.test(baseUrl)) {
		headers['ngrok-skip-browser-warning'] = 'yes';
	}

	instance.interceptors.request.use(async (config) => {
		return {
			...config,
			headers: {
				...(useJwtToken
					? {
							Authorization: await getAuthToken(),
					  }
					: {
							'x-public-tool': true,
					  }),
				...(isPdfExport && {'export-service-url': baseUrl}),
				...headers,
				...(config.headers || {}),
			},
		};
	}, onError);

	instance.interceptors.response.use((response) => {
		// if (response.data && response.data.data) {
		// 	// flatten the response.data,
		// 	// `response.data:{data:{/* data */}, meta:{}}` => response.data = {/* data */}
		// 	response.data = response.data.data;
		// }
		return response.data;
	}, onError);
	return instance;
}

export const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
const REPORT_SERVICE_BASE_URL = process.env
	.REACT_APP_REPORT_SERVICE_BASE_URL as string;
export const callApi = createInstance(BASE_URL);

export const callExportApi = createInstance(REPORT_SERVICE_BASE_URL);

export const callSBOMApi = createInstance(BASE_URL, false);

const sleep = (ms: number) => {
	return new Promise((res) => setTimeout(res, ms));
};

const getGrecaptchaToken = async (captchaAction: string) => {
	const result = window.grecaptcha?.enterprise.execute(RECAPTCHA_RENDER, {
		action: captchaAction,
	});

	if (!result) {
		await sleep(1000);
		return getGrecaptchaToken(captchaAction);
	}

	return result;
};

export const callSBOMApiWithCaptcha = (captchaAction: string) => {
	const instance = createInstance(BASE_URL, false);

	instance.interceptors.request.use(
		async function (config) {
			const token = await getGrecaptchaToken(captchaAction);

			config.params = {
				...(config.params || {}),
				captcha_token: token,
			};
			return config;
		},
		function (error: AxiosError) {
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error: AxiosError) {
			if (error.response?.status === 429) {
				alert('Too many request, please try again later');
			} else if (error.response?.status === 500) {
				alert(error.message);
			}

			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error);
		}
	);

	return instance;
};

export const callSharedApi = callSBOMApiWithCaptcha('public_tool');
export const getApiEntry = (siteEntry: SiteEntryPoint) =>
	siteEntry === SiteEntryPoint.InApp ? callApi : callSharedApi;

export interface PaginatedDocs<T> {
	docs: T;
	totalDocs: number;
	offset: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage?: any;
	nextPage?: any;
}
export interface PaginateParams {
	page: number;
	limit: number;
	sort: string;
	search: string;
}

interface TableData {
	columnOrder: number;
	groupSort: string;
	width: string;
	initialWidth: string;
	widthPx?: any;
	additionalWidth: number;
	id: number;
}

interface OrderBy {
	title: string;
	field: string;
	tableData: TableData;
}

export interface DBTableQuery {
	filters: any[];
	orderBy?: OrderBy;
	orderDirection?: string;
	page: number;
	pageSize: number;
	search: string;
	totalCount: number;
	error: boolean;
}
export const paginateParams = (
	query: DBTableQuery
): Partial<PaginateParams> => {
	let sortStr = '';

	if (query.orderBy) {
		sortStr = query.orderBy.field;
	}

	if (query.orderDirection === 'desc') {
		sortStr = `-${sortStr}`;
	}

	return {
		page: query.page + 1,
		limit: query.pageSize,
		sort: sortStr,
		search: query.search,
	};
};

export interface ServerRes<T> {
	meta: {
		code: number;
		message: string;
	};
	data: T;
}

interface UserRes {
	_id: string;
	publicTool: {
		sharedProjectId: string;
	};
}

export const useSharedProjectId = (useDemoUser?: boolean) => {
	const {data: userRes} = useSWRImmutable<AxiosResponse<UserRes>>(
		['/api/v1/user', useDemoUser],
		([url]) =>
			callSharedApi.get(url, {
				...(useDemoUser && {
					headers: {
						'x-demo-user': 'true',
					},
				}),
			})
	);

	if (!userRes) {
		return null;
	}

	return userRes.data?.publicTool?.sharedProjectId;
};

let cachedSharedProjectId: string | null = null;

export const getSharedProjectId = async (useDemoUser?: boolean) => {
	if (cachedSharedProjectId) {
		return cachedSharedProjectId;
	}

	const userRes = await callSharedApi.get<UserRes>('/api/v1/user', {
		headers: {
			'x-demo-user': useDemoUser ? 'true' : 'false',
		},
	});
	const projectId = userRes.data?.publicTool?.sharedProjectId;
	cachedSharedProjectId = projectId;
	return projectId;
};

let cachedGhAccountId: string | null = null;
export const getSharedGithubAccountId = async () => {
	if (cachedGhAccountId) {
		return cachedGhAccountId;
	}

	const accounts = await callSharedApi.get<GitHubAccountItem[]>(
		`/api/v1/github/account`
	);

	const id = accounts.data[0]?._id;
	cachedGhAccountId = id;
	return id;
};

export const useSharedGithubAccountId = (useDemoUser?: boolean) => {
	const {data: accounts} = useSWRImmutable<
		AxiosResponse<GitHubAccountItem[]>
	>([`/api/v1/github/account`, useDemoUser], ([url]) =>
		callSharedApi.get(url, {
			...(useDemoUser && {
				headers: {
					'x-demo-user': 'true',
				},
			}),
		})
	);

	if (!accounts || !accounts.data?.length) {
		return null;
	}

	return accounts.data[0]?._id;
};
