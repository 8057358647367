import {
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import MDAvatar from 'shared/MDAvatar';
import MDBox from 'shared/MDBox';

export const AccountMenu = () => {
	const user = useSelector((state: AppState) => state.user);
	const initials = user.nickname || user.name;

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current?.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const navigate = useNavigate();
	const navigateTo = (path, state) => () => navigate(path, state && {state});

	const menus = [
		// {
		// 	label: 'Account',
		// 	path: '/pricing',
		// },
		// {
		// 	label: 'Billing',
		// 	path: '/billing',
		// },
		{
			label: 'Settings',
			path: '/settings/notifications',
		},
		{
			label: 'Team',
			path: '/team',
		},
		{
			label: 'Logout',
			path: '/logout',
			state: {logoutClicked: true},
		},
	];

	return (
		<>
			<Button
				ref={anchorRef}
				id="account-menu-button"
				aria-controls={open ? 'account-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				size="small"
				sx={{
					fontWeight: 'normal',
				}}
			>
				<MDAvatar src={user.picture} sx={{width: 32, height: 32}}>
					{initials.substring(0, 1)}
				</MDAvatar>
				<MDBox component="span" ml={1}>
					{initials}
				</MDBox>
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-start"
				transition
				disablePortal
				sx={() => ({zIndex: 999})}
			>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom-start'
									? 'left top'
									: 'left bottom',
						}}
					>
						<Paper
							sx={(theme) => ({
								boxShadow: theme.boxShadows.md,
							})}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									id="account-menu"
									aria-labelledby="account-menu-button"
									onKeyDown={handleListKeyDown}
									sx={{overflow: 'hidden'}}
								>
									{menus.map((x) => (
										<MenuItem
											key={x.path}
											onClick={navigateTo(
												x.path,
												x.state
											)}
											sx={(theme) => ({
												borderRadius: 0,
												transition: 'all 300ms',
												'&:hover, &:focus': {
													color: theme.palette.white
														.main,
													backgroundColor:
														theme.palette.info.main,
												},
											})}
										>
											{x.label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
