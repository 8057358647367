import collapseIcon from 'components/AppFrame/sidebarLogos/collapse.svg';
import MDBox from 'shared/MDBox';

import {setMiniSidenav, useMaterialUIController} from 'shared/context';

const SidenavCollapseButton: React.FC<{
	onMouseEnterActive: boolean;
	setOnMouseEnter: (val: boolean) => void;
}> = ({onMouseEnterActive, setOnMouseEnter}) => {
	const [controller, dispatch] = useMaterialUIController();
	const {miniSidenav} = controller;

	const handleMiniSidenav = () => {
		if (onMouseEnterActive) {
			setOnMouseEnter(false);
			return setMiniSidenav(dispatch, false);
		}

		setMiniSidenav(dispatch, !miniSidenav);
	};

	return (
		<MDBox
			role="button"
			aria-label="collapse sidenav"
			onClick={handleMiniSidenav}
			sx={{
				cursor: 'pointer',
				position: 'absolute',
				bottom: '12px',
				right: '18px',

				'&:hover': {
					opacity: 0.7,
				},

				'& img': {
					transition: 'transform 300ms',
					transform:
						miniSidenav || onMouseEnterActive === true
							? `rotate(180deg)`
							: '',
				},
			}}
		>
			<img width="24" height="24" src={collapseIcon} />
		</MDBox>
	);
};

export default SidenavCollapseButton;
