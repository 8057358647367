import GitHubIcon from '@mui/icons-material/GitHub';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
	Autocomplete,
	CircularProgress,
	Icon,
	InputAdornment,
	Link,
	Tab,
	TextField,
} from '@mui/material';
import Dialog from 'components/Dialog';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import MDTypography from 'shared/MDTypography';
import NotificationStore from 'store/notification';
import {GitHubRepoItem} from 'types/githubApi';
import {METERING_CONTROL_MSG} from 'types/meterControlType';
import {callApi} from 'utils/network';
import {
	getGitHubRepoInfoWithUserToken,
	parseGitHubRepoUrl,
} from 'utils/network/ghApi';
import {useLoadingIndicator} from 'utils/network/useLoadingIndicator';
import {useGithubRepos} from './GithubRepos';

enum AddRepoTab {
	YourRepository = '0',
	PublicRepository = '1',
}

const AddRepoHint: React.FC<{
	open: boolean;
	onClose: () => void;
	accountId: string;
	appSettingsUrl?: string;
	onPendingAddRepo?: (repos: string[]) => void;
}> = ({open, onClose, accountId, appSettingsUrl, onPendingAddRepo}) => {
	const {t} = useTranslation();
	const {reposRes, mutate: mutateRepos} = useGithubRepos(
		accountId as string,
		{
			showAdded: false,
			hidePublic: false,
		}
	);

	const repos = reposRes?.data.repos;

	const dispatch = useDispatch();

	const [reposToAdd, setReposToAdd] = useState<string[]>([]);
	const [addRepoTab, setAddRepoTab] = useState(AddRepoTab.YourRepository);

	const onConfirmFn = async (repoIds: string[]) => {
		try {
			const addedResult = await callApi.post<{added: string[]}>(
				`/api/v1/github/${accountId}/repos/add`,
				{
					repoIds,
				}
			);

			if (onPendingAddRepo) {
				onPendingAddRepo(addedResult.data.added);
			}

			dispatch(
				NotificationStore.actions.show({
					msg: t('github.repo_added'),
					type: 'success',
				})
			);
		} catch (e: any) {
			dispatch(
				NotificationStore.actions.show({
					type: 'error',
					msg:
						METERING_CONTROL_MSG[e?.response?.data?.data] ??
						t('triggerScanFailed'),
				})
			);
		}

		setReposToAdd([]);
		onClose();
	};
	const {fetcher: onConfirm, loading: isConfirming} =
		useLoadingIndicator(onConfirmFn);

	const autoCompleteOptions = useMemo(() => {
		return (
			repos
				?.filter((x) => !x.added && !x.deletedOnSource)
				.map((x) => ({
					label: (x.full_name || x.name).concat(
						x.private ? ' (Private)' : ''
					),
					value: x._id,
				})) || []
		);
	}, [repos]);

	const {onRefreshRepoList, isRefreshingRepoList} = useRefreshingRepoList(
		accountId as string,
		() => mutateRepos()
	);

	return (
		<Dialog
			loading={!reposRes}
			title={t('github.addRepo')}
			open={open}
			onClose={(e, reason) => {
				if (reason !== 'backdropClick') {
					onClose();
				}
			}}
			disableEscapeKeyDown
			primaryAction={{
				destructive: false,
				content: t('confirm'),
				onAction: () => onConfirm(reposToAdd),
				disabled: reposToAdd.length !== 0,
				loading: isConfirming,
			}}
			contentProps={{
				sx: {
					'&': {
						padding: '0 !important',
					},
				},
			}}
		>
			<TabContext value={addRepoTab}>
				<MDBox sx={{borderBottom: 1, borderColor: 'divider'}}>
					<TabList
						onChange={(e, val) => {
							setAddRepoTab(val);
						}}
						aria-label="Add repository panel"
					>
						<Tab
							label={t('github.tab_your')}
							value={AddRepoTab.YourRepository}
							{...a11yProps(0)}
						/>
						<Tab
							label={t('github.tab_public')}
							value={AddRepoTab.PublicRepository}
							{...a11yProps(1)}
						/>
					</TabList>
				</MDBox>
				<TabPanel value={AddRepoTab.YourRepository}>
					<MDBox
						sx={{minHeight: '250px'}}
						display="flex"
						flexDirection="column"
					>
						<MDBox flexGrow={1} mt={1}>
							<Autocomplete
								// getOptionDisabled={() =>
								// 	reposToAdd.length >= maxUsageAllowed
								// }
								disablePortal
								multiple
								getOptionLabel={(option) => option.label}
								options={autoCompleteOptions}
								onChange={(e, newVal) => {
									setReposToAdd(newVal.map((x) => x.value));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t('github.selectRepos')}
									/>
								)}
							/>
							{/* <MeteringInfoPanel
								maxUsageAllowed={maxUsageAllowed}
								quota={quota}
								hasChosenPlan={hasChosenPlan}
							/> */}
						</MDBox>

						<MDBox>
							<MDButton
								variant="outlined"
								size="small"
								color="secondary"
								disabled={isRefreshingRepoList}
								onClick={onRefreshRepoList}
								startIcon={
									isRefreshingRepoList ? (
										<CircularProgress size={22} />
									) : null
								}
							>
								{t('github.refreshRepoList')}
							</MDButton>
						</MDBox>
						{appSettingsUrl && (
							<MDTypography variant="caption" sx={{mt: 1}}>
								{t('github.notSeeRepo')}
								<Link
									href={appSettingsUrl}
									target="_blank"
									rel="noopener"
									sx={{
										ml: 1,
										color: (theme) =>
											theme.palette.info.main,
									}}
								>
									{t('github.checkoutGithubAppSettings')}
								</Link>
							</MDTypography>
						)}
					</MDBox>
				</TabPanel>
				<TabPanel value={AddRepoTab.PublicRepository}>
					<MDBox
						sx={{minHeight: '250px'}}
						display="flex"
						flexDirection="column"
					>
						<AddRepoByUrl
							githubAccountId={accountId as string}
							onAdd={(repos: string[]) => {
								setReposToAdd(repos);
							}}
						/>
						{/* <MeteringInfoPanel
							maxUsageAllowed={maxUsageAllowed}
							quota={quota}
							hasChosenPlan={hasChosenPlan}
						/> */}
					</MDBox>
				</TabPanel>
			</TabContext>
		</Dialog>
	);
};

export default AddRepoHint;

function a11yProps(index: number) {
	return {
		id: `addrepo-tab-${index}`,
		'aria-controls': `addrepo-tabpanel-${index}`,
	};
}

// const MeteringInfoPanel: React.FC<{
// 	maxUsageAllowed: number;
// 	quota: number;
// 	hasChosenPlan: boolean;
// }> = ({hasChosenPlan, maxUsageAllowed, quota}) => {
// 	const {t} = useTranslation();

// 	if (!hasChosenPlan) {
// 		return (
// 			<MDTypography variant="caption" sx={{mt: 1}}>
// 				{t('noPlanChosen')}
// 			</MDTypography>
// 		);
// 	}

// 	if (quota !== Number.MAX_SAFE_INTEGER) {
// 		return (
// 			<MDTypography variant="caption" sx={{mt: 1}}>
// 				{t(
// 					maxUsageAllowed <= 0
// 						? 'github.switchToProPlanWith0OrNegativeRemainingRepoCount'
// 						: 'github.switchToProPlan',
// 					{
// 						remainingRepoCount: maxUsageAllowed,
// 					}
// 				)}
// 			</MDTypography>
// 		);
// 	}

// 	return <></>;
// };

const AddRepoByUrl: React.FC<{
	onAdd: (repos: string[]) => void;
	githubAccountId: string;
}> = ({onAdd, githubAccountId}) => {
	const {t} = useTranslation();
	const [url, setUrl] = useState('');

	const [reposToSelect, setReposToSelect] = useState([] as GitHubRepoItem[]);
	const [selectedRepo, setSelectedRepo] = useState([] as GitHubRepoItem[]);

	useEffect(() => {
		onAdd(selectedRepo.map((x) => `pub:::${x.full_name}`));
	}, [selectedRepo]);

	const [showLoading, setShowLoading] = useState(false);

	const setRepoToSelectByUrl = useCallback(
		_.debounce(async (url) => {
			setShowLoading(true);
			const parsed = parseGitHubRepoUrl(url);
			if (parsed) {
				const info = await getGitHubRepoInfoWithUserToken(
					githubAccountId,
					parsed.username,
					parsed.repoName
				);

				setShowLoading(false);

				if (info && !reposToSelect.some((x) => x.id === info.id)) {
					setReposToSelect([...reposToSelect, info]);
				}
			}
		}),
		[]
	);

	const onGitHubUrlChange = async (val) => {
		setUrl(val);
		setRepoToSelectByUrl(val);
	};

	return (
		<MDBox>
			<GitHubUrlInput
				loading={showLoading}
				value={url}
				onChange={onGitHubUrlChange}
			/>
			<MDTypography variant="button" gutterBottom>
				{t('github.selectedReposToAdd')}({selectedRepo.length}):
			</MDTypography>
			<MDBox>
				{reposToSelect.map((x) => {
					const isSelected = selectedRepo.find(
						(item) => item.id === x.id
					);

					return (
						<MDButton
							variant="outlined"
							color={isSelected ? 'success' : 'info'}
							key={x.id}
							size="small"
							sx={{margin: '0 5px 5px 0'}}
							onClick={() => {
								if (isSelected) {
									setSelectedRepo(
										selectedRepo.filter(
											(item) => item.id !== x.id
										)
									);
								} else {
									setUrl('');
									setSelectedRepo([x, ...selectedRepo]);
								}
							}}
							startIcon={
								isSelected ? (
									<Icon>check</Icon>
								) : (
									<Icon>add</Icon>
								)
							}
						>
							{x.full_name}
						</MDButton>
					);
				})}
			</MDBox>
		</MDBox>
	);
};

export const GitHubUrlInput: React.FC<{
	value: string;
	onChange: (val: string) => void;
	loading?: boolean;
}> = ({value, onChange, loading}) => {
	const [error, setError] = useState(false);

	const onUpdate = (e) => {
		const val = e.target.value;
		onChange(e.target.value);
		const parsed = parseGitHubRepoUrl(val);
		if (!parsed && val !== '') {
			setError(true);
		} else {
			setError(false);
		}
	};

	return (
		<TextField
			sx={{
				my: 1,
			}}
			value={value}
			onChange={onUpdate}
			fullWidth
			variant="standard"
			placeholder="https://github.com/nginx/nginx"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{loading ? (
							<CircularProgress size={16} />
						) : (
							<GitHubIcon />
						)}
					</InputAdornment>
				),
				endAdornment: error && (
					<InputAdornment position="end">
						<MDBox
							component="span"
							sx={{
								color: (theme) => theme.palette.error.main,
							}}
						>
							<Icon>error</Icon>
						</MDBox>
					</InputAdornment>
				),
			}}
		/>
	);
};

const useRefreshingRepoList = (accountId: string, onUpdate: () => void) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const onRefreshFn = async () => {
		await callApi.post(`/api/v1/github/${accountId}/repos`);
		onUpdate();
		dispatch(
			NotificationStore.actions.show({
				msg: t('github.refresh_repo_success'),
				type: 'success',
			})
		);
	};

	const {fetcher: onRefreshRepoList, loading: isRefreshingRepoList} =
		useLoadingIndicator(onRefreshFn);
	return {onRefreshRepoList, isRefreshingRepoList};
};
