import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface MalwareListItem {
	malwareName: string;
	_id: string;
	similar_files: [any];
}
export interface MalwareDb {
	malwareDbId: string;
	malwareDbName: string;
	description: string;
	malwareList: MalwareListItem[];
}

export interface MalwareDbState {
	data: MalwareDb;
}

export interface MalwareDbEffects {
	load: (payload: {malwareDbId: string}) => any;
	loadResource: (payload: {malwareDbId: string}) => any;
}

const malwareDb = makeResource<MalwareDbState, any, MalwareDbEffects>({
	name: 'malwareDb',
	state: {
		data: {
			malwareDbId: '',
			malwareDbName: '',
			description: '',
			malwareList: [],
		},
	},
	effects: {
		*loadResource({malwareDbId}): any {
			const data = (yield callApi(`/api/v1/malwaredbs/${malwareDbId}/`))
				.data;
			yield put(malwareDb.actions.set({data}));
		},
		*load({malwareDbId}): any {
			yield put(malwareDb.actions.startLoading());
			yield malwareDb.effects.loadResource({malwareDbId});
			yield put(malwareDb.actions.endLoading());
		},
	},
});

export default malwareDb;
