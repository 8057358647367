import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface Vul {
	_id: string;
	name: string;
	description: string;
	cvelink: string;
	functionName: string;
	offset: number;
	score: number;
	patchedDisassembleData: any;
}

export interface VulDBState {
	_id: string;
	name: string;
	softwares: any[];
	description: string;
	updatedAt: string;

	uploading: boolean;
}

export interface ProductEffects {
	loadResource: (payload: {id: string}) => any;
	load: (payload: {id: string}) => any;
	uploadFunction: (payload: {
		id: string;
		data: {
			name: string;
			description: string;
			type: string;
			functionName: string;
			offset: string;
			score: string;
			files: any;
		};
	}) => any;
	createSoftware: (payload: {
		id: string;
		name: string;
		description: string;
	}) => any;
	removeFunction: (payload: {vuldbId: string; vulId: string}) => any;
}

const vuldb = makeResource<VulDBState, any, ProductEffects>({
	name: 'vuldb',
	state: {
		_id: '',
		name: '',
		softwares: [],
		description: '',
		updatedAt: '',

		uploading: false,
	},
	effects: {
		*loadResource({id}): any {
			const data = (yield callApi(`/api/v1/vuldbs/${id}`)).data;
			yield put(vuldb.actions.set(data));
		},
		*load({id}): any {
			yield put(vuldb.actions.startLoading());
			try {
				yield vuldb.effects.loadResource({id});
			} catch (err) {
				throw err;
			} finally {
				yield put(vuldb.actions.endLoading());
			}
		},
		*uploadFunction(payload): any {
			const formData = new FormData();
			formData.append('name', payload.data.name);
			formData.append('type', payload.data.type);
			formData.append('functionName', payload.data.functionName);
			formData.append('offset', payload.data.offset);
			formData.append('score', payload.data.score);
			formData.append('description', payload.data.description);
			formData.append('file', payload.data.files[0]);
			try {
				yield put(vuldb.actions.set({uploading: true}));
				yield callApi(`/api/v1/vuldbs/${payload.id}/vuls`, {
					method: 'POST',
					data: formData,
				});
				yield vuldb.effects.loadResource({id: payload.id});
			} catch (err) {
				throw err;
			} finally {
				yield put(vuldb.actions.set({uploading: false}));
			}
		},
		*removeFunction({vuldbId, vulId}): any {
			yield put(vuldb.actions.startLoading());
			try {
				yield callApi(
					`/private_vulnerabilities/${vuldbId}/vulnerabilities/${vulId}`,
					{method: 'DELETE'}
				);
				yield vuldb.effects.loadResource({id: vuldbId});
			} catch (err) {
				throw err;
			} finally {
				yield put(vuldb.actions.endLoading());
			}
		},
		*createSoftware({id, name, description}): any {
			yield put(vuldb.actions.startLoading());
			try {
				yield callApi(`/api/v1/vuldbs/${id}/softwares`, {
					method: 'POST',
					data: {name, description},
				});
				yield vuldb.effects.loadResource({id});
			} catch (err) {
				throw err;
			} finally {
				yield put(vuldb.actions.endLoading());
			}
		},
	},
});

export default vuldb;
