import {Link, Skeleton} from '@mui/material';
import {AxiosResponse} from 'axios';
import ContainerListSummaryCard from 'pages/DrCloud/ContainerDashboard/components/ContainerListSummaryCard';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import FreeToolProductPageHero from 'shared/DeepbitsSite/FreeToolProductPageHero';
import MDAlert from 'shared/MDAlert';
import MDBox from 'shared/MDBox';
import MDTypography from 'shared/MDTypography';
import useSWR from 'swr';
import {DashboardVulData} from 'types/dashboardDataType';
import {GetGitHubRepoRes} from 'types/githubApi';
import {callSharedApi, useSharedGithubAccountId} from 'utils/network';
import {GitHubStarInfo} from '.';
import SBOMBuilderHeroImage from '../SBOMTool/sbomBuilderHero.png';
import {GithubRepoItemDetail} from './PublicGitHubRepoList';

const PublicGitHubRepoDetail: React.FC = () => {
	// const {onRefreshRepoList, isRefreshingRepoList} = useRefreshPublicRepo(
	// 	() => {}
	// );

	const {owner, repository} = useParams<{
		owner: string;
		repository: string;
	}>();
	const accountId = useSharedGithubAccountId();

	const {data: repoData, mutate} = useSWR<AxiosResponse<GetGitHubRepoRes>>(
		accountId ? `/api/v1/github/${accountId}/${owner}/${repository}` : null,
		callSharedApi.get
	);
	const {t} = useTranslation('sbom');

	const assetId = repoData?.data?.projectAsset?._id;

	const {data: scanSummaryData} = useSWR<{
		data: any;
	}>(
		assetId ? `/api/v1/project_scans?assetIds=${assetId}` : null,
		callSharedApi.get,
		{
			refreshInterval: 5000,
		}
	);

	const summary = scanSummaryData?.data?.[0]?.summary;

	return (
		<>
			<FreeToolProductPageHero
				header={t('DeepSCA')}
				description={t('DeepSCAHeading')}
				image={SBOMBuilderHeroImage}
			/>

			<MDBox my={6} sx={{maxWidth: '1500px', mx: 'auto'}}>
				{repoData && repoData.data ? (
					repoData.data.repo ? (
						<>
							<MDBox mb={2}>
								<MDBox display="flex" alignItems="center">
									<MDTypography variant="h5" gutterBottom>
										<Link
											href={`https://github.com/${repoData.data.repo.full_name}`}
											target="_blank"
											rel="noopener"
										>
											{repoData.data.repo.full_name}
										</Link>
									</MDTypography>
									<GitHubStarInfo info={repoData.data.repo} />
								</MDBox>
								<MDTypography>
									{repoData.data.repo.description}
								</MDTypography>
							</MDBox>
							<ContainerListSummaryCard
								malwareCount={summary?.malware || 0}
								vulDetail={
									summary?.vulnerabilties ||
									({} as DashboardVulData)
								}
								isLoading={!scanSummaryData}
							/>
							<MDBox mt={1}>
								<GithubRepoItemDetail
									rowData={repoData.data.repo}
									githubAccountId={accountId}
									onUpdate={() => {
										mutate();
									}}
								/>
							</MDBox>
						</>
					) : (
						<MDAlert>{t('github_repo_not_exist')}</MDAlert>
					)
				) : (
					<MDBox>
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</MDBox>
				)}
			</MDBox>
		</>
	);
};

export default PublicGitHubRepoDetail;
