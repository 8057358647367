import {makeResource} from 'utils/saga-resource';
import {all, put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface SimilarFile {
	id: string;
	family: string;
	confidence: number;
}

interface ComponentData {
	component: string;
	confidence: number;
}

export interface MalwareState {
	id: string;
	name: string;
	uploading: boolean;
	similarFiles: SimilarFile[];
	components: ComponentData[];
	code: string;
}

export interface MalwareEffects {
	loadResource: (payload: {id: string}) => any;
	load: (payload: {id: string}) => any;
	downloadFile: (payload: {malwareId: string; file: SimilarFile}) => any;
	downloadFileWithId: (payload: {malwareId: string; fileId: string}) => any;
	uploadFile: (payload: {
		malwareDbId: string;
		name: string;
		description: string;
		files: any;
	}) => any;
	removeFunction: (payload: {malwareId: string; fileId: string}) => any;
	loadComponents: (payload: {id: string}) => any;
}

const malware = makeResource<MalwareState, any, MalwareEffects>({
	name: 'malware',
	state: {
		id: '',
		uploading: false,
		similarFiles: [],
		components: [],
		name: '',
		code: '',
	},
	effects: {
		*loadResource({id}): any {
			const data = (yield callApi(`/api/v1/malwares/${id}`)).data;

			yield put(malware.actions.set({...data}));
		},
		*loadComponents({id}): any {
			const components = (yield callApi(
				`/api/v1/malwares/${id}/components`
			)).data.components;
			yield put(malware.actions.set({components}));
		},
		*load({id}): any {
			yield put(malware.actions.startLoading());
			try {
				yield all([
					malware.effects.loadResource({id}),
					malware.effects.loadComponents({id}),
				]);
			} catch (err) {
				throw err;
			} finally {
				yield put(malware.actions.endLoading());
			}
		},
		*uploadFile(payload): any {
			const formData = new FormData();
			formData.append('name', payload.name);
			formData.append('description', payload.description);
			formData.append('file', payload.files[0]);
			try {
				yield put(malware.actions.set({uploading: true}));
				yield callApi(
					`/api/v1/malwaredbs/${payload.malwareDbId}/files`,
					{
						method: 'POST',
						data: formData,
					}
				);
				yield malware.effects.loadResource({id: payload.malwareDbId});
			} catch (err) {
				throw err;
			} finally {
				yield put(malware.actions.set({uploading: false}));
			}
		},
		*removeFunction({malwareId, fileId}): any {
			yield put(malware.actions.startLoading());
			try {
				yield callApi(`/api/v1/malwares/${malwareId}/files/${fileId}`, {
					method: 'DELETE',
				});
				yield malware.effects.loadResource({id: malwareId});
			} catch (err) {
				throw err;
			} finally {
				yield put(malware.actions.endLoading());
			}
		},
		*downloadFile({malwareId, file}): any {
			const data = yield callApi(
				`/api/v1/malwares/${malwareId}/files/${file.id}`,
				{
					method: 'GET',
					responseType: 'blob',
				}
			);
			const url = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = url;
			// TODO add original name to api side
			// link.download = file.originalName;
			link.click();
			window.URL.revokeObjectURL(url);

			return 0;
		},
		*downloadFileWithId({malwareId, fileId}): any {
			const data = yield callApi(
				`/api/v1/malwares/${malwareId}/files/${fileId}`,
				{
					method: 'GET',
					responseType: 'blob',
				}
			);
			const url = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = url;
			// link.download = file.originalName;
			link.click();
			window.URL.revokeObjectURL(url);

			return 0;
		},
	},
});

export default malware;
