import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface VulData {
	// vulId: string;
	// name: string;
	// description: string;
	// type: string;
	// functionName: string;
	// functions: {
	// 	offset: number;
	// 	function: string;
	// 	score: number;
	// }[];

	vulId: string;
	vul_fun_id: string;
	fileId: string;
	fileName: string;
	no_of_checked_funcs: number;
	no_of_checked_vuls: number;
	softwareId: string;
	vul_candidates: any[];
	vulnerability: {
		name: string;
		type: string;
	};
}

interface ReportState {
	id: string;
	name: string;
	description: string;
	status: string;
	userId: string;
	fileId: string;
	fileName: string;
	vul_scan_results: VulData[];
	createdAt?: string;
	updatedAt?: string;
	scanned_files?: string[];
	software_to_search?: string[];
}

export interface ReportEffects {
	loadResource: (payload: {projectId: string; reportId: string}) => any;
	load: (payload: {projectId: string; reportId: string}) => any;
	removeCandidate: (payload: {
		projectId: string;
		reportId: string;
		vulFunId: string;
		candidateName: string;
	}) => any;
}

const report = makeResource<ReportState, any, ReportEffects>({
	name: 'report',
	state: {
		id: '',
		name: '',
		description: '',
		status: '',
		userId: '',
		fileId: '',
		fileName: '',
		vul_scan_results: [],
		scanned_files: [],
		software_to_search: [],
	},
	effects: {
		*loadResource({projectId, reportId}): any {
			const data = (yield callApi(
				`/api/v1/projects/${projectId}/scans/${reportId}`
			)).data;
			yield put(report.actions.set(data));
		},
		*load({projectId, reportId}): any {
			yield put(report.actions.startLoading());
			yield report.effects.loadResource({projectId, reportId});
			yield put(report.actions.endLoading());
		},
		*removeCandidate({projectId, reportId}): any {
			// const data = (yield callApi(
			// 	`/api/v1/projects/${projectId}/scans/${reportId}/vul_fun_id/${vulFunId}/candidates/${candidateName}`,
			// 	{method: 'DELETE'}
			// )).data;
			yield report.effects.load({projectId, reportId});
		},
	},
});

export default report;
