import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface MalwareIndex {
	_id: string;
	name: string;
	description: string;
	shared: boolean;
	malwares: string[];
}

export interface MalwareIndexState {
	data: MalwareIndex[];
}

export interface MalwareIndexEffects {
	load: () => any;
	loadResource: () => any;
	remove: (payload: {id: string}) => any;
	create: (payload: {name: string; description: string}) => any;
	share: (payload: {id: string; recipientEmail: string}) => any;
}

const malwareIndex = makeResource<MalwareIndexState, any, MalwareIndexEffects>({
	name: 'malware_index',
	state: {
		data: [],
	},
	effects: {
		*loadResource(): any {
			const data = (yield callApi('/api/v1/malwaredbs')).data;
			yield put(malwareIndex.actions.set({data}));
		},
		*load(): any {
			yield put(malwareIndex.actions.startLoading());
			yield malwareIndex.effects.loadResource();
			yield put(malwareIndex.actions.endLoading());
		},
		*remove({id}): any {
			yield put(malwareIndex.actions.startLoading());
			yield callApi(`/api/v1/malwaredbs/${id}`, {method: 'DELETE'});
			yield malwareIndex.effects.loadResource();
			yield put(malwareIndex.actions.endLoading());
		},
		*create({name, description}): any {
			yield put(malwareIndex.actions.startLoading());
			yield callApi(`/api/v1/malwaredbs`, {
				method: 'POST',
				data: {name, description},
			});
			yield malwareIndex.effects.loadResource();
			yield put(malwareIndex.actions.endLoading());
		},
		*share({id, recipientEmail}): any {
			yield put(malwareIndex.actions.startLoading());
			try {
				yield callApi(`/api/v1/share`, {
					method: 'POST',
					data: {
						entityType: 'MalwareDB',
						entityId: id,
						userEmails: [recipientEmail],
					},
				});
				alert('Product shared');
			} catch (e) {
				throw e;
			} finally {
				yield put(malwareIndex.actions.endLoading());
			}
		},
	},
});

export default malwareIndex;
