import {Paper} from '@mui/material';
import React, {ReactElement} from 'react';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import MDTypography from 'shared/MDTypography';

interface Props {
	icon: ReactElement;
	title?: string | ReactElement;
	description: string | ReactElement;
	summary: string | ReactElement;
	onSummaryClick?: () => void;
	color?: string;
	isActive?: boolean;
	alternativeBg?: boolean;
	disabled?: boolean;
}

const SummaryCard: React.FC<Props> = ({
	icon,
	title,
	description,
	summary,
	onSummaryClick,
	color,
	isActive,
	alternativeBg = false,
	disabled = false,
}) => {
	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				p: 2,
				height: '100%',
				boxShadow: (theme) =>
					isActive ? `0 0 0 2px ${theme.palette.info.main}` : 'none',
				...(alternativeBg && {backgroundColor: '#f6f6f6'}),
				...(disabled && {filter: 'grayscale(1) opacity(0.5)'}),
			}}
		>
			<MDBox display="flex" alignItems={title ? 'center' : 'flex-start'}>
				<MDBox mr={2}>{icon}</MDBox>
				<MDBox ml={!title ? 'auto' : undefined} width="100%">
					{title && (
						<MDTypography variant="h5" fontSize="17px">
							{title}
						</MDTypography>
					)}
					<MDTypography fontSize={title ? '12px' : '16px'}>
						{description}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox
				flexGrow={1}
				mt={1}
				pt={2}
				sx={(theme) => ({
					borderColor: theme.borders.borderColor,
					borderWidth: 0,
					borderTopWidth: theme.borders.borderWidth[1],
					borderStyle: 'solid',
				})}
			>
				{typeof summary === 'string' ? (
					onSummaryClick ? (
						<MDButton
							color="error"
							onClick={onSummaryClick}
							disabled={disabled}
						>
							{summary}
						</MDButton>
					) : (
						<MDBox
							component="span"
							sx={(theme) => ({
								padding: '4px 8px',
								display: 'inline-block',
								backgroundColor: color
									? theme.palette[color]?.main
									: theme.palette.error.main,
								color: theme.palette.white.main,
								borderRadius: theme.borders.borderRadius.sm,
							})}
						>
							{summary}
						</MDBox>
					)
				) : (
					summary
				)}
			</MDBox>
		</Paper>
	);
};

export default SummaryCard;
