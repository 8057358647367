import {Tooltip} from '@mui/material';
import {isProduction} from 'constant';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MDBadge from 'shared/MDBadge';

interface Props {
	scanStartAt?: string;
	scanEndAt?: string;
	error?: string;
	noImage?: boolean;
}

export enum ScanStatusEnum {
	NotScanned,
	Scanning,
	Failed,
	Complete,
}

export const getScanStatus = (targetContainer: Props) => {
	if (!targetContainer) {
		return null;
	}

	if (!targetContainer.scanStartAt) {
		return {
			type: 'warning',
			msg: 'Not scanned',
			status: ScanStatusEnum.NotScanned,
		};
	}

	if (targetContainer.scanEndAt) {
		if (
			moment(targetContainer.scanStartAt).isBefore(
				moment(targetContainer.scanEndAt)
			)
		) {
			return {
				type: 'success',
				msg: 'Scan completed',
				status: ScanStatusEnum.Complete,
			};
		}
	}

	const startComapreToNow = moment(targetContainer.scanStartAt).diff(
		new Date(),
		'hours'
	);

	if (startComapreToNow < -24) {
		return {
			type: 'error',
			msg: 'Timeout',
			status: ScanStatusEnum.Failed,
		};
	}

	return {
		type: 'success',
		msg: 'Scanning',
		status: ScanStatusEnum.Scanning,
	};
};

const ScanStatus: React.FC<Props> = (props) => {
	const scanStatus = getScanStatus(props);
	const {t} = useTranslation();
	if (props.noImage) {
		return (
			<Tooltip
				title={
					t('uploadOnRight') || 'You can upload image on right side'
				}
			>
				<MDBadge
					badgeContent={t('noImage')}
					color="warning"
					variant="contained"
				/>
			</Tooltip>
		);
	}
	if (props.error && !isProduction) {
		return (
			<Tooltip title={props.error}>
				<MDBadge
					badgeContent={t('scanFailed')}
					color="error"
					variant="contained"
				/>
			</Tooltip>
		);
	}
	return (
		<>
			{scanStatus && (
				<MDBadge
					badgeContent={scanStatus.msg}
					color={scanStatus.type}
					variant="contained"
				/>
			)}
		</>
	);
};

export default ScanStatus;
