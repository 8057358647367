import axios from 'axios';
import {GitHubRepoItem} from 'types/githubApi';
import {callApi} from './index';

export const parseGitHubRepoUrl = (url: string) => {
	const regex =
		/https:\/\/github.com\/([^/]+)\/([^/]+)(?:\/([^/]+)\/([^/]+))?/;

	const match = url.match(regex);
	if (match && match[1] && match[2]) {
		const username = match[1];
		const repoName = match[2];

		return {username, repoName};
	}

	return null;
};

export const getGitHubRepoInfo = (
	username: string,
	repoName: string
): Promise<GitHubRepoItem> => {
	const apiUrl = `https://api.github.com/repos/${username}/${repoName}`;
	return axios
		.get(apiUrl)
		.then((res) => res.data)
		.catch((e) => {
			if (axios.isAxiosError(e)) {
				alert(
					`Github API rate limit reached for your IP. Please try again later.`
				);
			}
		});
};

export const getGitHubRepoInfoWithUserToken = (
	githubAccountId: string,
	owner: string,
	repository: string
): Promise<GitHubRepoItem> => {
	return callApi
		.get(`/api/v1/github/${githubAccountId}/${owner}/${repository}`)
		.then((d) => d.data);
};
