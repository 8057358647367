import {Link} from 'react-router-dom';

export const LinkItem: React.FC<{href?: string; name: string}> = (item) => {
	const linkClassName =
		'p-2 px-6 flex items-start rounded-lg text-gray-900 hover:bg-bitblue hover:text-bitBlue whitespace-nowrap';

	if (!item.href) {
		return (
			<div className="p-2 px-6 flex items-start rounded-lg text-gray-900 whitespace-nowrap">
				{item.name}
			</div>
		);
	}
	const isExternal = /http/.test(item.href);

	if (isExternal) {
		return (
			<a key={item.name} href={item.href} className={linkClassName}>
				{item.name}
			</a>
		);
	}

	return (
		<Link to={item.href} key={item.name} className={linkClassName}>
			{item.name}
		</Link>
	);
};

export const NavSubItems: React.FC<{
	items: {name: string; href: string}[];
}> = ({items}) => {
	return (
		<>
			{items.map((item) => {
				return <LinkItem key={item.name} {...item} />;
			})}
		</>
	);
};
