import React, {Props as ReactProps, useEffect} from 'react';
import useMouse from '@react-hook/mouse-position';

import {styled, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
	Box,
	Breadcrumbs,
	Container,
	Drawer,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import Link from '../Link';
import {get} from 'lodash';
import MDBox from 'shared/MDBox';
import {setMiniSidenav, useMaterialUIController} from 'shared/context';

export interface StateProps {}
export interface ActionProps {}
export interface OwnProps {
	fullWidth?: boolean;
	title?: React.ReactElement | string;
	subTitle?: string;
	actions?: {component: React.ReactElement}[];
	loading?: boolean;
	nav?: {title: string; link: string}[];
	shouldShowRightSideContent?: boolean;
	rightSideContent?: React.ReactElement | null;
	onCloseRightSideContent?: () => void;
	defaultDrawerWidth?: number;
	rightSideContentTitle?: React.ReactElement;
}

type Props = StateProps & ActionProps & OwnProps & ReactProps<null>;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			maxWidth: '100%',
			padding: 0,
			paddingBottom: theme.spacing(2),
		},
		content: {
			display: 'flex',
			overflow: 'hidden',
		},
		topNavBarWrapper: {
			position: 'sticky',
			top: 0,
			minHeight: theme.spacing(8),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			borderBottom: `1px solid ${theme.palette.divider}`,
			backgroundColor: theme.palette.background.paper,
			zIndex: theme.zIndex.appBar,

			'&.space-between': {
				justifyContent: 'space-between',
			},
		},
		topNavBar: {
			padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
			flexShrink: 0,
		},
		breadcrumbPast: {
			cursor: 'pointer',
			opacity: 0.7,
			transition: theme.transitions.create(['opacity'], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.standard,
			}),

			'&:hover': {
				opacity: 1,
			},
		},
		top_portion: {
			paddingBottom: theme.spacing(2),
		},
		button_container: {
			paddingLeft: theme.spacing(1),
		},
		dragBarWrapper: {
			cursor: 'ew-resize',
			position: 'absolute',
			top: 0,
			left: '-4px',
			bottom: 0,
			width: '4px',
			height: '100%',
			backgroundColor: theme.palette.divider,
			zIndex: theme.zIndex.drawer + 20,
		},
		draggingArea: {
			display: 'none',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: '100%',
			backgroundColor: 'transparent',
			zIndex: theme.zIndex.drawer + 1,

			'&.show': {
				display: 'block',
			},
		},
	})
);

const LeftContent = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'open' && prop !== 'drawerX' && prop !== 'defaultWidth',
})<{
	open?: boolean;
	drawerX: number | null;
	defaultWidth: number;
}>(({open, defaultWidth, drawerX}) => ({
	position: 'relative',
	flexGrow: 1,
	padding: 0,
	maxWidth: '100%',
	// transition: theme.transitions.create('margin', {
	// 	easing: theme.transitions.easing.sharp,
	// 	duration: theme.transitions.duration.leavingScreen,
	// }),
	marginRight: drawerX ? `calc(${drawerX}px - 100vw)` : `-${defaultWidth}px`,
	...(open && {
		// transition: theme.transitions.create('margin', {
		// 	easing: theme.transitions.easing.easeOut,
		// 	duration: theme.transitions.duration.enteringScreen,
		// }),
		marginRight: 0,
	}),
}));

const Page = (props: Props): JSX.Element => {
	const {defaultDrawerWidth = 500} = props;
	const [, dispatch] = useMaterialUIController();

	const classes = useStyles();

	const draggingAreaRef = React.useRef(null);
	const mousePosition = useMouse(draggingAreaRef, {
		enterDelay: 0,
		leaveDelay: 0,
	});

	const [drawerWidth, setDrawerWidth] = React.useState(
		`${defaultDrawerWidth}px`
	);
	const [isResizingRightColumn, setIsResizingRightColumn] =
		React.useState(false);

	const Content = <MDBox sx={{maxWidth: '100%'}}>{props.children}</MDBox>;
	const Skeletons = (
		<>
			<Skeleton variant="text" />
			<Skeleton variant="text" />
			<Skeleton variant="text" />
		</>
	);

	useEffect(() => {
		setDrawerWidth(`${defaultDrawerWidth}px`);
	}, [props.defaultDrawerWidth]);

	useEffect(() => {
		if (props.shouldShowRightSideContent) {
			setMiniSidenav(dispatch, true);
		}
	}, [props.shouldShowRightSideContent]);

	return (
		<Container
			className={classNames({[classes.container]: !props.fullWidth})}
		>
			<div
				className={classNames(classes.draggingArea, {
					show: isResizingRightColumn,
				})}
				ref={draggingAreaRef}
				onMouseMove={() => {
					if (isResizingRightColumn && mousePosition.clientX) {
						const minimalDrawerWidth = 200;
						const maxMouseClientX = Math.round(
							(window.innerWidth + 240) * 0.4
						);

						let clientX = mousePosition.clientX;

						if (clientX < maxMouseClientX) {
							clientX = maxMouseClientX;
						}

						if (window.innerWidth - clientX < minimalDrawerWidth) {
							clientX = window.innerWidth - minimalDrawerWidth;
						}

						setDrawerWidth(
							mousePosition.clientX
								? `calc(100vw - ${clientX}px)`
								: `300px`
						);
					}
				}}
				onMouseUp={() => {
					setIsResizingRightColumn(false);
				}}
			></div>
			<LeftContent
				open={props.shouldShowRightSideContent}
				drawerX={mousePosition.clientX}
				defaultWidth={defaultDrawerWidth}
			>
				{props.nav && (
					<div className={classes.topNavBarWrapper}>
						<Breadcrumbs
							aria-label="breadcrumb"
							separator={<NavigateNextIcon fontSize="small" />}
							className={classes.topNavBar}
						>
							{props.nav.map((o, i) => {
								if (i == get(props, 'nav.length') - 1) {
									return (
										<Typography key={o.title}>
											{o.title}
										</Typography>
									);
								}
								return (
									<Link
										key={o.title}
										to={o.link}
										className={classes.breadcrumbPast}
									>
										{o.title}
									</Link>
								);
							})}
						</Breadcrumbs>
					</div>
				)}
				<Box
					display="flex"
					justifyContent="space-between"
					className={classes.top_portion}
				>
					{props.title && (
						<Box display="flex" flexDirection="column" flexGrow="1">
							<Typography variant="h4">{props.title}</Typography>
							{props.subTitle && (
								<Typography variant="subtitle1">
									{props.subTitle}
								</Typography>
							)}
						</Box>
					)}
					{props.actions?.map((action, i) => {
						return (
							<div
								className={classes.button_container}
								key={`action-${i}`}
							>
								{action.component}
							</div>
						);
					})}
				</Box>
				{props.loading ? Skeletons : Content}
			</LeftContent>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						borderRadius: 0,
						height: '100%',
						width: drawerWidth,
						borderLeft: 'none',
						paddingLeft: '4px',
						margin: 0,
					},
				}}
				open={props.shouldShowRightSideContent}
				anchor="right"
				variant="persistent"
			>
				<div
					style={{
						position: 'relative',
					}}
				>
					<div
						className={classes.dragBarWrapper}
						onMouseDown={() => setIsResizingRightColumn(true)}
						onMouseUp={() => {
							setIsResizingRightColumn(false);
						}}
					/>
					<div style={{minHeight: '100vh'}}>
						<div
							className={classNames([
								classes.topNavBarWrapper,
								'space-between',
							])}
							style={{maxWidth: drawerWidth}}
						>
							<MDBox px={2} sx={{maxWidth: '80%'}}>
								{props.rightSideContentTitle}
							</MDBox>
							<Stack
								direction="row"
								spacing={1}
								className={classes.topNavBar}
							>
								<IconButton
									onClick={() => {
										props.onCloseRightSideContent &&
											props.onCloseRightSideContent();
										setDrawerWidth(
											`${defaultDrawerWidth}px`
										);
									}}
								>
									<CloseIcon />
								</IconButton>
							</Stack>
						</div>
						{props.shouldShowRightSideContent &&
							props.rightSideContent}
					</div>
				</div>
			</Drawer>
		</Container>
	);
};

Page.defaultProps = {
	fullWidth: false,
};

export default Page;
