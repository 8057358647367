import {Grid} from '@mui/material';
import {mainAppUrl} from 'constant';
import {LinkItem} from './LinkItems';
import PopoverMenu from './PopoverMenu';

const siteLink = 'https://deepbits.com';

const toolsDropDown = [
	{
		name: 'DeepSCA',
		href: '/',
	},
	{
		name: 'DeepPkg',
		href: '/pkg',
	},
	{
		name: 'DeepMalware',
		href: '/malware',
	},
	{
		name: 'DeepDi',
		href: `${siteLink}/deepdi`,
	},
];

const companyDropdown = [
	{
		name: 'About',
		href: `${siteLink}/about`,
	},
	{
		name: 'Blog',
		href: `${siteLink}/blog`,
	},
	{
		name: 'News & Events',
		href: `${siteLink}/news`,
	},
	{
		name: 'Contact Us',
		href: `${siteLink}/about#contact-us`,
	},
];

const solutionDropdown = [
	{
		name: 'SBOM',
		href: `${siteLink}/sbom`,
	},
	{
		name: 'Digital Health',
		href: `${siteLink}/digital-health`,
	},
	{
		name: 'Verify Third-Party Software',
		href: `${siteLink}/verify-third-party-software`,
	},
];

export default function DeepbitsSiteHeader() {
	return (
		<div className="_deepbits" style={{width: '100%'}}>
			<Grid
				container
				justifyContent="end"
				style={{backgroundColor: 'white'}}
				sx={{py: 1.4}}
			>
				<Grid item xs={8}>
					<div className="flex items-center justify-end px-6 space-x-10 relative z-50">
						<div className="flex items-center">
							<nav className="md:flex space-x-10">
								<a
									href="https://deepbits.com"
									className="text-black font-medium hover:text-bitBlue"
								>
									Home
								</a>
								<a
									href="https://deepbits.com/platform"
									className="text-black font-medium hover:text-bitBlue"
								>
									Platform
								</a>

								<PopoverMenu
									title={() => <span>Solutions</span>}
									render={() => (
										<div className="relative grid px-2 py-4">
											{solutionDropdown.map((item) => (
												<LinkItem
													{...item}
													key={item.name}
												/>
											))}
										</div>
									)}
								/>

								<PopoverMenu
									title={() => <span>Tools</span>}
									render={() => (
										<div className="relative grid px-2 py-4">
											{toolsDropDown.map((item) => (
												<LinkItem
													{...item}
													key={item.name}
												/>
											))}
										</div>
									)}
								/>

								<a
									href="https://docs.deepbits.com"
									className="text-black font-medium hover:text-bitBlue"
								>
									Docs
								</a>

								<PopoverMenu
									title={() => <span>Company</span>}
									render={() => (
										<div className="relative grid px-2 py-4">
											{companyDropdown.map((item) => (
												<LinkItem
													{...item}
													key={item.name}
												/>
											))}
										</div>
									)}
								/>
							</nav>

							<div className="md:flex items-center md:flex-1">
								<a
									href={mainAppUrl}
									target="_blank"
									rel="noopener noreferrer"
									className="ml-8 w-48 rounded whitespace-nowrap inline-flex items-center justify-center py-2 border-2 border-transparent shadow-sm text-base font-medium text-white bg-bitBlue hover:bg-transparent hover:text-bitBlue transition hover:border-bitBlue"
								>
									Sign in / Sign up
								</a>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
