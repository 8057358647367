import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

interface SearchResult {
	fileId: string;
	files: string[];
}

interface SearchState {
	id: string;
	name: string;
	description: string;
	status: string;
	malwareIds: [];
	results: SearchResult[];
}

export interface SearchEffects {
	loadResource: (payload: {projectId: string; searchId: string}) => any;
	load: (payload: {projectId: string; searchId: string}) => any;
}

const search = makeResource<SearchState, any, SearchEffects>({
	name: 'search',
	state: {
		id: '',
		name: '',
		description: '',
		status: '',
		malwareIds: [],
		results: [],
	},
	effects: {
		*loadResource({projectId, searchId}): any {
			const data = yield callApi(
				`/projects/${projectId}/searches/${searchId}`
			);
			yield put(search.actions.set(data));
		},
		*load({projectId, searchId}): any {
			yield put(search.actions.startLoading());
			yield search.effects.loadResource({projectId, searchId});
			yield put(search.actions.endLoading());
		},
	},
});

export default search;
