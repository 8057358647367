import {t} from 'i18next';
import {errorMessageKeyMapping} from 'pages/Team/utils/errorMessageMapping';
import {useState} from 'react';
import {METERING_CHECK_MSG_KEY} from 'types/meteringCheckType';

export const useLoadingIndicator = (originFetcher) => {
	const [loading, setLoading] = useState(false);

	const fetcher = async (...args) => {
		setLoading(true);
		try {
			const result = await originFetcher(...args);
			setLoading(false);
			return result;
		} catch (e: any) {
			setLoading(false);

			if (e.isAxiosError) {
				const meteringError =
					e.response?.data?.data?.meteringCheckReason;
				const errorCode = e.response?.data?.data?.errorReason;

				let errorMessageKey =
					METERING_CHECK_MSG_KEY[meteringError] ??
					errorMessageKeyMapping[errorCode];

				if (!errorMessageKey && e.response.status == 403) {
					errorMessageKey = e.response?.data?.data?.isTeamMember
						? 'error403forTeamMember'
						: 'error403';
				}

				alert(t(errorMessageKey ?? 'error'));
			} else {
				alert(t('error'));
			}

			throw e;
		}
	};

	return {
		fetcher,
		loading,
	};
};
