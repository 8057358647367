import {
	Card,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	Skeleton,
} from '@mui/material';
import MDBox from 'shared/MDBox';
import Sidenav from '../components/Sidenav';
import {useTranslation} from 'react-i18next';
import {callApi} from 'utils/network';
import useSWR from 'swr';
import {AxiosResponse} from 'axios';
import {UserRes} from '../Developer/ApiKeyList';
import MDTypography from 'shared/MDTypography';
import MDButton from 'shared/MDButton';
import NotificationStore from 'store/notification';
import {useDispatch} from 'react-redux';
import {useLoadingIndicator} from 'utils/network/useLoadingIndicator';
import {useEffect, useState} from 'react';
import PageTopBar from 'shared/Navbars/PageTopBar/PageTopBar';
import {setJiraSetup, useMaterialUIController} from 'shared/context';

const JiraIntegration: React.FC<{
	insideDialog?: boolean;
}> = ({insideDialog}) => {
	const {t} = useTranslation();
	const {data: userRes, mutate} = useSWR<AxiosResponse<UserRes>>(
		`/api/v1/user`,
		callApi.get,
		{
			refreshInterval: 1000,
		}
	);
	const [, muiDispatch] = useMaterialUIController();

	useEffect(() => {
		if (userRes?.data?.jira?.base_url) {
			setJiraSetup(muiDispatch, false);
		}
	}, [userRes]);

	const dispatch = useDispatch();
	const unlinkJiraFn = async () => {
		await callApi.delete(`/api/v1/auth/jira/integration`);
		dispatch(
			NotificationStore.actions.show({
				msg: t('jiraAccountDeleted'),
				type: 'success',
			})
		);
		mutate();
	};

	const {fetcher: unlinkJira, loading: isUnlinking} =
		useLoadingIndicator(unlinkJiraFn);

	const onLinkJiraFn = async () => {
		const authorizeRes = await callApi.get(
			`/api/v1/auth/jira/authorize?returnUrl=${window.location.origin}/settings/jira/succcess`
		);
		const {authorizeUrl} = authorizeRes.data;

		const windowName = 'OAuthWindow';
		const windowFeatures = 'height=600,width=900,scrollbars=yes';

		const oauthWindow = window.open(
			authorizeUrl,
			windowName,
			windowFeatures
		);

		if (oauthWindow?.closed) {
			dispatch(
				NotificationStore.actions.show({
					msg: t('jiraAccountLinked'),
					type: 'success',
				})
			);
			mutate();
			setJiraSetup(muiDispatch, false);
		}
	};

	const {fetcher: onLinkJira, loading: isLinking} =
		useLoadingIndicator(onLinkJiraFn);

	const [showBindJiraAccountDialog, setShowBindJiraAccountDialog] =
		useState(false);

	useEffect(() => {
		const jira = userRes?.data.jira;
		if (jira && jira.access_token && !jira.base_url) {
			setShowBindJiraAccountDialog(true);
		}
	}, [userRes]);

	const content = userRes && (
		<MDBox>
			<MDTypography variant="h5" gutterBottom>
				{t('jiraIntegrationTitle')}
			</MDTypography>
			{userRes.data?.jira?.access_token ? (
				<MDBox>
					<MDTypography gutterBottom style={{display: 'block'}}>
						{t('linkedJiraAccount')}
					</MDTypography>
					{!!userRes.data.jira?.base_url && (
						<MDButton
							variant="outlined"
							color="info"
							sx={{mr: 1}}
							onClick={() => {
								window.open(userRes.data.jira?.base_url);
							}}
						>
							{t('viewJiraBoard')}
						</MDButton>
					)}
					<MDButton
						disabled={isUnlinking}
						variant="contained"
						color="error"
						onClick={unlinkJira}
						startIcon={
							isUnlinking ? (
								<CircularProgress
									size={18}
									color={'white' as 'primary'}
								/>
							) : (
								<Icon>delete</Icon>
							)
						}
					>
						{t('unlinkJiraIntegration')}
					</MDButton>
				</MDBox>
			) : (
				<MDBox>
					<MDTypography gutterBottom style={{display: 'block'}}>
						{t('noJiraIntegration')}
					</MDTypography>
					<MDButton
						disabled={isLinking}
						variant="contained"
						color="info"
						onClick={onLinkJira}
					>
						{t('linkJiraIntegration')}
					</MDButton>
				</MDBox>
			)}
		</MDBox>
	);

	const contentWithLoading = (
		<MDBox p={3}>
			{!userRes ? (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			) : (
				content
			)}
		</MDBox>
	);
	return (
		<MDBox>
			{insideDialog ? (
				<>{contentWithLoading}</>
			) : (
				<>
					<PageTopBar />
					<Grid container spacing={3}>
						<Grid item xs={12} lg={3}>
							<Sidenav />
						</Grid>
						<Grid item xs={12} lg={9}>
							<Card id="basic-info" sx={{overflow: 'visible'}}>
								{contentWithLoading}
							</Card>
						</Grid>
					</Grid>
				</>
			)}

			<Dialog
				open={showBindJiraAccountDialog}
				onClose={() => {
					setShowBindJiraAccountDialog(false);
				}}
			>
				{showBindJiraAccountDialog && (
					<SelectSystemDialogContent
						onFinish={() => {
							mutate();
							setShowBindJiraAccountDialog(false);
						}}
					/>
				)}
			</Dialog>
		</MDBox>
	);
};

interface AccessibleResourcesRes {
	data: {
		id: string;
		url: string;
		name: string;
		scopes: string[];
		avatarUrl: string;
	}[];
}

const SelectSystemDialogContent: React.FC<{onFinish: () => void}> = ({
	onFinish,
}) => {
	const {data: accessibleResource} = useSWR<AccessibleResourcesRes>(
		`/api/v1/auth/jira/accessible-resources`,
		callApi.get
	);
	const {t} = useTranslation();

	const dispatch = useDispatch();
	const [confirmingId, setConfirmingId] = useState<null | string>(null);

	const onConfirmFn = async (systemId) => {
		setConfirmingId(systemId);
		const result = await callApi.post(
			`/api/v1/auth/jira/accessible-resources/${systemId}`
		);

		if (result) {
			dispatch(
				NotificationStore.actions.show({
					msg: t('updateJiraSystemDialogSuccess'),
					type: 'success',
				})
			);
		}

		onFinish();
	};

	const {fetcher: onConfirm, loading: isConfirming} =
		useLoadingIndicator(onConfirmFn);

	return (
		<MDBox sx={{minWidth: '500px'}}>
			<DialogTitle id="create-jira-ticket-title">
				{t('updateJiraSystemDialogTitle')}
			</DialogTitle>

			{accessibleResource ? (
				<DialogContent>
					{accessibleResource.data.map((system) => (
						<MDButton
							variant="outlined"
							color="info"
							disabled={isConfirming}
							onClick={() => onConfirm(system.id)}
							key={system.id}
							sx={{m: 2}}
							startIcon={
								isConfirming &&
								confirmingId === system.id && (
									<CircularProgress size={22} />
								)
							}
						>
							{system.name}
						</MDButton>
					))}
				</DialogContent>
			) : (
				<MDBox p={2}>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</MDBox>
			)}
		</MDBox>
	);
};

export default JiraIntegration;
