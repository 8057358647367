import {makeResource} from 'utils/saga-resource';

const alert = makeResource({
	name: 'alert',
	state: {
		msg: '',
	},
	reducers: {
		openAlert(msg: string, {state}): any {
			return {...state, ...{msg}};
		},
		closeAlert(_: any, {state}): any {
			return {...state, ...{msg: ''}};
		},
	},
});

export default alert;
