import React, {createContext} from 'react';

export enum SiteEntryPoint {
	InApp = 'inApp',
	PublicTool = 'publicTool',
}

export const SiteEntryPointContext = createContext<SiteEntryPoint>(
	SiteEntryPoint.PublicTool
);

const SiteEntryPointProvider: React.FC<{value: SiteEntryPoint}> = ({
	children,
	value,
}) => {
	return (
		<SiteEntryPointContext.Provider value={value}>
			{children}
		</SiteEntryPointContext.Provider>
	);
};

export default SiteEntryPointProvider;
