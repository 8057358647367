import {TeamManagementError} from 'pages/Team/teamTypeDef';

export const errorMessageKeyMapping = {
	[TeamManagementError.UPGRADE_PLAN]: 'teamErrorUpgradePlan',
	[TeamManagementError.USER_NOT_FOUND]: 'teamErrorUserNotFound',
	[TeamManagementError.MEMBER_NOT_FOUND]: 'teamErrorUserNotFound',
	[TeamManagementError.ALREADY_IN_TEAM]: 'teamErrorAlreadyInTeam',
	[TeamManagementError.ALREADY_INVITED]: 'teamErrorAlreadyInvited',
	[TeamManagementError.AWS_USER_NOT_ALLOWED_TO_BE_INVITED]:
		'teamErrorAwsUserNotAllowedToBeInvited',
	[TeamManagementError.AWS_USER_NOT_ALLOWED_TO_JOIN]:
		'teamErrorAwsUserNotAllowedToJoin',
};
