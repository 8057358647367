import {Grid} from '@mui/material';
import MalwareIcon from 'components/Icons/MalwareIcon';
import VulnerabilityIcon from 'components/Icons/VulnerabilityIcon';
import SummaryCard from 'components/SummaryCard/SummaryCard';
import {t} from 'i18next';
import {renderCveColumn} from 'pages/DrCloud/common/renderCveColumn';
import {renderMalwareColumnInListPage} from 'pages/DrCloud/common/renderMalwareColumn';
import MDBox from 'shared/MDBox';
import {DashboardVulData} from 'types/dashboardDataType';

interface Props {
	malwareCount: number;
	vulDetail: DashboardVulData;
	isLoading: boolean;
	descriptionText?: string;
	onMalwareClick?: () => void;
	onVulClick?: () => void;
}

const ContainerListSummaryCard = (props: Props) => {
	const {
		malwareCount,
		vulDetail,
		isLoading,
		onMalwareClick,
		onVulClick,
		descriptionText,
	} = props;

	const malwareBadge = (
		<MDBox
			onClick={onMalwareClick}
			sx={{
				'&:hover': {
					cursor: onMalwareClick ? 'pointer' : 'unset',
				},
			}}
		>
			{renderMalwareColumnInListPage(malwareCount, true)}
		</MDBox>
	) || <></>;

	const vulBadge = (
		<MDBox
			onClick={onVulClick}
			sx={{
				'&:hover': {
					cursor: onVulClick ? 'pointer' : 'unset',
				},
			}}
		>
			{renderCveColumn(vulDetail, true)}
		</MDBox>
	) || <></>;

	return (
		<Grid container spacing={2}>
			<Grid item sm={3}>
				<SummaryCard
					title={t('malware')}
					description={descriptionText ?? t('homeFoundAcrossImages')}
					icon={<MalwareIcon />}
					color="error"
					summary={isLoading ? <></> : malwareBadge}
				/>
			</Grid>
			<Grid item sm={9}>
				<SummaryCard
					title={t('vulnerability')}
					description={descriptionText ?? t('homeFoundAcrossImages')}
					icon={<VulnerabilityIcon />}
					summary={isLoading ? <></> : vulBadge}
				/>
			</Grid>
		</Grid>
	);
};

export default ContainerListSummaryCard;
