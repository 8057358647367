import {DashboardVulData} from 'types/dashboardDataType';
import {CheckListData} from './checklist';

export interface ContainerListItem {
	_id: string;
	name: string;
	scanStartAt?: string;
	image_size_in_bytes?: number;
	vulSummary: DashboardVulData;
	scan_res: {final?: ContainerSummaryInfo};
}

export interface ContainerStaticResult {
	filePath: string;
	details: SBOMInfo[];
	langType: string;
	cve?: (CveItem | undefined)[];
}

// export interface ContainerDynamicResult {
// 	pid: number;
// 	syscall: string[];
// 	cmdline: string;
// 	environ: string[];
// 	modules: string[];
// 	network: string[];
// }

// export interface ContainerLayerChangeSet {
// 	name: string;
// 	action: string;
// 	type: string;
// 	link?: string;
// }

// export interface ContainerLayerInfo {
// 	cmd: string;
// 	checksum: string;
// 	id: string;
// 	changeset: ContainerLayerChangeSet[];
// }

export enum CveScoreSeverity {
	Critical = 9,
	High = 7,
	Medium = 4,
	Low = 0,
}

export interface ScoreSummary {
	vulnerabilities: number;
	malware: number;
	activities: {
		network: number;
		file: number;
		sum: number;
	};
	misconfig: number;
	sum: number;
}

export interface ContainerSummaryInfo {
	bom?: string;
	vulnerabilities: {
		path: string;
		tag: string;
		cves: {
			name: string;
			score: number;
			description: string;
		}[];
	}[];
	malware: {
		path: string;
		family: string;
		score?: number;
		isCalculate: boolean;
	}[];
	// activities: {
	// 	network: {
	// 		pid: number;
	// 		cmd: string;
	// 		ip: string;
	// 		description: string;
	// 	}[];
	// 	file: {pid: number; cmd: string; path: string; description: string}[];
	// };
	// misconfig: {
	// 	description: string;
	// 	results: {
	// 		status: string;
	// 		description: string;
	// 		audit: string;
	// 		actual_output: string;
	// 		expected_output: string;
	// 		reason: string;
	// 		recommend: string;
	// 	}[];
	// }[];
	saasbom?: SaaSBOMResponse | null;
	checklist?: CheckListData;
	score: ScoreSummary;
}

export interface ContainerResult {
	staticRes: ContainerStaticResult[];
	// dynamic: ContainerDynamicResult[];
	// layer: ContainerLayerInfo[];
	final?: ContainerSummaryInfo;
	error?: string;
}

export interface FirmwareListItem {
	_id: string;
	name: string;
}

export interface FirmwareResult {
	filePath: string;
	details: SBOMInfo[];
	cve?: [string, number, string][];
}

export type CveItem = [
	name: string,
	score: number,
	severity: string,
	source?: string // Added by frontend
];

export interface SBOMInfo {
	distribution: string;
	package: string;
	version: string;
	architecture: string;
	components: SBOMComponentInfo[];
	cve?: CveItem[];
	bom?: string;
	language?: string;
	malware?: {family?: string};
	isSystemPackage?: boolean;
	saasbom?: SaaSBOMResponse | null;
}

export interface SBOMComponentInfo {
	type: string;
	name: string;
	registry: string;
	version: string;
	cve?: CveItem[];
	license: string[];
	dynamic?: boolean;
}

export interface SBOMBuilder {
	_id?: string;
	hash?: string;
	blob_name: string;
	file_name: string;
	upload_time: string;
	result?: SBOMInfo[];
	failure: any;
	scanEndAt: string;
}

export interface SaaSBOMProvider {
	name: string;
	url: string[];
}

export interface SaaSBOM {
	provider: SaaSBOMProvider;
	name: string;
	description: string;
	endpoints: string[];
}

export interface SaaSBOMResponse {
	services?: SaaSBOM[];
}
export enum ProjectType {
	AssetGroup = 'AssetGroup',
}
