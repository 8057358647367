import {makeResource} from 'utils/saga-resource';
import {AlertColor} from '@mui/material/Alert';
import {DefaultReducers} from 'utils/saga-resource/types';

const defaultType: AlertColor = 'success';

export interface NotificationState {
	msg: string;
	type: AlertColor;
}
export interface NotificationEffects {
	show: (payload: NotificationState) => any;
	close: () => any;
}

const notification = makeResource<
	NotificationState,
	DefaultReducers<any, any>,
	NotificationEffects
>({
	name: 'notification',
	state: {
		msg: '',
		type: defaultType,
	},
	reducers: {
		show(payload: NotificationState, {state}): any {
			return {...state, ...payload};
		},
		close(_: any, {state}): any {
			return {...state, ...{msg: ''}};
		},
	},
});

export default notification;
