const VulnerabilityIcon = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M35.7992 36H4.20078C3.07445 36 2.16217 35.105 2.16217 34V7C2.16217 5.895 3.07445 5 4.20078 5H35.7992C36.9255 5 37.8378 5.895 37.8378 7V34C37.8378 35.105 36.9255 36 35.7992 36Z"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M2.16217 11.7351H37.8378"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M25.8661 36H7.51863V28.095C7.51863 26.99 8.43091 26.095 9.55724 26.095H23.8275C24.9538 26.095 25.8661 26.99 25.8661 28.095V36Z"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M16.6923 32.1851V35.1301"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M21.7889 25.465V20.54C21.7889 17.78 19.5057 15.54 16.6924 15.54C13.8791 15.54 11.5959 17.78 11.5959 20.54V22.225"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M31.6048 17.8948V26.8148"
				stroke="#242628"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M31.6048 32.125C32.4492 32.125 33.1337 31.4534 33.1337 30.625C33.1337 29.7966 32.4492 29.125 31.6048 29.125C30.7604 29.125 30.0758 29.7966 30.0758 30.625C30.0758 31.4534 30.7604 32.125 31.6048 32.125Z"
				fill="#242628"
			/>
			<path
				d="M6.12728 9.37012C6.69022 9.37012 7.14658 8.9224 7.14658 8.37012C7.14658 7.81783 6.69022 7.37012 6.12728 7.37012C5.56433 7.37012 5.10797 7.81783 5.10797 8.37012C5.10797 8.9224 5.56433 9.37012 6.12728 9.37012Z"
				fill="#242628"
			/>
			<path
				d="M10.2656 9.37012C10.8286 9.37012 11.285 8.9224 11.285 8.37012C11.285 7.81783 10.8286 7.37012 10.2656 7.37012C9.7027 7.37012 9.24634 7.81783 9.24634 8.37012C9.24634 8.9224 9.7027 9.37012 10.2656 9.37012Z"
				fill="#242628"
			/>
			<path
				d="M14.3989 9.37012C14.9619 9.37012 15.4182 8.9224 15.4182 8.37012C15.4182 7.81783 14.9619 7.37012 14.3989 7.37012C13.836 7.37012 13.3796 7.81783 13.3796 8.37012C13.3796 8.9224 13.836 9.37012 14.3989 9.37012Z"
				fill="#242628"
			/>
		</svg>
	);
};

export default VulnerabilityIcon;
