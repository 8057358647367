import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

interface Component {
	_id: string;
	componentName: string;
}
interface ComponentDb {
	_id: string;
	componentDbName: string;
	description: string;
	componentList: Component[];
}

export interface ComponentDbState {
	data: ComponentDb;
}

export interface ComponentDbIndexEffects {
	load: (payload: {componentDbId: string}) => any;
	loadResource: (payload: {componentDbId: string}) => any;
}

const componentDb = makeResource<
	ComponentDbState,
	any,
	ComponentDbIndexEffects
>({
	name: 'componentDb',
	state: {
		data: {
			_id: '',
			componentDbName: '',
			description: '',
			componentList: [],
		},
	},
	effects: {
		*loadResource({componentDbId}): any {
			const data = (yield callApi(`/api/v1/component/${componentDbId}`))
				.data;
			yield put(componentDb.actions.set({data}));
		},
		*load({componentDbId}): any {
			yield put(componentDb.actions.startLoading());
			yield componentDb.effects.loadResource({
				componentDbId,
			});
			yield put(componentDb.actions.endLoading());
		},
	},
});

export default componentDb;
