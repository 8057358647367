import React from 'react';

import Icon from '@mui/material/Icon';
import PubGitHubRepoDetail from 'pages/GitHubFreeTool/PublicGitHubRepoDetail';
import deepBomLogo from './sidebarLogos/deepBom.svg';
import deepDiLogo from './sidebarLogos/deepDi.svg';
// import deepDockerLogo from './sidebarLogos/deepDocker.svg';
import deepPackj from './sidebarLogos/deepPackj.svg';
// import deepRepo from './sidebarLogos/deepRepo.svg';
// import homeLogo from './sidebarLogos/home.svg';
import malwareAnalysisLogo from './sidebarLogos/malwereAnalysis.svg';
import memoryDumpLogo from './sidebarLogos/memoryDumpLogo.svg';
import slackLogo from './sidebarLogos/slack.svg';

// const Home = React.lazy(
// 	() => import('pages/DrCloud/FreeToolHome/FreeToolHome')
// );

const DeepSCA = React.lazy(() => import('pages/DeepSCA'));

// const SBOMTool = React.lazy(() => import('pages/SBOMTool'));
const SBOMDetailsWrapper = React.lazy(
	() => import('pages/SBOMTool/SBOMDetailsWrapper')
);

const MalwareTool = React.lazy(() => import('pages/MalwareTool'));
const MalwareDetailsWrapper = React.lazy(
	() => import('pages/MalwareTool/MalwareDetailsWrapper')
);
const MalwareUploadHistory = React.lazy(
	() => import('pages/MalwareTool/MalwareUploadHistory')
);

// const DockerHubFreeTool = React.lazy(
// 	() => import('pages/DockerHubFreeTool/index')
// );
const DockerHubTagScanDetails = React.lazy(
	() => import('pages/DockerHubFreeTool/DockerHubTagScanDetails')
);
const MemoryDumpFreeTool = React.lazy(() => import('pages/MemoryDumpFreeTool'));
const MemoryDumpDetailsWrapper = React.lazy(
	() => import('pages/MemoryDumpFreeTool/MemoryDumpDetailsWrapper')
);

// const GitHubFreeTool = React.lazy(() => import('pages/GitHubFreeTool/index'));
const PubGitHubBranchDetail = React.lazy(
	() => import('pages/GitHubFreeTool/PublicGitHubBranchDetail')
);

const DeepPackj = React.lazy(() => import('pages/DeepPackj/index'));
const DeepPackjSearchList = React.lazy(
	() => import('pages/DeepPackj/DeepPackjSearchList')
);
const DeepPackjDetails = React.lazy(
	() => import('pages/DeepPackj/DeepPackjDetails')
);
const DeepChat = React.lazy(() => import('pages/DeepChat/DeepChatMain'));
const PublicRegistry = React.lazy(() => import('pages/PublicRegistry'));
const PublicRegistryDetail = React.lazy(
	() => import('pages/PublicRegistry/PublicRegistryDetail')
);

const enableMemoryDumpTool = false;

export const useFreeToolRoutes = () => {
	const routes = [
		// {
		// 	type: 'collapse',
		// 	name: 'Home',
		// 	noCollapse: true,
		// 	key: 'home',
		// 	icon: <img width={20} src={homeLogo} />,
		// 	route: '/',
		// 	component: <Home />,
		// },
		{
			type: 'collapse',
			name: 'DeepSCA',
			noCollapse: true,
			key: '',
			icon: <img width={20} src={deepBomLogo} />,
			route: '/',
			component: <DeepSCA />,
		},
		// {
		// 	type: 'collapse',
		// 	name: 'DeepBOM',
		// 	noCollapse: true,
		// 	key: 'sbom',
		// 	icon: <img width={20} src={deepBomLogo} />,
		// 	route: '/sbom',
		// 	component: <SBOMTool />,
		// },
		{
			type: 'collapse',
			name: 'DeepPkg',
			noCollapse: true,
			key: 'pkg',
			icon: <img width={20} src={deepPackj} />,
			route: '/pkg',
			component: <DeepPackj />,
		},
		// {
		// 	type: 'collapse',
		// 	name: 'DeepRepo',
		// 	noCollapse: true,
		// 	key: 'github',
		// 	icon: <img width={20} src={deepRepo} />,
		// 	route: '/github',
		// 	component: <GitHubFreeTool />,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'DeepDocker',
		// 	noCollapse: true,
		// 	key: 'dockerhub',
		// 	icon: <img width={20} src={deepDockerLogo} />,
		// 	route: '/dockerhub',
		// 	component: <DockerHubFreeTool />,
		// },
		{
			type: 'collapse',
			name: 'DeepMalware',
			noCollapse: true,
			key: 'malware',
			icon: <img width={20} src={malwareAnalysisLogo} />,
			route: '/malware',
			component: <MalwareTool />,
		},
		{
			type: 'collapse',
			name: 'DeepDi',
			noCollapse: true,
			key: 'deepDi',
			icon: <img width={20} src={deepDiLogo} />,
			disabled: true,
			href: 'https://www.deepbits.com/deepdi',
		},
		...(enableMemoryDumpTool
			? [
					{
						type: 'collapse',
						name: 'DeepMem',
						noCollapse: true,
						key: 'deepDi',
						icon: <img width={20} src={memoryDumpLogo} />,
						disabled: true,
						route: '/mdump',
						component: <MemoryDumpFreeTool />,
					},
			  ]
			: []),
		{type: 'divider', key: 'divider-2', name: 'divider-2'},
		{
			type: 'collapse',
			name: 'Help & Docs',
			noCollapse: true,
			key: 'help-docs',
			icon: <Icon fontSize="medium">help</Icon>,
			disabled: true,
			href: 'https://docs.deepbits.com',
		},
		{
			type: 'collapse',
			name: 'Join Slack',
			noCollapse: true,
			key: 'join-slack',
			icon: <img width={20} src={slackLogo} />,
			disabled: true,
			href: 'https://join.slack.com/t/deepbits/shared_invite/zt-1bx3xegmf-wW8UBBotNFyu6Ai9CYb~JQ',
		},
		{
			route: '/mdump/details/:id',
			key: 'mdumpDetails',
			component: <MemoryDumpDetailsWrapper />,
		},
		{
			route: '/sbom/details/:id',
			key: 'sbomDetails',
			component: <SBOMDetailsWrapper />,
		},
		{
			route: '/sbom/popular/:id',
			key: 'sbomPopular',
			component: <SBOMDetailsWrapper />,
		},
		{
			route: '/malware/detail/:id',
			key: 'malareDetails',
			component: <MalwareDetailsWrapper />,
		},
		{
			route: '/malware/hash/:sha',
			key: 'malareDetails',
			component: <MalwareDetailsWrapper />,
		},
		{
			route: '/malware/history',
			key: 'malwareHistory',
			component: <MalwareUploadHistory />,
		},
		{
			route: '/dockerhub/:assetId/:streamId',
			key: 'dockerhubDetails',
			component: <DockerHubTagScanDetails />,
		},
		{
			route: '/github/:owner/:repository',
			key: 'githubRepoDetail',
			component: <PubGitHubRepoDetail />,
		},
		{
			route: '/github/:owner/:repository/:branchName',
			key: 'githubBranchDetail',
			component: <PubGitHubBranchDetail />,
		},
		{
			route: '/pkg/search',
			key: 'packjSearch',
			component: <DeepPackjSearchList />,
		},
		{
			route: '/pkg/details',
			key: 'packjDetails',
			component: <DeepPackjDetails />,
		},
		{
			route: 'chat',
			key: 'chat',
			component: <DeepChat />,
			noPx: true,
		},
		{
			route: '/registry/:id/public/:code',
			key: 'publicRegistry',
			component: <PublicRegistry />,
		},
		{
			route: '/registry/:id/public/:code/stream/:streamId/scan-result',
			key: 'publicRegistryDetail',
			component: <PublicRegistryDetail />,
		},
	];

	return routes;
};
