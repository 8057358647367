import {DashboardVulData} from 'types/dashboardDataType';
import {PaginatedDocs} from 'utils/network';

export interface ProjectDetails {
	_id: string;
	name: string;
	description: string;
	vulSummary?: DashboardVulData;
	malware?: {family: string}[];
	assets?: ProjectAssetPayload[];
}

export type ProjectAssetsRes = PaginatedDocs<ProjectAssetPayload[]>;

export interface ProjectAssetPayload {
	assetType: ProjectAssetType;
	assetIds: string[];
	assetIdsWithDetails?: {[key: string]: any; assetId: string}[];
}

export enum ProjectAssetType {
	AwsEcr = 'AwsEcr',
	GitHubRepo = 'GitHubRepo',
	DockerHub = 'DockerHub',
	Container = 'Container',
	SBOMBuilder = 'SBOMBuilder',
	MemoryDump = 'MemoryDump',
}

export interface ProjectAssetDef {
	_id: string;
	assetType: ProjectAssetType;
	ecrRepoId?: string;
	gitHubRepoId?: string;
	dockerHubRepoId?: string;
	containerId?: string;
	sbomBuilderId?: string;
	projectId: string;
	selected: boolean;
	assetDeleted: boolean;
}
