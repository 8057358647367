import React from 'react';
import MDBox from 'shared/MDBox';
import PageTopBarMenus from './PageTopBarMenus';

export interface OwnProps {
	children?: React.ReactNode;
	enableTopMenu?: boolean;
}

const PageTopBar = (props: OwnProps) => {
	const {children, enableTopMenu = true} = props;
	return (
		<MDBox display="flex" justifyContent="space-between" mb={1}>
			<MDBox>{children}</MDBox>
			{enableTopMenu && <PageTopBarMenus />}
		</MDBox>
	);
};

export default PageTopBar;
