import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface VulnerabilityIndex {
	_id: string;
	id: string; // remove
	name: string;
	description: string;
	shared: boolean;
	softwares?: string[];
}

export interface VulnerabilityIndexState {
	data: VulnerabilityIndex[];
}

export interface VulnerabilityIndexEffects {
	load: () => any;
	loadResource: () => any;
	remove: (payload: {id: string}) => any;
	create: (payload: {name: string; description: string}) => any;
	share: (payload: {id: string; recipientEmail: string}) => any;
}

const VulDBs = makeResource<
	VulnerabilityIndexState,
	any,
	VulnerabilityIndexEffects
>({
	name: 'vulnerability_index',
	state: {
		data: [],
	},
	effects: {
		*loadResource(): any {
			const data = (yield callApi('/api/v1/vuldbs')).data;
			yield put(VulDBs.actions.set({data}));
		},
		*load(): any {
			yield put(VulDBs.actions.startLoading());
			yield VulDBs.effects.loadResource();
			yield put(VulDBs.actions.endLoading());
		},
		*remove({id}): any {
			yield put(VulDBs.actions.startLoading());
			try {
				yield callApi(`/api/v1/vuldbs/${id}`, {method: 'DELETE'});
			} catch (e) {
				throw e;
			} finally {
				yield VulDBs.effects.loadResource();
				yield put(VulDBs.actions.endLoading());
			}
		},
		*create({name, description}): any {
			yield put(VulDBs.actions.startLoading());
			yield callApi(`/api/v1/vuldbs`, {
				method: 'POST',
				data: {name, description},
			});
			yield VulDBs.effects.loadResource();
			yield put(VulDBs.actions.endLoading());
		},
		*share({id, recipientEmail}): any {
			yield put(VulDBs.actions.startLoading());
			try {
				yield callApi(`/api/v1/share`, {
					method: 'POST',
					data: {
						entityType: 'VulDB',
						entityId: id,
						userEmails: [recipientEmail],
					},
				});
				alert('Product shared');
			} catch (e) {
				throw e;
			} finally {
				yield put(VulDBs.actions.endLoading());
			}
		},
	},
});

export default VulDBs;
