/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles

import pxToRem from 'assets/theme/functions/pxToRem';

// types
type Types = any;

const divider: Types = {
	styleOverrides: {
		root: {
			backgroundColor: 'rgba(140, 146, 163, 0.2)',
			height: pxToRem(1),
			margin: `${pxToRem(16)} ${pxToRem(24)}`,
			borderBottom: 'none',
		},

		vertical: {
			backgroundColor: 'rgba(140, 146, 163, 0.2)',
			width: pxToRem(1),
			height: '100%',
			margin: `0 ${pxToRem(16)}`,
			borderRight: 'none',
		},

		light: {
			backgroundColor: 'rgba(140, 146, 163, 0.2)',
		},
	},
};

export default divider;
