import {useEffect, VFC} from 'react';
import {useLocation} from 'react-router-dom';
import {pageview} from './ga';

export const SetGoogleAnalytics: VFC = () => {
	const location = useLocation();

	useEffect(() => {
		pageview(location);
	}, [location]);

	return null;
};
