import {makeResource} from 'utils/saga-resource';
import {callApi} from 'utils/network';
import {put} from 'redux-saga/effects';

export interface FunctionDef {
	vulFunc: FunctionDetails;
	patchedFunc: FunctionDetails;
}

export interface FunctionDetails {
	_id: string;
	name: string;
	arch: string;
	disassemble_data: any;
	decompiled_code: string;
	source_code: string;
	function_signature: any;
	comments: string;
	embedding: string;
	label: string;
	sha256: any;
	binaryFile_id?: string;
	correlation_key?: string;
	fileDetail?: {fileName: string};
}

export interface FunctionsState {
	[key: string]: FunctionDef;
}

export interface FunctionsEffects {
	loadVulFunction: (payload: {vulId: string; funcId: string}) => any;
}

const functions = makeResource<FunctionsState, any, FunctionsEffects>({
	name: 'function',
	state: {},
	effects: {
		*loadVulFunction({vulId, funcId}): any {
			const func = (yield callApi(
				`/api/v1/vuldbs/${vulId}/functions/${funcId}`
			)).data;
			yield put(
				functions.actions.set({
					[funcId]: {
						vulFunc: func.vulFunc,
						patchedFunc: func.patchedFunc,
					},
				})
			);
		},
	},
});

export default functions;
