import _ from 'lodash';
import {makeResource} from 'utils/saga-resource';
import {put, take} from 'redux-saga/effects';
import {FirmwareResult} from 'types/drCloudOnPremisesType';
import {callApi} from 'utils/network';
import axios from 'axios';
import {channel} from 'redux-saga';

export interface FirmwareResultState {
	result: FirmwareResult[];
	name: string;
	blobName: string;
	uploadProgressPercentage: number;
	isUploading: boolean;
}

export interface FirmwareResultEffects {
	load: (payload: {firmwareId?: string}) => any;
	loadResult: (payload: {firmwareId: string}) => any;
	watchUploadFirmwareChannel: () => any;
	uploadFirmwareFile: (payload: {
		firmwareId: string;
		data: {file: any};
	}) => any;
	resetUploadProgress: () => any;
}

const uploadFirmwareFileChannel = channel();

const convertData = (data): FirmwareResult[] =>
	data?.map((x) => {
		const [filePath, info] = x;

		const infoWithCve =
			info?.map((item) => ({
				...item,
				cve: _.flatMap(item.components, (c) => c.cve || []),
			})) || [];

		const cve = _.flatMap(infoWithCve, (item) => item.cve || []);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: next-line
		return {filePath, details: [...infoWithCve], cve} as FirmwareResult;
	});

const firmwareResult = makeResource<
	FirmwareResultState,
	any,
	FirmwareResultEffects
>({
	name: 'firmwareResult',
	state: {
		result: [],
		name: '',
		blobName: '',
		uploadProgressPercentage: 0,
		isUploading: false,
	},
	effects: {
		*loadResult({firmwareId}): any {
			const data = (yield callApi(`/api/v1/firmwares/${firmwareId}`))
				.data;

			const result = convertData(data.scan_result[0] ?? []) || [];
			yield put(
				firmwareResult.actions.set({
					result,
					name: data.name,
					blobName: data.blob_name,
				})
			);
		},
		*load({firmwareId}): any {
			if (firmwareId) {
				yield put(firmwareResult.actions.startLoading());
				yield firmwareResult.effects.loadResult({
					firmwareId,
				});
				yield put(firmwareResult.actions.endLoading());
			}
		},
		*watchUploadFirmwareChannel(): any {
			while (true) {
				const action = yield take(uploadFirmwareFileChannel);
				yield put(action);
			}
		},
		*uploadFirmwareFile({firmwareId, data}): any {
			yield put(firmwareResult.actions.set({isUploading: true}));
			const formData = new FormData();

			formData.append('file', data.file[0]);

			const urlInfo = (yield callApi(
				`api/v1/firmwares/${firmwareId}/get_upload_url`
			)).data;

			const options = {
				headers: {
					'Content-Type': 'application/x-tar',
				},
				onUploadProgress: function (progressEvent): void {
					const uploadProgressPercentage = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					uploadFirmwareFileChannel.put(
						firmwareResult.actions.set({uploadProgressPercentage})
					);
				},
			};

			const uploadUrl = urlInfo.uploadUrl;

			yield axios.put(uploadUrl, data.file[0], options);

			yield callApi(`/api/v1/firmwares/${firmwareId}/upload_success`, {
				method: 'PUT',
				data: {path: urlInfo.path},
			});
			yield put(firmwareResult.actions.set({isUploading: false}));
		},
		*resetUploadProgress(): any {
			yield put(
				firmwareResult.actions.set({uploadProgressPercentage: 0})
			);
		},
	},
});

export default firmwareResult;
