import React, {Props as ReactProps} from 'react';
import {
	Box,
	CircularProgress,
	Dialog as MuiDialog,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
	DialogContentProps,
	DialogProps,
	DialogTitle as MuiDialogTitle,
	IconButton,
	Slide,
	Typography,
	useMediaQuery,
} from '@mui/material';

import MDButton from 'shared/MDButton';

import {Close as CloseIcon} from '@mui/icons-material';

import {Theme, useTheme} from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import {TransitionProps} from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	})
);

const DialogTitle = (props: any): React.ReactElement => {
	const {children, onClose, ...other} = props;
	const classes = useStyles();
	return (
		<MuiDialogTitle className={classes.root} {...other}>
			<Typography variant="h6" component="span">
				{children}
			</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
					size="large"
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export interface StateProps {}
export interface ActionProps {}
export interface OwnProps {
	open: boolean;
	onClose: (e: Event, reason: string) => void;
	loading?: boolean;
	title: string;
	primaryAction?: {
		disabled?: boolean;
		content: string;
		destructive?: boolean;
		onAction: () => void;
		loading?: boolean;
	};
	secondaryAction?: {
		content: string;
		destructive?: boolean;
		onAction: () => void;
	};
	width?: 'lg' | 'md' | 'sm';
	contentProps?: DialogContentProps;
}

type Props = StateProps &
	ActionProps &
	OwnProps &
	ReactProps<null> &
	DialogProps;

function Dialog(props: Props): JSX.Element {
	const {
		title,
		primaryAction,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		secondaryAction,
		children,
		width,
		loading,
		contentProps,
		...otherProps
	} = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<MuiDialog
			{...otherProps}
			keepMounted
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth={width}
			fullScreen={fullScreen}
			TransitionComponent={Transition}
		>
			<DialogTitle id="alert-dialog-slide-title" onClose={props.onClose}>
				{title}
			</DialogTitle>
			<DialogContent dividers {...(contentProps ? contentProps : {})}>
				{loading ? (
					<Box display="flex" justifyContent="center" py={5}>
						<CircularProgress />
					</Box>
				) : (
					children
				)}
			</DialogContent>
			<DialogActions>
				{secondaryAction && (
					<MDButton
						onClick={secondaryAction.onAction}
						color="error"
						variant="outlined"
						size="small"
					>
						{secondaryAction.content}
					</MDButton>
				)}
				{primaryAction && (
					<MDButton
						startIcon={
							primaryAction.loading && (
								<CircularProgress size={22} />
							)
						}
						disabled={primaryAction.disabled === false}
						onClick={primaryAction.onAction}
						color={primaryAction.destructive ? 'error' : 'info'}
						size="small"
					>
						{primaryAction.content}
					</MDButton>
				)}
			</DialogActions>
		</MuiDialog>
	);
}

Dialog.defaultProps = {
	loading: false,
};

export default Dialog;
