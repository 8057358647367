import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {v4 as uuid} from 'uuid';
import {callApi} from 'utils/network';
import {
	HostRiskLevel,
	HostSnapshotRisk,
	KernelModuleInfo,
	ProcessInfoDetails,
	RiskType,
	RootkitInfo,
	SnapshotInfo,
} from 'types/hostsType';

export interface HostMemorySnapshotDetailsState {
	data: HostSnapshotRisk[];
	snapshotInfo: SnapshotInfo;
	processInfo: ProcessInfoDetails[];
	kernelModuleInfo: KernelModuleInfo[];
	rootkitInfo: RootkitInfo;
}

export interface HostMemorySnapshotDetailsEffects {
	load: (payload: {memoryDumpId: string}) => any;
	loadResource: (payload: {memoryDumpId: string}) => any;
}

const createData = (count): HostSnapshotRisk[] =>
	Array.from(Array(count).keys()).map((currentIndex) => {
		const riskType =
			RiskType[Object.keys(RiskType)[Math.floor(Math.random() * 2)]];
		return {
			name: `${riskType} - ${currentIndex}`,
			size: Math.floor(Math.random() * 10),
			riskLevel:
				HostRiskLevel[
					Object.keys(HostRiskLevel)[Math.floor(Math.random() * 5)]
				],
			riskType,
			hash: uuid(),
			_id: uuid(),
		};
	});

const hostMemorySnapshotDetails = makeResource<
	HostMemorySnapshotDetailsState,
	any,
	HostMemorySnapshotDetailsEffects
>({
	name: 'hostMemorySnapshotDetails',
	state: {
		data: [
			{
				_id: '',
				name: '',
				size: 0,
				riskLevel: HostRiskLevel.Critical,
				riskType: RiskType.Malware,
				hash: '',
			},
		],
		snapshotInfo: {
			_id: '',
			name: '',
			size: '545MB',
			inHost: 'AWS Host 1',
			createdAt: '4 hours ago',
			hash: '84E19FA9-3FAC-41C1-A973-0D523173AEBF',
		},
		processInfo: [
			{
				pid: '',
				ppid: '',
				files: [],
				addr: '',
				time: '',
				dtb: '',
				name: '',
				cmd: '',
				gid: '',
				uid: '',
				networkState: [],
				memInfo: [],
			},
		],
		kernelModuleInfo: [
			{
				name: '',
				size: '',
				offset: '',
				params: [{name: '', index: ''}],
				sections: [{name: '', offset: ''}],
			},
		],
		rootkitInfo: {
			linux_check_afinfo: [],
			linux_check_fop: [],
			linux_check_idt: [],
			linux_check_syscall: [],
			linux_check_tty: [],
			linux_keyboard_notifiers: [],
			linux_check_creds: [],
			linux_check_modules: [],
		},
	},
	effects: {
		*loadResource({memoryDumpId}): any {
			const resData = (yield callApi(
				`/api/v1/memdumpdetails/${memoryDumpId}`
			)).data;

			const {processInfo, kernelModuleInfo, rootkitInfo} = resData;
			const data = createData(10);
			yield put(
				hostMemorySnapshotDetails.actions.set({
					data,
					processInfo,
					kernelModuleInfo,
					rootkitInfo,
				})
			);
		},
		*load({memoryDumpId}): any {
			yield put(hostMemorySnapshotDetails.actions.startLoading());
			yield hostMemorySnapshotDetails.effects.loadResource({
				memoryDumpId,
			});
			yield put(hostMemorySnapshotDetails.actions.endLoading());
		},
	},
});

export default hostMemorySnapshotDetails;
