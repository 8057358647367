import {makeResource} from 'utils/saga-resource';
import {
	put,
	all,
	delay,
	fork,
	select,
	take,
	cancel,
	call,
} from 'redux-saga/effects';
import {callApi} from 'utils/network';
import {find, get} from 'lodash';
import {VulData} from './scan';
export interface UploadedFile {
	_id: string;
	name: string;
	size: number;
	dateUploaded: string;
	type: string;
	status: string;
	sha256: string;
}

export interface Scan {
	_id: string;
	name: string;
	description: string;
	createdAt: string;
	status: string;
	userId: string;
	fileId: string;
	fileName: string;
	vulnerability_to_search: string[];
	vul_scan_results?: VulData[];
	data: any[];
}

export interface Search {
	id: string;
	name: string;
	description: string;
	status: string;
	usermalwareIds: string[];
	results: any[];
}
export interface ProjectState {
	dateCreated: string;
	description: string;
	_id: string;
	name: string;
	traffic: number;

	risks: any;
	traffics: any;
	files: UploadedFile[];
	scans: Scan[];
	searches: Search[];
}

export interface ProjectsEffects {
	_loadProject: (id: string) => any;
	_loadRisksData: (id: string) => any;
	_loadTrafficData: (id: string) => any;
	_loadFilesData: (id: string) => any;
	_loadScansData: (id: string) => any;
	_loadSearchesData: (id: string) => any;
	_background_sync: () => any;
	_cancelBackgroundSync: () => any;
	loadResource: (id: string) => any;
	load: (id: string) => any;
	removeFile: (payload: {project_id: string; file_id: string}) => any;
	uploadFiles: (payload: {project_id: string; files: FileList | null}) => any;
	downloadFile: (payload: {project_id: string; file: UploadedFile}) => any;
	downloadFileWithId: (payload: {project_id: string; fileId: string}) => any;
	removeScan: (payload: {project_id: string; scan_id: string}) => any;
	removeSearch: (payload: {project_id: string; search_id: string}) => any;
	downloadScan: (payload: {scan: Scan}) => any;
	generateScan: (payload: {
		projectId: string;
		info: {
			name: string;
			description: string;
			vuldbIds: string[];
			fileIds: string[];
			softwareIds: string[];
		};
	}) => any;
	generateSearch: (payload: {
		projectId: string;
		info: {
			name: string;
			description: string;
			malwareIds: string[];
			fileIds: string[];
		};
	}) => any;
	_uploadFile: (payload: {project_id: string; file: File | null}) => any;
}

const project = makeResource<ProjectState, any, ProjectsEffects>({
	name: 'project',
	state: {
		dateCreated: '',
		description: '',
		_id: '',
		name: '',
		traffic: 0,
		risks: {},
		traffics: {},
		files: [],
		scans: [],
		searches: [],
	},
	effects: {
		*_loadProject(id: string): any {
			const projectData = (yield callApi(`/api/v1/projects/${id}`)).data;
			yield put(project.actions.set(projectData));
		},
		*_loadRisksData(id: string): any {
			const risksData = yield callApi(`/projects/${id}/statistics/risks`);
			yield put(project.actions.set({risks: risksData}));
		},
		*_loadTrafficData(id: string): any {
			const trafficData = yield callApi(
				`/projects/${id}/statistics/traffic`
			);
			yield put(project.actions.set({traffics: trafficData}));
		},
		*_loadFilesData(id: string): any {
			// const uploadsData = yield callApi(`/projects/${id}/uploads`);
			// yield put(project.actions.set({uploads: uploadsData}));

			const filesData = (yield callApi(`/api/v1/projects/${id}/files`))
				.data;
			yield put(project.actions.set({files: filesData}));
		},
		*_loadScansData(id: string): any {
			const scansData = (yield callApi(`/api/v1/projects/${id}/scans`))
				.data;

			yield put(project.actions.set({scans: scansData}));
		},
		*_loadSearchesData(id: string): any {
			const searchesData = yield callApi(`/projects/${id}/searches`);
			yield put(project.actions.set({searches: searchesData}));
		},
		*_background_sync(): any {
			function* getBackgroundTasks(): any {
				const effects: any = [];
				const state: ProjectState = yield select(
					(state: AppState) => state.project
				);
				if (
					Boolean(
						find(
							state.scans,
							(data) => get(data, 'status') === 'Processing'
						)
					)
				) {
					effects.push(project.effects._loadScansData(state._id));
				}
				if (
					Boolean(
						find(
							state.files,
							(data) => get(data, 'status') === 'Processing'
						)
					)
				) {
					effects.push(project.effects._loadFilesData(state._id));
				}
				if (
					Boolean(
						find(
							state.searches,
							(data) => get(data, 'status') === 'Processing'
						)
					)
				) {
					effects.push(project.effects._loadSearchesData(state._id));
				}

				return effects;
			}
			let tasks: any[];
			while (true) {
				yield delay(5000);
				if ((tasks = yield getBackgroundTasks()) && tasks.length > 0) {
					console.log('project store background refresh');
					yield all(tasks);
				}
			}
		},
		*loadResource(id: string): any {
			yield all([
				project.effects._loadProject(id),
				// project.effects._loadRisksData(id),
				// project.effects._loadTrafficData(id),
				// project.effects._loadFilesData(id),
				project.effects._loadScansData(id),
				// project.effects._loadSearchesData(id),
			]);
			yield call(project.effects._loadFilesData, id);
		},
		*load(id: string): any {
			yield put(project.actions.startLoading());
			try {
				yield project.effects.loadResource(id);
			} catch (err) {
				throw err;
			} finally {
				yield put(project.actions.endLoading());
			}
			const bgSync = yield fork(project.effects._background_sync);
			yield take(project.actions._cancelBackgroundSync().type);
			yield cancel(bgSync);
		},
		*_cancelBackgroundSync(): any {
			return 0;
		},
		*removeFile({project_id, file_id}): any {
			yield put(project.actions.startLoading());
			try {
				yield callApi(`/projects/${project_id}/uploads/${file_id}`, {
					method: 'DELETE',
				});
				yield project.effects.loadResource(project_id);
			} catch (e) {
				throw e;
			} finally {
				yield put(project.actions.endLoading());
			}
		},
		*_uploadFile({project_id, file}): any {
			if (!file) return;
			const formData = new FormData();
			formData.append('file', file);
			yield callApi(`/api/v1/projects/${project_id}/files`, {
				method: 'POST',
				data: formData,
			});
			// yield callApi(`/projects/${project_id}/uploads/binary`, {
			// 	method: 'POST',
			// 	data: formData,
			// });
		},
		*uploadFiles({project_id, files}): any {
			if (!files) return;
			yield put(project.actions.startLoading());

			const tasks: IterableIterator<any>[] = [];
			for (let i = 0; i < files.length; i++) {
				tasks.push(
					project.effects._uploadFile({project_id, file: files[i]})
				);
			}
			yield all(tasks);
			yield project.effects.loadResource(project_id);
			yield put(project.actions.endLoading());
		},
		*downloadFile({project_id, file}): any {
			const data = yield callApi(
				`/projects/${project_id}/uploads/${file._id}`,
				{
					method: 'GET',
					responseType: 'blob',
				}
			);
			const url = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = url;
			link.download = file.name;
			link.click();
			window.URL.revokeObjectURL(url);
		},
		*downloadFileWithId({project_id, fileId}): any {
			const data = yield callApi(
				`/projects/${project_id}/uploads/${fileId}`,
				{
					method: 'GET',
					responseType: 'blob',
				}
			);
			const url = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = url;
			// link.download = file.name;
			link.click();
			window.URL.revokeObjectURL(url);
		},
		*removeScan({project_id, scan_id}): any {
			yield put(project.actions.startLoading());
			try {
				yield callApi(
					`/api/v1/projects/${project_id}/scans/${scan_id}`,
					{
						method: 'DELETE',
					}
				);
				yield project.effects._loadScansData(project_id);
			} catch (e) {
				throw e;
			} finally {
				yield put(project.actions.endLoading());
			}
		},
		*removeSearch({project_id, search_id}): any {
			yield put(project.actions.startLoading());
			try {
				yield callApi(`/projects/${project_id}/searches/${search_id}`, {
					method: 'DELETE',
				});
				yield project.effects._loadSearchesData(project_id);
			} catch (e) {
				throw e;
			} finally {
				yield put(project.actions.endLoading());
			}
		},
		*downloadScan({scan}): any {
			const data = yield callApi(`/get_vul_scan_report/${scan._id}`, {
				method: 'GET',
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = url;
			link.download = `${scan.name}.docx`;
			link.click();
			window.URL.revokeObjectURL(url);
		},
		*generateScan({projectId, info}): any {
			/**
			 * info:
			 * {
			 * 	name: string,
			 * 	fileIds: string[],
			 * 	vuldbIds: string[],
			 * 	description: string
			 * }
			 */
			yield put(project.actions.startLoading());

			yield callApi(`/api/v1/projects/${projectId}/scans`, {
				method: 'POST',
				data: info,
			});

			// const formData = new FormData();
			// formData.append('name', info.name);
			// formData.append('description', info.description);
			// // formData.append('productId', info.productIds[0]);
			// for (const productId of info.productIds) {
			// 	formData.append('productIds', productId);
			// }
			// for (const fileId of info.fileIds) {
			// 	formData.append('fileIds', fileId);
			// }
			// // formData.append('fileId', info.fileIds[0]);
			// yield callApi(`/projects/${projectId}/scans`, {
			// 	method: 'POST',
			// 	data: formData,
			// });
			yield project.effects._loadScansData(projectId);
			yield put(project.actions.endLoading());
		},
		*generateSearch({projectId, info}): any {
			yield put(project.actions.startLoading());
			const formData = new FormData();
			formData.append('name', info.name);
			formData.append('description', info.description);
			// formData.append('productId', info.productIds[0]);
			for (const malwareId of info.malwareIds) {
				formData.append('malwareIds', malwareId);
			}
			for (const fileId of info.fileIds) {
				formData.append('fileIds', fileId);
			}
			// formData.append('fileId', info.fileIds[0]);
			yield callApi(`/projects/${projectId}/searches`, {
				method: 'POST',
				data: formData,
			});
			yield project.effects._loadSearchesData(projectId);
			yield put(project.actions.endLoading());
		},
	},
});

export default project;
