import {MaterialTableProps} from '@material-table/core';
import {Skeleton, Stack} from '@mui/material';
import DBTable from 'components/DBTable';
import {DemoAccountContext} from 'components/DemoAccount';
import {renderCveColumn} from 'pages/DrCloud/common/renderCveColumn';
import {renderMalwareColumnInListPage} from 'pages/DrCloud/common/renderMalwareColumn';
import {SBOMStreamDef} from 'pages/Registry/StreamSourceTable';
import React, {useCallback, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import MDBadge from 'shared/MDBadge';
import MDBadgeDot from 'shared/MDBadgeDot';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import useSWR from 'swr';
import {GitHubBranchDef, GitHubBranchRes} from 'types/githubApi';
import {
	DBTableQuery,
	PaginatedDocs,
	callApi,
	callSharedApi,
	paginateParams,
} from 'utils/network';
import {filterSingleStreamScanSummaryWithBatchSummaryData} from '../utils/utils';

interface Props {
	repoPath: string;
	accountId?: string;
	githubApiUrl: string;
	onWatch?: (identifier: string, action: 'watch' | 'unwatch') => void;
	onScan: (projectId: string, assetId: string, sbomStreamId: string) => void;
	streams: SBOMStreamDef[];
	isWatching: boolean;
	isTriggeringScan: boolean;
	isFreeTool?: boolean;
	projectAssetId: string;
}

const GitHubRepoAssetSettings: React.FC<Props> = (props) => {
	const tableRef = React.useRef<MaterialTableProps<Record<string, never>>>();
	const isDemoAccount = useContext(DemoAccountContext);

	const streamIds = useMemo(
		() => props.streams.map((s) => s._id),
		[props.streams]
	);

	const {data: scanSummaryData, isLoading: isLoadingScanSummaryRaw} = useSWR<{
		data: any;
	}>(
		streamIds?.length
			? `/api/v1/project_scans?streamIds=${streamIds.join(',')}`
			: null,
		props.isFreeTool
			? (url) =>
					callSharedApi.get(url, {
						...(isDemoAccount && {
							headers: {
								'x-demo-user': 'true',
							},
						}),
					})
			: callApi.get,
		{
			refreshInterval: 5000,
		}
	);

	const isLoadingScanSummary = isLoadingScanSummaryRaw && streamIds?.length;

	const scanSummaries = scanSummaryData?.data;

	const watchedStreams = props.streams?.filter((s) => s.isWatched);
	const watched = watchedStreams?.map((s) => s.branchName);

	const queryData = useCallback(
		(query) => {
			const api = props.isFreeTool ? callSharedApi : callApi;
			return api
				.get<PaginatedDocs<GitHubBranchRes[]>>(
					props.githubApiUrl.concat('/branch'),
					{
						params: {
							...paginateParams(query as unknown as DBTableQuery),
							projectAssetId: props.projectAssetId,
						},
						...(isDemoAccount && {
							headers: {
								'x-demo-user': 'true',
							},
						}),
					}
				)
				.then((res) => {
					return {
						data: res.data.docs || [],
						page: res.data.page - 1,
						totalCount: res.data.totalDocs,
					};
				});
		},
		[props.githubApiUrl]
	);

	const {t} = useTranslation();
	const columns: any = useMemo(
		() => [
			{
				title: t('github.branch.name'),
				field: 'name',
				render: (rowData) => {
					return (
						<MDBox display="flex" alignItems="center">
							<Stack spacing={1}>
								<MDBadge
									variant="contained"
									badgeContent={rowData.name}
									key={rowData.name}
								/>
								<MDBadgeDot
									color="success"
									badgeContent={rowData.commit?.sha?.slice(
										0,
										8
									)}
								/>
							</Stack>
						</MDBox>
					);
				},
			},
			{
				title: t('malware'),
				type: 'boolean',
				render: (row) => {
					if (isLoadingScanSummary) {
						return <Skeleton />;
					}

					const summary =
						filterSingleStreamScanSummaryWithBatchSummaryData({
							streams: props.streams,
							identifier: row.name,
							scanSummaries,
						});

					return renderMalwareColumnInListPage(summary?.malware);
				},
			},
			{
				title: t('vulnerabilities'),
				field: 'vulnerabilityCount',
				type: 'boolean',
				render: (row) => {
					if (isLoadingScanSummary) {
						return <Skeleton />;
					}

					const summary =
						filterSingleStreamScanSummaryWithBatchSummaryData({
							streams: props.streams,
							identifier: row.name,
							scanSummaries,
						});

					return renderCveColumn(summary?.vulnerabilties ?? {});
				},
			},
			{
				title: t('action'),
				render: (rowData: GitHubBranchDef) => (
					<ActionButton
						fullName={props.repoPath}
						isFreeTool={props.isFreeTool}
						gitHubApiUrl={props.githubApiUrl}
						isWatching={props.isWatching}
						onWatch={props.onWatch}
						isWatched={!!watched?.includes(rowData.name)}
						rowData={rowData}
						viewButtonStream={props.streams?.find(
							(s) =>
								s.branchName === rowData.name &&
								s.scanResult?.length
						)}
						onScan={props.onScan}
						isDemoAccount={isDemoAccount}
					/>
				),
			},
		],
		[
			watched,
			isLoadingScanSummary,
			scanSummaries,
			props.githubApiUrl,
			props.isWatching,
		]
	);

	return (
		<MDBox>
			<DBTable
				tableRef={tableRef}
				title={t('github.branchTableTitle')}
				data={queryData}
				columns={columns}
			/>
		</MDBox>
	);
};

const ActionButton: React.FC<{
	fullName: string;
	rowData: GitHubBranchDef;
	isFreeTool?: boolean;
	onWatch?: (identifier: string, action: 'watch' | 'unwatch') => void;
	gitHubApiUrl: string;
	isWatching: boolean;
	isWatched: boolean;
	viewButtonStream?: SBOMStreamDef;
	onScan: (projectId: string, assetId: string, sbomStreamId: string) => void;
	isDemoAccount?: boolean;
}> = ({
	onWatch,
	rowData,
	isWatching,
	isWatched,
	viewButtonStream,
	isFreeTool,
	fullName,
	isDemoAccount,
}) => {
	const {t} = useTranslation();

	const link = isFreeTool
		? `/github/${fullName}/${
				viewButtonStream?.branchName
					? encodeURIComponent(viewButtonStream?.branchName)
					: ''
		  }${isDemoAccount ? '?demo=true' : ''}`
		: `/project/${viewButtonStream?.projectId}/${viewButtonStream?.projectAssetId}/${viewButtonStream?._id}`;

	return (
		<Stack direction="row" spacing={1}>
			{!isDemoAccount && !!onWatch && (
				<MDButton
					color="info"
					variant="text"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						onWatch(rowData.name, isWatched ? 'unwatch' : 'watch');
					}}
					disabled={isWatching}
				>
					{t(isWatched ? 'projects.unMonitor' : 'projects.monitor')}
				</MDButton>
			)}
			<MDButton
				component={Link}
				to={link}
				aria-label="view scan detail"
				color="info"
				variant="text"
				disabled={!viewButtonStream}
			>
				{t('view')}
			</MDButton>
		</Stack>
	);
};
export default GitHubRepoAssetSettings;
