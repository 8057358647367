import * as ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import {Provider} from 'react-redux';
import store from 'store';
import {BrowserRouter} from 'react-router-dom';
import 'styles/index.scss';
import {initAlert} from 'utils/alert';
import {MaterialUIControllerProvider} from 'shared/context';
import theme from 'assets/theme';
import SBOMToolAppMain from './SBOMToolAppMain';
import {SetGoogleAnalytics} from 'utils/analytics/SetGoogleAnalytics';

initAlert(store);

console.log(`APP ENV: ${process.env.REACT_APP_ENV}`);

const SBOMTool = () => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Provider store={store}>
					<BrowserRouter>
						<SetGoogleAnalytics />
						<SBOMToolAppMain />
					</BrowserRouter>
				</Provider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

ReactDOM.render(
	<MaterialUIControllerProvider isFreeTool>
		<SBOMTool />
	</MaterialUIControllerProvider>,
	document.getElementById('app')
);
