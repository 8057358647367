import {Props as ReactProps} from 'react';

import {Link as MLink, LinkProps as MLinkProps} from '@mui/material';
import {Link as RLink, LinkProps as RLinkProps} from 'react-router-dom';
export interface StateProps {}
export interface ActionProps {}
export interface OwnProps extends ReactProps<null> {}

type Props = StateProps & ActionProps & OwnProps & MLinkProps & RLinkProps;

function Link(props: Props): JSX.Element {
	return (
		<MLink component={RLink} {...props}>
			{props.children}
		</MLink>
	);
}

export default Link;
