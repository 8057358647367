import {Store} from 'redux';
import alertStore from 'store/alert';

let store: Store | undefined;
export function initAlert(s: Store): void {
	store = s;
}

export default function alert(msg: string): void {
	console.log(msg);
	store?.dispatch(alertStore.actions.openAlert(msg));
}

window.alert = alert;
