/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon, {IconProps} from '@mui/material/Icon';
import {styled, Theme} from '@mui/material/styles';
import colors from 'assets/theme/base/colors';

interface OwnerState extends IconProps {
	color: typeof colors['gradients'];
	bgWhite?: boolean;
}
export default styled(Icon)<{
	ownerState: OwnerState;
}>(({theme, ownerState}: {theme: Theme; ownerState: OwnerState}) => {
	const {palette, functions, typography} = theme;
	const {color, bgWhite} = ownerState;

	const {white, transparent, gradients} = palette;
	const {pxToRem, linearGradient} = functions;
	const {size} = typography;

	// backgroundImage value
	let backgroundImageValue;

	if (bgWhite) {
		backgroundImageValue = gradients[color]
			? linearGradient(gradients[color].main, gradients[color].state)
			: linearGradient(gradients.info.main, gradients.info.state);
	} else if (color === 'light') {
		backgroundImageValue = linearGradient(
			gradients.dark.main,
			gradients.dark.state
		);
	}

	return {
		backgroundImage: backgroundImageValue,
		WebkitTextFillColor:
			bgWhite || color === 'light' ? transparent.main : white.main,
		WebkitBackgroundClip: 'text',
		marginRight: pxToRem(8),
		fontSize: size.lg,
		transform: `translateY(${pxToRem(-2)})`,
	};
});
