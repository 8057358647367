import {makeResource} from 'utils/saga-resource';
import {all, cancel, delay, fork, put, select, take} from 'redux-saga/effects';
import {
	ContainerResult,
	ContainerStaticResult,
} from 'types/drCloudOnPremisesType';
import {callApi} from 'utils/network';
import axios from 'axios';
import {channel} from 'redux-saga';
import _ from 'lodash';
import NotificationStore from 'store/notification';
import {
	METERING_CONTROL_MSG,
	MeteringControlReasons,
} from 'types/meterControlType';
import i18n from 'i18next';
import {
	getScanStatus,
	ScanStatusEnum,
} from 'pages/DrCloud/ContainerScanDetails/components/ScanStatus';

export interface ContainerResultState {
	result: ContainerResult;
	name: string;
	blobName: string;
	uploadProgressPercentage: number;
	isUploading: boolean;
	scanStartAt?: string;
	scanEndAt?: string;
	uploadTime?: string;
	imageSizeInBytes?: number;
	isUploadFailedDueToMeteringControl?: boolean;
	uploadFailedReason: MeteringControlReasons | undefined;
	id?: string;
	imageUploadController?: AbortController;
}

export interface ContainerResultEffects {
	_background_sync: () => any;
	_cancelBackgroundSync: () => any;
	load: (payload: {imageId?: string}) => any;
	loadResult: (payload: {imageId: string}) => any;
	watchDownloadFileChannel: () => any;
	uploadContainerImage: (payload: {
		imageId: string;
		data: {file: any};
	}) => any;
	cancelImageUpload: () => void;
	resetUploadProgress: () => any;
	setIsUploadFailedDueToMeteringControl: (payload: {
		isFailed: boolean;
	}) => any;
}

const downloadFileChannel = channel();

const convertData = (data): ContainerStaticResult[] =>
	data[0]?.map((x) => {
		const [filePath, info = []] = x;

		const infoWithCve =
			info?.map((item) => {
				const cveInsideComponents = _.flatMap(
					item.components,
					(c) => c.cve || []
				);
				return {
					...item,
					cve: (item.cve || []).concat(cveInsideComponents),
				};
			}) || [];

		const cve = (info.cve || []).concat(
			_.flatMap(infoWithCve, (item) => item.cve || [])
		);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: next-line
		return {
			filePath,
			details: [...infoWithCve],
			cve,
		} as ContainerStaticResult;
	});

export const convertStaticResult = (data): ContainerStaticResult[] =>
	data
		?.map((x) => {
			const [langType, filePath, info = []] = x;

			const infoWithCve =
				info?.map((item) => {
					const cveInsideComponents = _.flatMap(
						item.components,
						(c) => c.cve || []
					);
					return {
						...item,
						cve: (item.cve || []).concat(cveInsideComponents),
					};
				}) || [];

			const cve = (info.cve || []).concat(
				_.flatMap(infoWithCve, (item) => item.cve || [])
			);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore: next-line
			return {
				filePath,
				details: [...infoWithCve],
				cve,
				langType,
			} as ContainerStaticResult;
		})
		.reduce((acc: ContainerStaticResult[], item) => {
			const existingItem = acc.find((x) => x.filePath === item.filePath);

			if (existingItem) {
				const existingItemIndex = acc.findIndex(
					(x) => x.filePath === item.filePath
				);

				acc[existingItemIndex] = item;
				return acc;
			}

			acc.push(item);

			return acc;
		}, []);

const containerResult = makeResource<
	ContainerResultState,
	any,
	ContainerResultEffects
>({
	name: 'containerResult',
	state: {
		result: {staticRes: []},
		name: '',
		blobName: '',
		uploadProgressPercentage: 0,
		isUploading: false,
		uploadFailedReason: undefined,
	},
	effects: {
		*_background_sync(): any {
			function* getBackgroundTasks(): any {
				const effects: any = [];
				const state: ContainerResultState = yield select(
					(state: AppState) => state.containerResult
				);
				const scanStatus = getScanStatus(state);
				if (scanStatus?.status === ScanStatusEnum.Scanning) {
					effects.push(
						containerResult.effects.loadResult({
							imageId: state.id as string,
						})
					);
				}

				return effects;
			}
			let tasks: any[];

			while (true) {
				yield delay(5000);
				if ((tasks = yield getBackgroundTasks()) && tasks.length > 0) {
					console.log('container scan background refresh');
					yield all(tasks);
				}
			}
		},
		*_cancelBackgroundSync(): any {
			return 0;
		},
		*loadResult({imageId}): any {
			const data = (yield callApi(`/api/v1/containers/${imageId}`)).data;

			let staticRes = convertData(data.scan_result ?? [[]]) || [];

			if (data.scan_res?.static) {
				staticRes = convertStaticResult(data.scan_res.static);
			}

			// const dynamic = data.scan_res?.dynamic ?? [];
			// const layer = data.scan_res?.layer ?? [];
			const final = data.scan_res?.final;
			const error = data.scan_res?.error;

			yield put(
				containerResult.actions.set({
					result: {staticRes, final, error},
					name: data.name,
					blobName: data.blob_name,
					scanStartAt: data.scanStartAt,
					scanEndAt: data.scanEndAt,
					imageSizeInBytes: data.image_size_in_bytes,
					uploadTime: data.image_upload_time,
					id: imageId,
				})
			);
		},
		*load({imageId}): any {
			if (imageId) {
				yield put(containerResult.actions.startLoading());
				yield containerResult.effects.loadResult({
					imageId: imageId,
				});
				yield put(containerResult.actions.endLoading());

				const bgSync = yield fork(
					containerResult.effects._background_sync
				);
				yield take(
					containerResult.actions._cancelBackgroundSync().type
				);
				yield cancel(bgSync);
			}
		},
		*watchDownloadFileChannel(): any {
			while (true) {
				const action = yield take(downloadFileChannel);
				yield put(action);
			}
		},
		*cancelImageUpload(): any {
			const controller = yield select(
				(state: AppState) => state.containerResult.imageUploadController
			);
			if (!controller) {
				return;
			}

			controller.abort();
			yield put(containerResult.actions.resetUploadProgress());
		},
		*uploadContainerImage({imageId, data}): any {
			yield put(containerResult.actions.set({isUploading: true}));
			const formData = new FormData();

			formData.append('file', data.file[0]);
			const controller = new AbortController();
			yield put(
				containerResult.actions.set({
					imageUploadController: controller,
				})
			);

			try {
				const urlInfo = (yield callApi(
					`api/v1/containers/${imageId}/get_upload_url/?size=${(
						data.file[0].size /
						1024 /
						1024
					).toFixed(2)}`,
					{signal: controller.signal}
				)).data;

				const options = {
					signal: controller.signal,
					headers: {
						'Content-Type': 'application/x-tar',
					},
					onUploadProgress: function (progressEvent): void {
						const uploadProgressPercentage = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);

						downloadFileChannel.put(
							containerResult.actions.set({
								uploadProgressPercentage,
							})
						);
					},
				};

				const uploadUrl = urlInfo.uploadUrl;

				yield axios.put(uploadUrl, data.file[0], options);

				yield callApi(`/api/v1/containers/${imageId}/upload_success`, {
					method: 'PUT',
					data: {path: urlInfo.path},
					signal: controller.signal,
				});
				yield put(containerResult.actions.set({isUploading: false}));
			} catch (e: any) {
				if (METERING_CONTROL_MSG[e.response?.data?.data]) {
					yield put(
						containerResult.actions.set({
							isUploadFailedDueToMeteringControl: true,
							uploadFailedReason: e.response.data.data,
						})
					);
					yield put(
						NotificationStore.actions.show({
							msg: METERING_CONTROL_MSG[e.response.data.data],
							type: 'error',
						})
					);
				} else {
					if (
						e.isAxiosError &&
						e.response &&
						e.response.status == 403
					) {
						const errorMessageKey = e.response.data.data
							?.isTeamMember
							? 'error403forTeamMember'
							: 'error403';

						alert(i18n.t(errorMessageKey));

						return;
					}

					throw e;
				}
			} finally {
				yield put(containerResult.actions.set({isUploading: false}));
			}
		},
		*resetUploadProgress(): any {
			yield put(
				containerResult.actions.set({uploadProgressPercentage: 0})
			);
		},
		*setIsUploadFailedDueToMeteringControl({isFailed}): any {
			yield put(
				containerResult.actions.set({
					isUploadFailedDueToMeteringControl: isFailed,
					uploadFailedReason: undefined,
				})
			);
		},
	},
});

export default containerResult;
