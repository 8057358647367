/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';

const {size, fontWeightRegular} = typography;
const {dark, borderColor} = colors;

// types
type Types = any;

const tab: Types = {
	styleOverrides: {
		root: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			flex: '0 1 auto',
			textAlign: 'center',
			maxWidth: 'unset !important',
			minWidth: '150px !important',
			minHeight: 'unset !important',
			fontSize: size.md,
			fontWeight: fontWeightRegular,
			textTransform: 'none',
			lineHeight: 'inherit',
			padding: pxToRem(10),
			borderRadius: '0 !important',
			color: `${dark.main} !important`,
			opacity: '1 !important',

			'&[tabindex="0"]': {
				boxShadow: 'none',
			},
			boxShadow: `inset -1px 0px ${borderColor}`,

			'& .material-icons, .material-icons-round': {
				marginBottom: '0 !important',
				marginRight: pxToRem(6),
			},

			'& svg': {
				marginBottom: '0 !important',
				marginRight: pxToRem(6),
			},
		},

		labelIcon: {
			paddingTop: pxToRem(4),
		},
	},
};

export default tab;
