/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState, ReactNode} from 'react';

// react-router-dom components
import {useLocation, NavLink} from 'react-router-dom';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// Material Dashboard 2 PRO React TS components
import MDBox from 'shared/MDBox';
import MDTypography from 'shared/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';

// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';

// Material Dashboard 2 PRO React context
import {
	useMaterialUIController,
	setMiniSidenav,
	setTransparentSidenav,
	setWhiteSidenav,
} from 'shared/context';
import {Tooltip} from '@mui/material';
import {trackEvent} from 'utils/analytics/ga';
import SidenavCollapseButton from './SidenavCollapseButton';
import GithubSidenavMenu from './GithubSidenavMenu';
import {Theme} from '@mui/material/styles';

// Declaring props types for Sidenav
export interface Props {
	color?:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'success'
		| 'warning'
		| 'error'
		| 'dark';
	brand?: string;
	routes: {
		[key: string]:
			| ReactNode
			| string
			| {
					[key: string]:
						| ReactNode
						| string
						| {
								[key: string]: ReactNode | string;
						  }[];
			  }[];
	}[];
	isFreeTool?: boolean;
	[key: string]: any;
}

function Sidenav({
	color,
	brand,
	routes,
	onMouseEnterActive,
	setOnMouseEnter,
	isFreeTool,
	switcher,
	...rest
}: Props): JSX.Element {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			siteLogo: {
				position: 'relative',

				'&::after': {
					content: '"TOOLS"',
					color: 'white',
					position: 'absolute',
					fontSize: '0.9rem',
					fontWeight: 'bold',
					bottom: '14%',
					left: '100%',
					marginLeft: theme.spacing(0.8),
					// transform: 'translateX(-50%)',
				},
			},
		})
	);

	const classes = useStyles();

	const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
	const [openNestedCollapse, setOpenNestedCollapse] = useState<
		boolean | string
	>(false);
	const [controller, dispatch] = useMaterialUIController();
	const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} =
		controller;
	const location = useLocation();
	const {pathname} = location;
	const collapseName = pathname.split('/').slice(1)[0];
	const items = pathname.split('/').slice(1);
	const itemParentName = items[1];
	const itemName = items[items.length - 1];

	let textColor:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'success'
		| 'warning'
		| 'error'
		| 'dark'
		| 'white'
		| 'inherit'
		| 'text'
		| 'light' = 'white';

	if (transparentSidenav || (whiteSidenav && !darkMode)) {
		textColor = 'dark';
	} else if (whiteSidenav && darkMode) {
		textColor = 'inherit';
	}

	const closeSidenav = () => setMiniSidenav(dispatch, true);

	useEffect(() => {
		setOpenCollapse(collapseName);
		setOpenNestedCollapse(itemParentName);
	}, []);

	useEffect(() => {
		const xlBreakPoint = 1100;
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < xlBreakPoint);
			setTransparentSidenav(
				dispatch,
				window.innerWidth < xlBreakPoint ? false : transparentSidenav
			);
			setWhiteSidenav(
				dispatch,
				window.innerWidth < xlBreakPoint ? false : whiteSidenav
			);
		}

		/**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
		window.addEventListener('resize', handleMiniSidenav);

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleMiniSidenav);
	}, [dispatch, location]);

	// Render all the nested collapse items from the routes.js
	const renderNestedCollapse = (collapse: any) => {
		const template = collapse.map(
			({name, route, key, href, icon, type}: any) => {
				if (type === 'github') {
					return <GithubSidenavMenu key={key} icon={icon} />;
				}

				return href ? (
					<Link
						key={key}
						href={href}
						target="_blank"
						rel="noreferrer"
						sx={{textDecoration: 'none'}}
						onClick={() => {
							trackEvent({
								name: `sidenav-external-link-${key}`,
								params: {
									url: href,
								},
							});
						}}
					>
						<SidenavItem icon={icon} name={name} nested />
					</Link>
				) : (
					<NavLink
						to={route}
						key={key}
						style={{textDecoration: 'none'}}
					>
						<SidenavItem
							name={name}
							icon={icon}
							active={route === pathname}
							nested
						/>
					</NavLink>
				);
			}
		);

		return template;
	};
	// Render the all the collpases from the routes.js
	const renderCollapse = (collapses: any) =>
		collapses.map(
			({
				name,
				collapse,
				route,
				href,
				key,
				onClick,
				disabled,
				type,
			}: any) => {
				let returnValue;

				if (collapse) {
					returnValue = (
						<SidenavItem
							key={key}
							color={color}
							name={name}
							nested
							active={key === itemParentName ? 'isParent' : false}
							open={openNestedCollapse === key}
							onClick={({currentTarget}: any) =>
								openNestedCollapse === key &&
								currentTarget.classList.contains(
									'MuiListItem-root'
								)
									? setOpenNestedCollapse(false)
									: setOpenNestedCollapse(key)
							}
						>
							{renderNestedCollapse(collapse)}
						</SidenavItem>
					);
				} else if (type === 'title') {
					returnValue = (
						<MDTypography
							key={key}
							color={textColor}
							display="block"
							variant="caption"
							fontWeight="bold"
							textTransform="uppercase"
							pl={2}
							mt={2}
							mb={1}
							ml={5}
						>
							{name}
						</MDTypography>
					);
				} else {
					returnValue = href ? (
						<Link
							href={href}
							key={key}
							target="_blank"
							rel="noreferrer"
							sx={{textDecoration: 'none'}}
							onClick={(...args) => {
								trackEvent({
									name: `sidenav-external-link-${key}`,
									params: {
										name,
										url: href,
									},
								});
								onClick(...args);
							}}
						>
							<SidenavItem
								nested
								color={color}
								name={name}
								active={key === itemName}
							/>
						</Link>
					) : disabled ? (
						<SidenavItem
							nested
							color={color}
							name={name}
							active={key === itemName}
						/>
					) : (
						<NavLink
							to={route}
							key={key}
							style={{textDecoration: 'none'}}
						>
							<SidenavItem
								nested
								color={color}
								name={name}
								active={key === itemName}
							/>
						</NavLink>
					);
				}
				return (
					<SidenavList key={key}>
						{disabled ? (
							<Tooltip
								title="Feature in development"
								placement="right"
							>
								<MDBox
									sx={{
										opacity: disabled ? 0.6 : 1,
										userSelect: 'none',
									}}
								>
									{returnValue}
								</MDBox>
							</Tooltip>
						) : (
							returnValue
						)}
					</SidenavList>
				);
			}
		);

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const renderRoutes = routes.map(
		({
			type,
			name,
			icon,
			title,
			collapse,
			noCollapse,
			key,
			href,
			route,
		}: any) => {
			let returnValue;

			if (type === 'collapse') {
				if (href) {
					returnValue = (
						<Link
							href={href}
							key={key}
							target="_blank"
							rel="noreferrer"
							sx={{textDecoration: 'none'}}
							onClick={() => {
								trackEvent({
									name: `sidenav-external-link-${key}`,
									params: {
										url: href,
										name,
									},
								});
							}}
						>
							<SidenavCollapse
								name={name}
								icon={icon}
								active={key === collapseName}
								noCollapse={noCollapse}
							/>
						</Link>
					);
				} else if (noCollapse && route) {
					returnValue = (
						<NavLink
							to={route}
							key={key}
							onClick={() => {
								trackEvent({
									name: `sidenav-${key}`,
									params: {
										name,
									},
								});
							}}
						>
							<SidenavCollapse
								name={name}
								icon={icon}
								noCollapse={noCollapse}
								active={key === collapseName}
							>
								{collapse ? renderCollapse(collapse) : null}
							</SidenavCollapse>
						</NavLink>
					);
				} else {
					returnValue = (
						<SidenavCollapse
							key={key}
							name={name}
							icon={icon}
							active={key === collapseName}
							open={true}
							onClick={() =>
								openCollapse === key
									? setOpenCollapse(false)
									: setOpenCollapse(key)
							}
						>
							{collapse && !miniSidenav
								? renderCollapse(collapse)
								: null}
						</SidenavCollapse>
					);
				}
			} else if (type === 'title') {
				returnValue = (
					<MDTypography
						key={key}
						color={textColor}
						display="block"
						variant="caption"
						fontWeight="bold"
						textTransform="uppercase"
						pl={3}
						mt={2}
						mb={1}
						ml={1}
					>
						{title}
					</MDTypography>
				);
			} else if (type === 'divider') {
				returnValue = (
					<Divider
						key={key}
						light={
							(!darkMode &&
								!whiteSidenav &&
								!transparentSidenav) ||
							(darkMode && !transparentSidenav && whiteSidenav)
						}
					/>
				);
			} else if (type === 'github') {
				returnValue = <GithubSidenavMenu key={key} icon={icon} />;
			}

			return returnValue;
		}
	);

	return (
		<SidenavRoot
			{...rest}
			className="overlay-scrollbar-container"
			variant="permanent"
			ownerState={{
				transparentSidenav,
				whiteSidenav,
				miniSidenav,
				darkMode,
			}}
		>
			<MDBox
				pt={3}
				pb={1}
				mb={1}
				px={miniSidenav ? 4 : 3}
				textAlign="center"
			>
				<MDBox
					display={{xs: 'block', xl: 'none'}}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{cursor: 'pointer'}}
				>
					<MDTypography variant="h6" color="secondary">
						<Icon sx={{fontWeight: 'bold'}}>close</Icon>
					</MDTypography>
				</MDBox>
				<MDBox
					component={NavLink}
					to="/"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						...(isFreeTool && {
							transform: 'scale(0.8)',
							...(!controller.miniSidenav && {
								transformOrigin: 'left',
							}),
						}),
					}}
					className={
						isFreeTool && !controller.miniSidenav
							? classes.siteLogo
							: ''
					}
				>
					{brand && (
						<MDBox
							component="img"
							src={brand}
							alt="Drcloud"
							height="36px"
							style={{objectFit: 'cover'}}
						/>
					)}
				</MDBox>
				{switcher}
			</MDBox>
			<List>{renderRoutes}</List>
			<SidenavCollapseButton
				setOnMouseEnter={setOnMouseEnter}
				onMouseEnterActive={onMouseEnterActive}
			/>
		</SidenavRoot>
	);
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
	color: 'info',
	brand: '',
};

export default Sidenav;
