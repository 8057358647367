export enum HostRiskLevel {
	Critical,
	High,
	Medium,
	Low,
	Safe,
}

export enum HostStatus {
	Lost,
	Idle,
	Scanning,
	Pending,
}

export interface MemoryDump {
	_id: string;
	date: string;
	status: string;
}

export interface HostTag {
	_id: string;
	name: string;
}

export interface HostListItem {
	_id: string;
	userId: string;
	key: string;
	name: string;
	lastScanned: string;
	status: HostStatus;
	memoryDumps: MemoryDump[];
	tags: HostTag[];
}

export interface HostRiskSummaryInfo {
	critical: number;
	high: number;
	medium: number;
	low: number;
	clean: number;
}

export interface RiskSummaryInfo {
	vulnerability: number;
	malware: number;
}

export interface SummaryData {
	hostRiskSummaryInfo: HostRiskSummaryInfo;
	riskSummaryInfo: RiskSummaryInfo;
}

export enum RiskType {
	Malware,
	Vulnerability,
}

export interface HostSnapshotRisk {
	_id: string;
	name: string;
	size: number;
	riskLevel: HostRiskLevel;
	riskType: RiskType;
	hash: string;
}

export interface SnapshotInfo {
	_id: string;
	name: string;
	size: string;
	inHost: string;
	createdAt: string;
	hash: string;
}

export interface ProcessFile {
	descriptor: string;
	name: string;
}

export interface MemInfoDetails {
	bitness: string;
	flags: string;
	fpath: string;
	inode: string;
	major: string;
	minor: string;
	pgoff: string;
	size: string;
	start: string;
	components: MemComponentInfoDetails[];
}

export interface MemComponentInfoDetails {
	type: string;
	name: string;
	version: string;
}

export interface GenericRootkitInfoDetail {
	Name: string;
	Addr: string;
	Symbol: string;
}

export interface RootkitInfoLinuxCheckCred {
	PIDs: string;
}

export interface RootkitInfoLinuxCheckModule {
	ModuleAddress: string;
	ModuleName: string;
}

export interface RootkitInfo {
	linux_check_afinfo: GenericRootkitInfoDetail[];
	linux_check_fop: GenericRootkitInfoDetail[];
	linux_check_idt: GenericRootkitInfoDetail[];
	linux_check_syscall: GenericRootkitInfoDetail[];
	linux_check_tty: GenericRootkitInfoDetail[];
	linux_keyboard_notifiers: GenericRootkitInfoDetail[];
	linux_check_creds: RootkitInfoLinuxCheckCred[];
	linux_check_modules: RootkitInfoLinuxCheckModule[];
}

export interface ProcessInfoDetails {
	pid: string;
	ppid?: string;
	files: ProcessFile[];
	addr: string;
	time: string;
	dtb: string;
	name: string;
	cmd: string;
	gid: string;
	uid: string;
	networkState: NetWorkStateInfo[];
	memInfo: MemInfoDetails[];
}

export interface KernelModuleParam {
	index: string;
	name: string;
}
export interface KernelModuleSection {
	offset: string;
	name: string;
}
export interface KernelModuleInfo {
	name: string;
	offset: string;
	params: KernelModuleParam[];
	sections: KernelModuleSection[];
	size: string;
}

export interface NetWorkStateInfo {
	protocol: string;
	state: string;
	localIP: string;
	localPort: number;
	remoteIP: string;
	remotePort: number;
}

export interface HostMemorySnapshot {
	_id: string;
	name: string;
	size: number;
	riskLevel: HostRiskLevel;
	updated: string;
	hash: string;
}

export interface Schedule {
	cron: string;
	fixedFrequencyType: string;
	fixedFrequencyValue: number;
	scheduleType: 'cron' | 'fixed';
}

export interface HostTag {
	_id: string;
	name: string;
}

export interface HostInfo {
	_id: string;
	userId: string;
	key: string;
	name: string;
	lastScanned: string;
	status: HostStatus;
	memoryDumps: MemoryDump[];
	tags: HostTag[];
}
