/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';

// Material Dashboard 2 PRO React TS Helper Functions

const {white, info, lessBlue, borderColor} = colors;

// types
type Types = any;

const tabs: Types = {
	styleOverrides: {
		root: {
			position: 'relative',
			backgroundColor: lessBlue.main,
			boxShadow: `inset 0px 1px ${borderColor}`,
			borderRadius: 0,
			minHeight: 'unset',
			padding: 0,
		},

		flexContainer: {
			height: '100%',
			position: 'relative',
			zIndex: 10,
		},

		fixed: {
			overflow: 'unset !important',
			overflowX: 'unset !important',
		},

		vertical: {
			'& .MuiTabs-indicator': {
				width: '100%',
			},
		},

		indicator: {
			height: '100%',
			borderRadius: 0,
			backgroundColor: white.main,
			boxShadow: `inset 0px 3px 0px 0px ${info.main}`,
			transition: 'all 500ms ease',
		},
	},
};

export default tabs;
