import {Avatar, Card, CircularProgress, Icon, Link} from '@mui/material';
import githubIcon from 'components/AppFrame/sidebarLogos/github.svg';
import moment from 'moment';
import DeletionFailedDialog from 'pages/DrCloud/Projects/components/DeletionFailedDialog';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import MDTypography from 'shared/MDTypography';
import PageTopBar from 'shared/Navbars/PageTopBar/PageTopBar';
import NotificationStore from 'store/notification';
import {callApi} from 'utils/network';
import {useLoadingIndicator} from 'utils/network/useLoadingIndicator';
import {dateTimeLong} from 'variables/dateFormat';
import {GitHubAccountItem} from './GitHubAccount';

const ManageGithubAccount: React.FC<{
	accounts: GitHubAccountItem[];
	onUpdate: () => void;
}> = (props) => {
	const {t} = useTranslation();

	const dispatch = useDispatch();

	const [deletionResult, setDeletionResult] = useState<{
		result: boolean;
		projects: any[];
		registries: any[];
	}>({result: false, projects: [], registries: []});

	const onUnlinkFn = async (id: string) => {
		try {
			await callApi.delete(`/api/v1/github/account/${id}`);
			dispatch(
				NotificationStore.actions.show({
					msg: t('github_account_deleted'),
					type: 'success',
				})
			);
		} catch (e: any) {
			if (e?.response?.data?.data?.errorReason === 'RES_IN_USE') {
				setDeletionResult({
					result: true,
					projects: e?.response?.data?.data?.projects,
					registries: e?.response?.data?.data?.registries,
				});
			} else {
				alert(t('error'));
			}
		} finally {
			props.onUpdate();
		}
	};

	const {fetcher: onUnlink, loading} = useLoadingIndicator(onUnlinkFn);

	const onLogin = async () => {
		const {data} = await callApi.get(`/api/v1/auth/github/install`);
		if (data.url) {
			window.open(data.url);
		}
	};
	return (
		<>
			<PageTopBar enableTopMenu={false}>
				<MDButton
					variant="contained"
					color="dark"
					startIcon={<img width="20" height="20" src={githubIcon} />}
					onClick={onLogin}
				>
					{t('github.linkMore')}
				</MDButton>
			</PageTopBar>
			{props.accounts.map((account) => (
				<MDBox key={account._id} display="flex" mb={2}>
					<Card sx={{p: 2}}>
						<MDBox
							display="flex"
							alignItems="center"
							justifyContent="space-between"
						>
							<MDBox display="flex" alignItems="center">
								<Avatar
									alt={account.username}
									src={account.avatar}
									sx={{width: 32, height: 32}}
								/>
								<MDTypography variant="h5" sx={{ml: 1}}>
									<Link
										href={account.accountLink}
										target="_blank"
										rel="noopener"
									>
										{account.username}
									</Link>
								</MDTypography>
							</MDBox>
							{account.accountType && (
								<MDTypography variant="body1" sx={{ml: 1}}>
									<Link
										href={account.accountLink}
										target="_blank"
										rel="noopener"
									>
										{account.accountType}
									</Link>
								</MDTypography>
							)}
						</MDBox>
						<MDTypography variant="button" sx={{mt: 1}}>
							{t('github.linkTime')}
							{moment(account.createdAt).format(dateTimeLong)}
						</MDTypography>
						<MDTypography variant="button">
							{t('github.updateTime')}
							{moment(account.updatedAt).fromNow()}
						</MDTypography>
						<MDBox display="flex" mt={2}>
							<MDButton
								variant="contained"
								color="dark"
								size="small"
								startIcon={
									<img
										width="20"
										height="20"
										src={githubIcon}
									/>
								}
								href={account.url}
								sx={{mr: 1}}
							>
								{t('github_account_manage')}
							</MDButton>
							<MDButton
								variant="contained"
								color="error"
								size="small"
								startIcon={
									loading ? (
										<CircularProgress size={20} />
									) : (
										<MDBox
											sx={{
												color: (theme) =>
													theme.palette.white.main,
											}}
										>
											<Icon color="inherit">delete</Icon>
										</MDBox>
									)
								}
								onClick={() => onUnlink(account._id)}
							>
								{t('github_account_unlink')}
							</MDButton>
						</MDBox>
					</Card>
				</MDBox>
			))}
			{deletionResult.result && (
				<DeletionFailedDialog
					onClose={() => {
						setDeletionResult({
							result: false,
							projects: [],
							registries: [],
						});
					}}
					projects={deletionResult.projects}
					registries={deletionResult.registries}
				/>
			)}
		</>
	);
};

export default ManageGithubAccount;
