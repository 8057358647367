/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {ReactNode, useEffect} from 'react';

// react-router-dom components
import {useLocation} from 'react-router-dom';

// Material Dashboard 2 PRO React TS components
import MDBox from 'shared/MDBox';

// Material Dashboard 2 PRO React context
import {Dialog} from '@mui/material';
import JiraIntegration from 'pages/Settings/Jira';
import {setJiraSetup, setLayout, useMaterialUIController} from 'shared/context';

function DashboardLayout({
	children,
	resetedLayout,
	noPx,
}: {
	children: ReactNode;
	resetedLayout?: boolean;
	noPx?: boolean;
}): JSX.Element {
	const [controller, dispatch] = useMaterialUIController();
	const {miniSidenav, showJiraSetup} = controller;
	const {pathname} = useLocation();

	useEffect(() => {
		setLayout(dispatch, 'dashboard');
	}, [pathname]);

	return (
		<>
			<MDBox
				sx={({breakpoints, transitions, functions: {pxToRem}}) => ({
					py: resetedLayout ? 0 : 3,
					px: noPx ? 0 : 3,
					position: 'relative',

					[breakpoints.up('xl')]: {
						marginLeft: miniSidenav ? pxToRem(120) : pxToRem(200),
						transition: transitions.create(
							['margin-left', 'margin-right'],
							{
								easing: transitions.easing.easeInOut,
								duration: transitions.duration.standard,
							}
						),
					},
				})}
			>
				{children}
			</MDBox>
			<Dialog
				open={showJiraSetup}
				onClose={() => {
					setJiraSetup(dispatch, false);
				}}
			>
				{showJiraSetup && <JiraIntegration insideDialog />}
			</Dialog>
		</>
	);
}

export default DashboardLayout;
