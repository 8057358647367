import {makeResource} from 'utils/saga-resource';
import {put} from 'redux-saga/effects';
import {callApi} from 'utils/network';

export interface ComponentDb {
	_id: string;
	name: string;
	description: string;
	components: string[];
}

export interface ComponentDbsState {
	data: ComponentDb[];
}

export interface ComponentDbsEffects {
	load: () => any;
	loadResource: () => any;
}

const componentDbs = makeResource<ComponentDbsState, any, ComponentDbsEffects>({
	name: 'componentDbs',
	state: {
		data: [],
	},
	effects: {
		*loadResource(): any {
			const data = (yield callApi('/api/v1/componentdbs')).data;
			yield put(componentDbs.actions.set({data}));
		},
		*load(): any {
			yield put(componentDbs.actions.startLoading());
			yield componentDbs.effects.loadResource();
			yield put(componentDbs.actions.endLoading());
		},
	},
});

export default componentDbs;
