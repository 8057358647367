import React, {createContext, useContext} from 'react';
import {SiteEntryPoint, SiteEntryPointContext} from './SiteEntryPoint';

export const DemoAccountContext = createContext<boolean>(false);

const DemoAccountProvider: React.FC<{
	useDemoUser: boolean;
}> = ({children, useDemoUser}) => {
	return (
		<DemoAccountContext.Provider
			value={
				useContext(SiteEntryPointContext) ===
					SiteEntryPoint.PublicTool && useDemoUser
			}
		>
			{children}
		</DemoAccountContext.Provider>
	);
};

export default DemoAccountProvider;
