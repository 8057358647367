import {makeResource} from 'utils/saga-resource';
import {put, select} from 'redux-saga/effects';
import {callApi} from 'utils/network';
import {find, get} from 'lodash';
import {HostInfo, HostStatus, Schedule} from 'types/hostsType';

export const SCHEDULE_TYPE = {
	fixed: 'fixed',
	cron: 'cron',
};

export interface HostDetailsState {
	memoryDumpDetails: any[];
	hostInfo: HostInfo;
}

export interface HostDetailsEffects {
	load: (payload: {hostId: string}) => any;
	loadResource: (payload: {hostId: string}) => any;
	loadMemoryDumpDetails: (payload: {memoryDumpId: string}) => any;
	changeSchedule: (payload: {hostId: string; schedule: Schedule}) => any;
}

const hostDetails = makeResource<HostDetailsState, any, HostDetailsEffects>({
	name: 'hostDetails',
	state: {
		memoryDumpDetails: [],
		hostInfo: {
			_id: '',
			name: '',
			lastScanned: '',
			status: HostStatus.Idle,
			memoryDumps: [{_id: '', date: '', status: ''}],
			tags: [],
			userId: '',
			key: '',
		},
	},
	effects: {
		*loadResource({hostId}): any {
			yield callApi(`/api/v1/hosts/${hostId}/status`);
			const hostInfo = (yield callApi(`/api/v1/hosts/${hostId}`)).data;

			yield put(hostDetails.actions.set({hostInfo}));
		},
		*load({hostId}): any {
			yield put(hostDetails.actions.startLoading());
			yield hostDetails.effects.loadResource({hostId});
			yield put(hostDetails.actions.endLoading());
		},
		*loadMemoryDumpDetails({memoryDumpId}): any {
			yield put(hostDetails.actions.startUpdating(['memoryDump']));
			const memDumpResult = (yield callApi(
				`/api/v1/memdump/${memoryDumpId}`
			)).data;

			const state: HostDetailsState = yield select(
				(state: AppState) => state.hostDetails
			);

			const isDumpDetailExists = Boolean(
				find(
					state.memoryDumpDetails,
					(data) => get(data, '_id') === memDumpResult._id
				)
			);

			yield put(
				hostDetails.actions.set({
					memoryDumpDetails: !isDumpDetailExists
						? [...state.memoryDumpDetails, memDumpResult]
						: state.memoryDumpDetails.map((memDump) =>
								memDump._id === memDumpResult._id
									? memDumpResult
									: memDump
						  ),
				})
			);
			yield put(hostDetails.actions.endUpdating(['memoryDump']));
		},
		*changeSchedule({hostId, schedule}): any {
			console.log(hostId, schedule);
		},
	},
});

export default hostDetails;
